export const SET_USER_NAME = "SET_USER_NAME";
// Get USER name on link click from home
export function setUserName(holder) {
  // console.log("set model flag", holder);
  return (dispatch) => {
    dispatch({
      type: "SET_USER_NAME",
      payload: holder,
    });
  };
}

export const SET_DB_JOB_ID = "SET_DB_JOB_ID";
export function setjobId(holder) {
  // console.log("set model flag", holder);
  return (dispatch) => {
    dispatch({
      type: "SET_DB_JOB_ID",
      payload: holder,
    });
  };
}

export const SET_TAB = "SET_TAB";
// Get USER name on link click from home
export function setTab(holder) {
  // console.log("set modal tab", holder);
  return (dispatch) => {
    dispatch({
      type: "SET_TAB",
      payload: holder,
    });
  };
}

export const SET_JOB = "SET_JOB";
// Get USER name on link click from home
export function setjob_candidate(holder) {
  // console.log("set job in action", holder);
  return (dispatch) => {
    dispatch({
      type: "SET_JOB",
      payload: holder,
    });
  };
}

export const SET_CID = "SET_CID";
// Get USER name on link click from home
export function setCandidateId(holder) {
  // console.log("set candidateId in action", holder);
  return (dispatch) => {
    dispatch({
      type: "SET_CID",
      payload: holder,
    });
  };
}

/*export const SET_RECOMMCID = "SET_RECOMMCID";
// Get USER name on link click from home
export function setcandidateRecommId(holder) {
  // console.log("set candidateId in action", holder);
  return (dispatch) => {
    dispatch({
      type: "SET_RECOMMCID",
      payload: holder,
    });
  };
} */

export function showSnackBar(data) {
  // console.log('data---', data);
  return (dispatch) => {
    dispatch({
      type: "TOGGLE_SNACKBAR",
      payload: data,
    });
  };
}

export function showLoader(data) {
  return (dispatch) => {
    dispatch({
      type: "SHOW_LOADER",
      payload: data,
    });
  };
}
