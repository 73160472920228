import React, { useEffect } from "react";

function JobVsResume(props) {
  useEffect(() => {
    if (typeof window === "undefined") {
      return null;
    }
    var chart = window.am4core.create(
      `chartdiv${props}`,
      window.am4charts.XYChart
    );

    var data = props.plotting.map((item) => {
      return {
        year: item.reqId,
        OpenPosition: item.totalPosition,
        ResumesOnJob: item.count,
        Designation: item.desig,
      };
    });

    chart.data = data;
    chart.colors.step = 2;

    let categoryAxis = chart.xAxes.push(new window.am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.line.strokeOpacity = 1;
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.cellStartLocation = 0.4;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.title.text = "Req Ids";
    categoryAxis.title.fontWeight = "bold";
    var valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = "Resume counts";
    valueAxis.title.fontWeight = "bold";
    let series = chart.series.push(new window.am4charts.ColumnSeries());
    series.dataFields.categoryX = "year";
    series.dataFields.valueY = "OpenPosition";
    series.dataFields.valueZ = "Designation";
    series.tooltipText = "{name}: {valueY}, {name1}: {valueZ}";
    series.name = "OpenPosition";
    series.name1 = "Designation";
    series.columns.template.maxWidth = 50;
    series.columns.template.focusable = true;
    series.columns.template.hoverOnFocus = true;
    series.columns.template.strokeWidth = 0;
    series.columns.template.tooltipText = "xx {valueY}";

    let series2 = chart.series.push(new window.am4charts.ColumnSeries());
    series2.dataFields.categoryX = "year";
    series2.dataFields.valueY = "ResumesOnJob";
    series2.name = "ResumesOnJob";
    series2.tooltipText = "{name}: {valueY}";
    // series2.columns.template.width = window.am4core.percent(99);
    series2.columns.template.maxWidth = 50;
    series2.columns.template.strokeWidth = 0;
    chart.scrollbarX = new window.am4core.Scrollbar();

    chart.legend = new window.am4charts.Legend();

    chart.cursor = new window.am4charts.XYCursor();

    /**
     * ========================================================
     * Enabling responsive features
     * ========================================================
     */

    chart.responsive.useDefault = false;
    chart.responsive.enabled = true;

    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 400) {
          return true;
        }

        return false;
      },
      state: function (target, stateId) {
        if (target instanceof window.am4charts.Chart) {
          var state = target.states.create(stateId);
          state.properties.paddingTop = 5;
          state.properties.paddingRight = 15;
          state.properties.paddingBottom = 5;
          state.properties.paddingLeft = 0;
          return state;
        }
        return null;
      },
    });
  }, [props]);

  return (
    <div
      id={`chartdiv${props}`}
      style={{
        width: "100%",
        height: "450px",
      }}
    ></div>
  );
}
export default JobVsResume;
