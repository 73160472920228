let INITIAL_STATE_ID = {
    userName: ""
};
export default (state = INITIAL_STATE_ID, action) => {
    switch (action.type) {
        case "SET_USER_NAME":
            console.log(action.payload, state, "main Reducer");
            // return Object.assign({}, { ...state }, { userName: action.payload });
            return Object.assign(action.payload);
        default:
            return state;
    }
};

