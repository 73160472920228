import React, { useState, useEffect } from "react";
import endPoints from "../../config/end-point";
import axios from "axios";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const BusinessDetails = (props) => {
  const { signupUser } = props;

  let history = new useHistory();
  const [openRegsitration, setOpenRegsitration] = useState(false);
  const [userInfo, setUserInfo] = useState({
    companyName: "",
    gstIn: "",
    pan: "",
    address: "",
    state: "",
    pinCode: "",
  });
  const [city, setCity] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [ipValue, setIPvalue] = useState("");
  const [disablesSubmit, setDisableSubmit] = useState(false);
  const [industryType, setIndustryType] = useState("");
  const [place, setPlace] = useState("");

  useEffect(() => {
    getCityAndIndustry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    var dupuserInfo = { ...userInfo };
    dupuserInfo[e.target.id] = e.target.value;
    setUserInfo(dupuserInfo);
  };

  const submitDetail = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    if (userInfo.gstIn.length !== 15) {
      props.showSnackBar({
        message: "GSTIN should be 15 characters",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    }
    if (userInfo.pan.length !== 10) {
      props.showSnackBar({
        message: "PAN should be 10 characters",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    }
    if (userInfo.pinCode.length !== 6) {
      props.showSnackBar({
        message: "Pin Code should be 6 digits",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    } else {
      var data = { ...userInfo };
      data.userName = signupUser.emailId;
      data.city = place;
      data.industryType = industryType;

      axios
        .put(endPoints.signupComplete, data, {
          params: {
            ip: ipValue,
          },
        })
        .then((res) => {
          setDisableSubmit(false);
          setOpenRegsitration(true);
        })
        .catch((error) => {
          setDisableSubmit(false);
          if (error.response && error.response.data.code === 100022) {
            props.showSnackBar({
              message: error.response.data.error[0].description,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
          }
          console.log(error, "error");
        });
    }
  };
  const getCityAndIndustry = () => {
    const selectInput = {
      metaData: [
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "LOCATION",
        },
        {
          searchType: "ALL",
          searchValue: "Na",
          entity: "INDUSTRY",
        },
      ],
    };

    axios({
      url: endPoints.publicLookup,
      data: selectInput,
      method: "POST",
    })
      .then((res) => {
        var data = res.data;
        if (data.code === 100000) {
          setCity(data.data.LOCATION);
          setIndustry(data.data.INDUSTRY);
        }
      })
      .catch((error) => {
        console.log("error while fetching data", error);
      });
  };

  const homepage = () => {
    history.push("/");
  };
  const contactus = () => {
    history.push("/contactus");
  };

  return (
    <div className="tq-form-container personal-details">
      <h3 className="tq-form-heading">Business Details</h3>
      <form className="tq-form" onSubmit={submitDetail}>
        <Row>
          <Form.Group as={Col} xs={12} sm={12}>
            <Form.Label>
              Company Name<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Name"
              id="companyName"
              value={userInfo.companyName}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="companyName"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Form.Group as={Col} xs={12} sm={6}>
            <Form.Label>
              GSTIN<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="GSTIN"
              id="gstIn"
              maxLength="15"
              value={userInfo.gstIn}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="gstIn"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}>
            <Form.Label>
              PAN No.<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="PAN No."
              id="pan"
              maxLength="10"
              value={userInfo.pan}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="pan"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Form.Group as={Col} xs={12} sm={6}>
            <Form.Label>
              Industry Type<small className="required__form">*</small>
            </Form.Label>
            <select
              value={industryType}
              onChange={(e) => setIndustryType(e.target.value)}
              variant="outlined"
              className="form-select"
              required="required"
              select
            >
              <option selected>Select Industry</option>
              {industry.map((ind, _id) => (
                <option key={_id} value={ind.name}>
                  {ind.name}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}>
            <Form.Label>
              City<small className="required__form">*</small>
            </Form.Label>
            <select
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              variant="outlined"
              required="required"
              className="form-select"
            >
              <option selected>Select City</option>
              {city.map((city, _id) => (
                <option key={_id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Form.Group as={Col} xs={12} sm={12}>
            <Form.Label>
              Address Details<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Address Details"
              id="address"
              value={userInfo.address}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="address"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Form.Group as={Col} xs={12} sm={6}>
            <Form.Label>
              State<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="State"
              id="state"
              value={userInfo.state}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="state"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}>
            <Form.Label>
              Pin Code<small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Pin Code"
              id="pinCode"
              maxLength="6"
              value={userInfo.pinCode}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="pinCode"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={12} className="mt-4">
            <div className="text-center">
              <Button variant="primary" type="submit" disabled={disablesSubmit}>
                Continue
              </Button>
            </div>
          </Form.Group>
        </Row>
      </form>
      {/* ****** Dialog for Registration completed ******* */}

      <Modal
        dialogClassName="modal__filter-wrapper register__success-message"
        show={openRegsitration}
      >
        <Modal.Body>
          <h4 className="dialogueHliteMsg">Registration Successful!</h4>
          <br />
          Once you get verified, we'll let you know and you will be able to
          access your Profile.
          <Row className="justify-content-around mt-4">
            <Col xs={5} className="text-center">
              <Button variant="success" type="submit" onClick={homepage}>
                Go to Home
              </Button>
            </Col>
            <Col xs={5} className="text-center">
              <Button variant="primary" type="submit" onClick={contactus}>
                Contact Us
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(BusinessDetails);
