import React, { useState } from "react";
import Header from "../common/Header";
// import "../main.css";
import Footer from "../common/Footer";
import endPoints from "../../config/end-point";
import axios from "axios";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const ContactUs = (props) => {
  const [demo, setDemo] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [enable, setEnable] = useState(true);

  const handleInputChange = (e) => {
    let dupDemo = { ...demo };
    dupDemo[e.target.id] = e.target.value;
    setDemo(dupDemo);
  };
  const requestForDemo = (e) => {
    if (demo.name === "") {
      props.showSnackBar({
        message: "Please enter your name",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setEnable(true);
    }
    if (demo.email === "") {
      props.showSnackBar({
        message: "Please enter your email",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setEnable(true);
    }
    if (demo.contact === "") {
      props.showSnackBar({
        message: "Please enter your contact no.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setEnable(true);
    }
    if (demo.message === "") {
      props.showSnackBar({
        message: "Please enter your message",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setEnable(true);
    }
    if (demo.email !== "") {
      var mailformat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
      if (!demo.email.match(mailformat)) {
        props.showSnackBar({
          message: "Please enter valid email",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        setEnable(true);
        return;
      }
    }
    e.preventDefault();
    setEnable(false);
    const data = demo;
    axios
      .post(endPoints.contactUs, data)
      .then((res) => {
        var data = res.data;
        if (data.code === 100000) {
          props.showSnackBar({
            message:
              "We have received your contact details and we'll contact you soon.",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          setDemo({ name: "", email: "", contact: "", message: "" });
          setEnable(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="contact-page">
      <Header />

      <section className="contact-section tq-section-padding">
        <Container>
          <Row>
            <Col md={6} sm={6}>
              <div className="tq-heading-wrapper mb-3">
                <p className="top-heading">Contact Us</p>
                <h2 className="section-heading">Hello! We are here to help.</h2>
                <p className="heading-desc">
                  TalenQ delivers the solution to your hiring problems by
                  providing the best suited to your requirements.
                </p>
              </div>
              <div className="contact-wrapper">
                <div className="contact-details">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <svg
                        width="20"
                        height="16"
                        viewBox="0 0 20 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
                          fill="#333"
                        />
                      </svg>
                    </div>
                    <p className="desc-text">support@talenq.com</p>
                  </div>
                </div>
              </div>
              <p className="desc-text mt-3">You Can Conntect with us on</p>
              <div className="social-handles">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/talenq_com?s=08"
                  className="social-link"
                >
                  <img
                    src="https://talenq.r.worldssl.net/talenq/img/twitter.svg"
                    alt="twitter"
                    className="img-fluid"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/talenqsoftware/?view_public_for=1480136818789389"
                  className="social-link"
                >
                  <img
                    src="https://talenq.r.worldssl.net/talenq/img/facebook.svg"
                    alt="facebook"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/talenq/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <img
                    src="https://talenq.r.worldssl.net/talenq/img/linkedin.svg"
                    alt="linkedin"
                    className="img-fluid"
                  />
                </a>
              </div>
            </Col>
            <Col md={6} sm={6}>
              <Form action="" className="contact-form">
                <Form.Group className="mb-3">
                  <Form.Label className="floting-label" htmlFor="formName">
                    Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your Name"
                    id="name"
                    value={demo.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="floting-label" htmlFor="formEmail">
                    Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Your Email"
                    id="email"
                    value={demo.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="floting-label" htmlFor="formContact">
                    Contact No.
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="+91 0000000000"
                    id="contact"
                    value={demo.contact}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="floting-label" htmlFor="formMsg">
                    Message
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="message"
                    as="textarea"
                    placeholder="Leave a comment here"
                    value={demo.message}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <button
                  className="btn-tq tqBtn my-2"
                  type="submit"
                  onClick={requestForDemo}
                >
                  Submit
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(ContactUs);
