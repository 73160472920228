let INITIAL_STATE_JOB = {
    job: ""
};
export default (state = INITIAL_STATE_JOB, action) => {
    switch (action.type) {
        case "SET_JOB":
            // console.log("payload:", action.payload, "state:", state, "job in the Reducer");
            return Object.assign(action.payload);
        default:
            return state;
    }
};

