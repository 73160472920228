import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Radio } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./loginsignup.css";
import Header from "../../home/common/Header";
import Footer from "../../home/common/Footer";
import endPoints from "../../config/end-point";
import axios from "axios";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Recaptcha from "react-recaptcha";
import ForgotPassword from "./ForgotPassword";
import "../../home/home/tqResponsive.css";

const useStyles = makeStyles({
  hidden: {
    display: "none",
  },
});
const Login = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    username: "",
    password: "",
    rm: false,
    setLogin: false,
  });
  const [isVerified, setIsVerified] = useState(false);
  const [disableLogin, setDisableLogin] = useState(false);
  const [showHideLogin, setShowHideLogin] = useState(true);
  const [showpass, setShowpass] = useState(false);
  const [showContinue, setShowContinue] = useState(true);
  const [ipValue, setIPvalue] = useState("");
  const [showHideForgot, setShowHideForgot] = useState(false);
  const [closeJob, setCloseJob] = useState(false);
  const [getToken, setGetToken] = useState("");
  const [getType, setGetType] = useState("");
  const [getTokenTimeOut, setGetTokenTimeOut] = useState("");
  const [getTokenTimeOutNew, setGetTokenTimeOutNew] = useState("");
  const [disableContinue, setDisableContinue] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [step, setStep] = useState(1);
  const reCaptchaLoaded = () => {
    console.log("captcha loaded successfully");
  };
  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };
  const loginChange = () => {
    window.location.reload();
  };
  const showforgotpass = () => {
    setShowHideForgot(true);
    props.history.push("/forgotpassword", { userId: user.username });
  };
  const setLoginChange = (e) => {
    const dupuser = { ...user };
    if (e.target.name === "setLogin") {
      dupuser.setLogin = e.target.value;
    }
    if (e.target.value === "false") {
      props.history.push("/");
      props.showSnackBar({
        message: "Redirecting to home page",
        open: true,
        type: "success",
        vertical: "top",
        horizontal: "center",
      });
      props.history.push("/");
      return;
    }
    setCloseJob(true);
    setUser(dupuser);
  };
  const changeRememberme = (e) => {
    const dupuser = { ...user };
    dupuser.rme = e.target.checked;
    setUser(dupuser);
  };
  const handleEmailChange = (e) => {
    const dupuser = { ...user };
    dupuser.username = e.target.value;
    setUser(dupuser);
  };
  const passwordChange = (e) => {
    const dupuser = { ...user };
    dupuser.password = e.target.value;
    setUser(dupuser);
  };
  const handleShowLogin = (e) => {
    const payloadUser = { ...user };
    const dupuser = { ...user };

    setUser(dupuser);
    setDisableLogin(true);
    dupuser.password = "xx0000xx0000xx0000xx0000";
    dupuser.password = user.password;
    if (isVerified) {
      axios({
        url: endPoints.loginUser,
        method: "POST",
        params: { ip: ipValue },
        data: payloadUser,
      })
        .then((res) => {
          setDisableLogin(false);
          e.preventDefault();
          var setLogin = dupuser && dupuser.setLogin ? dupuser.setLogin : "";
          dupuser.setLogin = setLogin;
          setUser(dupuser);
          setCloseJob(true);
        })
        .catch((error) => {
          setDisableLogin(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100039
          ) {
            props.showSnackBar({
              message: "Your Email ID & Password Does Not Match", //error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };
  const handleClose = () => {
    setCloseJob(false);
    props.history.push("/");
  };

  //  ########### login User #############
  const loginUser = () => {
    const payloadUser = { ...user };
    const dupuser = { ...user };

    setUser(dupuser);
    setDisableLogin(true);
    dupuser.password = "xx0000xx0000xx0000xx0000";
    dupuser.password = user.password;
    if (isVerified) {
      axios({
        url: endPoints.loginUser,
        method: "POST",
        params: { ip: ipValue },
        data: payloadUser,
      })
        .then((res) => {
          setDisableLogin(false);
          var data = res.data.data;
          if (res.data && res.data.code === 100088) {
            axios
              .get(endPoints.resetToken, {
                params: {
                  userName: user.username,
                },
              })
              .then((res) => {
                if (res.data && res.data.code === 100055) {
                  props.history.push("/login-signup");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          const expiryTime = new Date(data.tokenTimeout).toGMTString();
          const expiryTimeOne = new Date(data.tokenTimeoutNew).toGMTString();
          let userType;

          if (data.userInfo.userType === "Admin") {
            userType = "1110";
          } else if (data.userInfo.userType === "Corporate") {
            userType = "1100";
          } else if (data.userInfo.userType === "Vendor") {
            userType = "1000";
          }
          document.cookie = `info=${
            data && data.userInfo ? data.userInfo.token : ""
          } ;expires=${expiryTime};path=/`;
          document.cookie = `infoOne=${
            data && data.userInfo ? data.userInfo.tokenAdmin : ""
          } ;expires=${expiryTimeOne};path=/`;
          localStorage.setItem(
            "name",
            data && data.userInfo ? data.userInfo.firstName : ""
          );
          localStorage.setItem(
            "ses",
            data && data.userInfo ? data.tokenTimeout : ""
          );
          localStorage.setItem(
            "lastName",
            data && data.userInfo ? data.userInfo.lastName : ""
          );
          localStorage.setItem(
            "i",
            data && data.userInfo ? data.userInfo._id : ""
          );
          localStorage.setItem(
            "ci",
            data && data.userInfo ? data.userInfo.corporateId : ""
          );
          localStorage.setItem(
            "vci",
            data && data.userInfo ? data.userInfo.vendorCorporateId : ""
          );
          localStorage.setItem("ut", userType ? userType : "");
          localStorage.setItem(
            "ua",
            data && data.userInfo ? data.userInfo.userAccess : ""
          );
          localStorage.setItem(
            "email",
            data && data.userInfo ? data.userInfo.userName : ""
          );

          setTimeout(() => {}, 1000);
          if (
            data &&
            data.userInfo &&
            (data.userInfo.userType === "Corporate" ||
              data.userInfo.userType === "Admin")
          ) {
            props.history.push("/dashboard");
          }
          if (data && data.userInfo && data.userInfo.userType === "Vendor") {
            props.history.push("/dashboard");
          }
        })
        .catch((error) => {
          setDisableLogin(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100039
          ) {
            props.showSnackBar({
              message: "Your Email ID & Password Does Not Match", //error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    } else {
      setDisableLogin(false);
      props.showSnackBar({
        message: "Invalid Captcha.",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
  };

  // ****** check userName first *******
  const checkUserName = (e) => {
    axios
      .get(endPoints.checkUserRegistration, {
        params: {
          userName: user.username,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.data && data.data.setToken) {
          setGetToken(data.data.setToken);
        }
        if (data.data && data.data.userType) {
          setGetType(data.data.userType);
        }
        if (data.data && data.data.tokenTimeout) {
          setGetTokenTimeOut(data.data.tokenTimeout);
        }
        if (data.data && data.data.tokenTimeoutNew) {
          setGetTokenTimeOutNew(data.data.tokenTimeoutNew);
        }
        if (data && data.code === 100004) {
          setShowpass(true);
          setShowContinue(false);
        }
        if (data && data.code === 100038) {
          let userData = {
            username: user.username,
            contact: data.data.queryRsp.contact,
            status: data.data.queryRsp.status,
          };
          props.history.push("/signup", { userData });
          setShowHideLogin(false);
        }
        if (data && data.code === 100027) {
          props.showSnackBar({
            message: "Email already exist",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (data && data.code === 100044) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (data && data.code === 100042) {
          setShowHideLogin(false);
          setStep(step + 2);
          let userData = {
            status: data.data.queryRsp.status,
            username: user.username,
            contact: user.contact,
          };

          props.showSnackBar({
            message: "please proceed for profile complete",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          props.history.push("/signup", { userData });
          setShowHideLogin(false);
        }
      })
      .catch((error) => {
        setDisableContinue(false);
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100039
        ) {
          props.showSnackBar({
            message: "Your Email ID & Password Does Not Match",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100022
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  let DataBaseTime = new Date(getTokenTimeOut).toLocaleString();
  let CurrentTime = new Date().toLocaleString(); ///new Date(randomTime).toLocaleString();
  let DataBaseAdminTime = new Date(getTokenTimeOutNew).toLocaleString();

  return (
    <div className="personal-details">
      <Header />
      {showHideLogin ? (
        <div className="login-card">
          <img
            src="https://talenq.r.worldssl.net/talenq/img/login/form-texture.png"
            alt="login-texture"
            className="img-fluid login-texture"
          />
          <div className="tq-form-container text-center">
            <h3 className="tq-form-heading">Login</h3>
            <form className="tq-form">
              {showHideForgot ? (
                <ForgotPassword />
              ) : (
                <div>
                  <Row className="text-left">
                    <Form.Group
                      as={Col}
                      xs={12}
                      sm={12}
                      className="position-relative"
                    >
                      <Form.Label>
                        Email
                        <small className="required__form">*</small>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        label="Email"
                        variant="outlined"
                        name="uname"
                        placeholder="Email"
                        value={user.username.trim()}
                        onChange={handleEmailChange}
                        required="required"
                        aria-labelledby="uname"
                        disabled={disableEmail}
                      />
                      <p
                        onClick={loginChange}
                        className={
                          !showpass ? classes.hidden : "forgot-text changeUID"
                        }
                      >
                        Change Email
                      </p>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs={12}
                      className={!showpass ? classes.hidden : null}
                    >
                      <Row>
                        <Form.Group as={Col} xs={12} sm={12} className="mt-4">
                          <Form.Label>
                            Password
                            <small className="required__form">*</small>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            label="Password"
                            variant="outlined"
                            placeholder="Password*"
                            onChange={passwordChange}
                            value={user.password}
                            name="psw"
                            required={showpass}
                            aria-labelledby="psw"
                          />
                        </Form.Group>
                        <Form.Group
                          className="text-left rememberMe"
                          as={Col}
                          xs={6}
                          sm={6}
                        >
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Remember Me"
                            labelPlacement="end"
                            name="remember"
                            onChange={changeRememberme}
                            checked={user.rme}
                          />
                        </Form.Group>

                        <Form.Group
                          className="text-right forgetPass mt-2"
                          as={Col}
                          xs={6}
                          sm={6}
                        >
                          <p onClick={showforgotpass} className="forgot-text">
                            Forgot Password?
                          </p>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          xs={8}
                          sm={12}
                          className="captcha-wrapper mt-3"
                        >
                          <Recaptcha
                            sitekey="6LcZg8kUAAAAALqbMDrxQ2-6t5f7uvS1__QwVaPt"
                            render="explicit"
                            onloadCallback={reCaptchaLoaded}
                            verifyCallback={verifyCallback}
                          />
                        </Form.Group>
                        <Form.Group
                          className="text-center"
                          as={Col}
                          xs={12}
                          sm={12}
                        >
                          {getToken === "true" &&
                          getType !== "Admin" &&
                          DataBaseTime >= CurrentTime &&
                          DataBaseAdminTime === "Invalid Date" ? (
                            <Button
                              className="tq-button"
                              onClick={handleShowLogin}
                            >
                              Login
                            </Button>
                          ) : (
                            <Button
                              className="tq-button"
                              onClick={loginUser}
                              disabled={disableLogin}
                            >
                              Login
                            </Button>
                          )}
                        </Form.Group>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="text-center"
                      xs={12}
                      sm={12}
                    >
                      {showContinue ? (
                        <Button
                          className="tq-button"
                          onClick={checkUserName}
                          disabled={disableContinue}
                        >
                          Continue
                        </Button>
                      ) : null}
                    </Form.Group>
                  </Row>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : (
        ""
      )}

      <Modal
        show={closeJob}
        onHide={handleClose}
        className="tq-border"
        animation={false}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Container>
            <p>
              We can see that you have logged from other device, Would you like
              to reset that previous login continue here ?
            </p>
            <span>
              <FormControlLabel
                control={
                  <Radio
                    checked={user.setLogin === "true"}
                    onChange={setLoginChange}
                    value="true"
                    name="setLogin"
                    id="yes"
                    aria-label="position"
                  />
                }
                label="Yes"
              />

              <FormControlLabel
                control={
                  <Radio
                    checked={user.setLogin === "false"}
                    onChange={setLoginChange}
                    value="false"
                    name="setLogin"
                    id="no"
                    aria-label="position"
                  />
                }
                label="No"
              />
            </span>
          </Container>
          <span style={{ color: "red", fontSize: "14px", marginTop: "14px" }}>
            Please confirm your login by selecting 'YES' or 'NO'
          </span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={disableLogin} onClick={loginUser}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(Login);
