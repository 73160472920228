import { combineReducers } from "redux";
import main from "./main";
import maintab from "./maintab";
import mainjobid from "./mainjobid";
import job from "./job";
import cid from "./cid";
import snackbar from "./snackbar";
import loader from "./loader";

const rootReducer = combineReducers({
  main: main,
  maintab: maintab,
  mainjobid: mainjobid,
  job: job,
  cid: cid,
  snackbar: snackbar,
  loader: loader,
});
export default rootReducer;
