let INITIAL_STATE_TAB = {
    tab: ""
};
export default (state = INITIAL_STATE_TAB, action) => {
    switch (action.type) {
        case "SET_TAB":
            // console.log(action.payload, state, "maintab Reducer");
            // return Object.assign({}, { ...state }, { userName: action.payload });
            return Object.assign(action.payload);
        default:
            return state;
    }
};