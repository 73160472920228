import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import Badge from "../components/badge/Badge";
import JobsCard from "../components/jobcards/JobsCard";
//import jobData from "../assets/jsonDatas/openJobs.json";

import React, { useState, useEffect } from "react";
import { dEndPoints } from "../config/end-point";
import axios from "axios";

//import job from "../reducers/job";
import Chart from "./Chart";
import trackEvent from "../utils/analytics-manager";
import TopNav from "../components/topnav/TopNav";
import SideNav from "../components/sidenav/SideNav";
import GoBackButton from "../components/badge/GoBackButton";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader, showSnackBar } from "../actions/index";
import { setUserName, setjobId } from "../actions/index";

// const orderStatus = {
//   close: "close",
//   pending: "pending",
//   open: "open",
//   inactive: "inactive",
//   Draft: "Draft",
// };
const BucketDetail = (props) => {
  const [jobs, setJobs] = useState([]);
  const [closeJob, setCloseJob] = useState(false);

  const [job, setJob] = useState({
    closedComment: [],
    statHis: [],
    coOwners: [],
  });

  useEffect(() => {
    bucketdetailsJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let userID = localStorage.getItem("i");
  const userType = localStorage.getItem("ut");
  const clickDashboardRequirement = (jobId) => {
    props.history.push(`/alljobs/${jobId}`);
  };
  const clickDashboardRequirementVendor = (jobId) => {
    props.history.push(`/alljobs/${jobId}?enroll=${true}`);
  };
  var getQuery = props.location.search
    .replace(/\s/g, "-")
    .replace(/,/g, "")
    .replace(/\?/g, "");

  function handleViewRequirement(jobId) {
    props.setUserName({ userName: jobId });
    props.history.push(`/createjob/${jobId}`);
    trackEvent({
      type: "EDIT_JOB",
      data: {
        jobId: jobId,
      },
    });
  }

  const editJob = (jobId, data) => {
    //let cId = cacheData.ci;
    let cId = localStorage.getItem("ci");
    //var jobId = props.match.params.jobId,data;
    //let data = { ...job };

    //data.jobId = jobIDs;
    axios
      .put(dEndPoints.editJob(cId, jobId), data)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };
  const bucketdetailsJobs = (pageNum = 0) => {
    //const corporateId = cacheData.ci;
    const userId = localStorage.getItem("i");
    const corporateId = localStorage.getItem("ci");
    const vendorCorporateId = localStorage.getItem("vci");
    props.showLoader({ show: true });
    //setIsLoading(true);
    if (userType === "1100") {
      axios
        .get(dEndPoints.corpBucketDetails(corporateId, userId), {
          params: { status: getQuery },
          // params: { customStatus: getQuery },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100178) {
            //setfacets(data.data.facets);
          }
          if (data.data.closedJobs) {
            setJobs(data.data.closedJobs);
          }
          if (data.data.awaitingApprovalJobs) {
            setJobs(data.data.awaitingApprovalJobs);
          }
          if (data.data.CreatedByMe) {
            setJobs(data.data.CreatedByMe);
          }
          if (data.data.coOwnersJob) {
            setJobs(data.data.coOwnersJob);
          }
          if (data.data.openJobs) {
            setJobs(data.data.openJobs);
          }
          if (data.data.inactiveJobs) {
            setJobs(data.data.inactiveJobs);
          }
          if (data.data.draftJobs) {
            setJobs(data.data.draftJobs);
          }
          if (data.data.renderCustomJob) {
            setJobs(data.data.renderCustomJob);
          }
          //setJobCount(Math.ceil(data.data.total / 12));
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else if (userType === "1110") {
      axios
        .get(dEndPoints.superAdminBucketDetails(userId), {
          params: { status: getQuery },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100178) {
            //setfacets(data.data.facets);
          }
          if (data.data.closedJobs) {
            setJobs(data.data.closedJobs);
          }
          if (data.data.openJobs) {
            setJobs(data.data.openJobs);
          }
          if (data.data.awaitingApprovalJobs) {
            setJobs(data.data.awaitingApprovalJobs);
          }
          if (data.data.inactiveJobs) {
            setJobs(data.data.inactiveJobs);
          }
          if (data.data.draftJobs) {
            setJobs(data.data.draftJobs);
          }

          //setJobCount(Math.ceil(data.data.total / 12));
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      axios
        .get(dEndPoints.vendorBucketDetails(vendorCorporateId, userId), {
          params: { status: getQuery },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100178) {
            //setfacets(data.data.facets);
          }
          if (data.data.noResumeJob) {
            setJobs(data.data.noResumeJob);
          }
          if (data.data.closedJobs) {
            setJobs(data.data.closedJobs);
          }
          if (data.data.inactiveJobs) {
            setJobs(data.data.inactiveJobs);
          }
          if (data.data.TotalClosures) {
            setJobs(data.data.TotalClosures);
          }
          if (data.data.JobOfDay) {
            setJobs(data.data.JobOfDay);
          }
          if (data.data.OfferedCandidates) {
            setJobs(data.data.OfferedCandidates);
          }
          if (data.data.JobEnrolledBucket) {
            setJobs(data.data.JobEnrolledBucket);
          }
          if (data.data.renderCustomJob) {
            setJobs(data.data.renderCustomJob);
          }
          //setJobCount(Math.ceil(data.data.total / 12));
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const favouriteJob = (jobId, favjob) => {
    //const corporateId = cacheData.ci;
    const corporateId = localStorage.getItem("ci");
    axios
      .patch(dEndPoints.corporatefavouriteJob(corporateId, jobId), null, {
        params: {
          fav: favjob,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100141) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          bucketdetailsJobs();
          return;
        } else if (data && data.code === 100173) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          bucketdetailsJobs();
          return;
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100142
        ) {
          props.showSnackBar({
            message: error.response.data.message,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  const updateJob = (e, status, jobId, statHis, coOwners, closedComment) => {
    e.preventDefault();
    //const corporateId = cacheData.ci;
    const corporateId = localStorage.getItem("ci");

    // jobId = props.match.params.jobId;
    var cjob = { ...job, statHis, coOwners, closedComment };

    cjob.status = status;

    props.showLoader({ show: true });

    axios
      .patch(dEndPoints.updateJob(corporateId, jobId), cjob)
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        editJob(jobId, data);
        handleCloseJob();
        bucketdetailsJobs();
        props.showSnackBar({
          message: "job Updated Successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        props.history.push("/alljobs");
      })
      .catch((error) => {
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };

  // function handleViewRequirement(jobId) {
  //   props.setUserName({ userName: jobId });
  //   props.history.push(`/createjob/${jobId}`);
  //   trackEvent({
  //     type: "EDIT_JOB",
  //     data: {
  //       jobId: jobId,
  //     },
  //   });
  // }

  function handleInputChange(event) {
    var cjob = { ...job };

    if (event.target.name === "is_talenq_closed") {
      var closeJob = cjob && cjob.feedback ? cjob.feedback : {};
      closeJob[event.target.name] = event.target.value === "true";
      cjob.feedback = closeJob;
    }
    if (event.target.name === "closedComment") {
      cjob[event.target.name] = event.target.value;
    } else {
      cjob[event.target.id] = event.target.value;
    }

    setJob({ ...job, ...cjob });
  }
  const handleShowCloseJob = (e, jobId) => {
    e.preventDefault();
    var cjob = { ...job, jobId };
    var feedback = cjob && cjob.feedback ? cjob.feedback : {};
    cjob.feedback = feedback;
    setJob(cjob);
    setCloseJob(true);
  };
  const handleCloseJob = () => {
    setCloseJob(false);
    var cjob = { ...job };
    cjob.closedComment = [];
    setJob({ ...job, ...cjob });
  };

  // var jobDonutData;

  // jobDonutData = {
  //   options: {
  //     labels: ["Pending", "Screened", "InProcess", "Onhold", "Scheduled"],
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     legend: {
  //       show: false,
  //     },
  //   },
  //   series: [0, 55, 41, 17, 15],
  // };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2}>
            <SideNav {...props} />
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />
            <GoBackButton />
            <div className="tq-bucketOne">
              <div className="bucket-heading text-center">
                {" "}
                {jobs.map((item, index) => (
                  <h2 className="page-header">{item.name}</h2>
                ))}
                {/* <div className="filter__wrapper">
                  <FilterShow icon="bx bx-slider" BtnName="Filter" />
                </div> */}
              </div>
              {userType === "1000" ? (
                <div className="Jobs__listing-wrapper">
                  <Row>
                    {jobs.map((item, index) => (
                      <Col md={4} className="mt-5" key={index}>
                        {item.status ? (
                          <JobsCard
                            _id={item._id}
                            ChartDatas={
                              !item.rCtInfo ||
                              (item.rCtInfo &&
                                !item.rCtInfo.Pending &&
                                !item.rCtInfo.Screened &&
                                !item.rCtInfo.OfferRejected &&
                                !item.rCtInfo.InProcess &&
                                !item.rCtInfo.Onhold &&
                                !item.rCtInfo.RoundClear &&
                                !item.rCtInfo.Scheduled &&
                                !item.rCtInfo.RoundCompleted &&
                                !item.rCtInfo.OfferRevoked &&
                                !item.rCtInfo.Offered &&
                                !item.rCtInfo.Proposed &&
                                !item.rCtInfo.Dropped &&
                                !item.rCtInfo.Declined &&
                                !item.rCtInfo.Rejected &&
                                !item.rCtInfo.OfferAccepted &&
                                !item.rCtInfo.Joined) ? (
                                <div align="center">
                                  <span>No resume yet!</span>
                                  <p>You can work on it.</p>
                                </div>
                              ) : (
                                <div align="center">
                                  <Chart
                                    jobId={item._id}
                                    resumeCount={
                                      item.rCtInfo ? item.rCtInfo : {}
                                    }
                                  />
                                </div>
                              )
                            }
                            badge={
                              <Badge type={item.status} content={item.status} />
                            }
                            jobLink={() =>
                              clickDashboardRequirementVendor(item._id)
                            }
                            enrolled={item.enrolled}
                            jobOfTheDay={item.jobOfTheDay}
                            rate={item.rate}
                            notice={item.nPeriod}
                            jodstatus={item.jodstatus}
                            jobType={item.jobType}
                            reqId={item.reqId}
                            Heading={item.desig}
                            posted={new Date(item.cOn).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                            company={item.cName}
                            skills={item.pSkills.join() || item.pSkills}
                            location={item.city + "    "}
                            FavIcon={
                              <div className="buttonsGroup">
                                {item.status === "Open" ? (
                                  <>
                                    <div>
                                      {item.favourite &&
                                      item.favourite.filter(
                                        (item) => item === userID
                                      )[0] ? (
                                        <button
                                          title="Remove favourite"
                                          className="jobAction"
                                        >
                                          <i
                                            className="bx bx-heart"
                                            onClick={() => {
                                              favouriteJob(item._id, "false");
                                            }}
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          title="Favourite"
                                          className="jobAction"
                                        >
                                          <i
                                            className="bx bx-heart"
                                            onClick={() => {
                                              favouriteJob(item._id, "true");
                                            }}
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div className="Jobs__listing-wrapper">
                  <Row>
                    {jobs.map((item, index) => (
                      <Col md={4} className="mt-5" key={index}>
                        {item.status ? (
                          <JobsCard
                            badge={
                              <Badge type={item.status} content={item.status} />
                            }
                            ChartDatas={
                              !item.rCt ||
                              (item.rCt &&
                                !item.rCt.Pending &&
                                !item.rCt.Screened &&
                                !item.rCt.OfferRejected &&
                                !item.rCt.InProcess &&
                                !item.rCt.Onhold &&
                                !item.rCt.RoundClear &&
                                !item.rCt.Scheduled &&
                                !item.rCt.RoundCompleted &&
                                !item.rCt.OfferRevoked &&
                                !item.rCt.Offered &&
                                !item.rCt.Proposed &&
                                !item.rCt.Dropped &&
                                !item.rCt.Declined &&
                                !item.rCt.Rejected &&
                                !item.rCt.OfferAccepted &&
                                !item.rCt.Joined) ? (
                                <div align="center">
                                  <span>No resume yet !</span>
                                </div>
                              ) : (
                                <div align="center">
                                  <Chart
                                    jobId={item._id}
                                    resumeCount={item.rCt ? item.rCt : {}}
                                  />
                                </div>
                              )
                            }
                            jobLink={() => clickDashboardRequirement(item._id)}
                            jobstatus={item.status}
                            jodstatus={item.jobOfTheDay}
                            jobType={item.jobType}
                            reqId={item.reqId}
                            Heading={item.desig}
                            posted={new Date(item.cOn).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                            company={item.cName}
                            skills={item.pSkills.join() || item.pSkills}
                            location={item.city + "    "}
                            FavIcon={
                              <div className="buttonsGroup">
                                {item.status === "Open" ? (
                                  <>
                                    <div>
                                      {item.favourite &&
                                      item.favourite.filter(
                                        (item) => item === userID
                                      )[0] ? (
                                        <button
                                          title="Remove favourite"
                                          className="jobAction"
                                        >
                                          <i
                                            className="bx bx-heart"
                                            onClick={() => {
                                              favouriteJob(item._id, "false");
                                            }}
                                          ></i>
                                        </button>
                                      ) : (
                                        <button
                                          title="Favourite"
                                          className="jobAction"
                                        >
                                          <i
                                            className="bx bx-heart"
                                            onClick={() => {
                                              favouriteJob(item._id, "true");
                                            }}
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                    <div align="center" title="Edit Job">
                                      <button
                                        className="jobAction"
                                        onClick={(e) => {
                                          handleViewRequirement(item._id);
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        value={item._id}
                                      >
                                        <i className="bx bx-edit"></i>
                                      </button>
                                    </div>
                                    <div title="Inactive Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(event) =>
                                          updateJob(
                                            event,
                                            "Inactive",
                                            item._id,
                                            item.statHis,
                                            item.coOwners
                                          )
                                        }
                                      >
                                        <i className="bx bx-hide"></i>
                                      </button>
                                    </div>
                                    <div align="center" title="Close Job">
                                      <button
                                        className="jobAction"
                                        justIcon
                                        onClick={(e) =>
                                          handleShowCloseJob(e, item._id)
                                        }
                                      >
                                        <i className="bx bx-trash"></i>
                                      </button>
                                    </div>
                                  </>
                                ) : item.status === "Awaiting Approval" ? (
                                  <>
                                    <div align="center" title="Edit Job">
                                      <button
                                        className="jobAction"
                                        onClick={(e) => {
                                          handleViewRequirement(item._id);
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        value={item._id}
                                      >
                                        <i className="bx bx-edit"></i>
                                      </button>
                                    </div>
                                    <div align="center" title="Close Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(e) =>
                                          handleShowCloseJob(e, item._id)
                                        }
                                      >
                                        <i className="bx bx-trash"></i>
                                      </button>
                                    </div>
                                  </>
                                ) : item.status === "Draft" ? (
                                  <>
                                    <div align="center" title="Edit Job">
                                      <button
                                        className="jobAction"
                                        onClick={(e) => {
                                          handleViewRequirement(item._id);
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        value={item._id}
                                      >
                                        <i className="bx bx-edit"></i>
                                      </button>
                                    </div>
                                    <div title="Post Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(event) =>
                                          updateJob(
                                            event,
                                            "Awaiting Approval",
                                            item._id,
                                            item.statHis,
                                            item.coOwners
                                          )
                                        }
                                      >
                                        <i className="bi bi-check-square-fill"></i>
                                      </button>
                                    </div>
                                    <div align="center" title="Close Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(e) =>
                                          handleShowCloseJob(e, item._id)
                                        }
                                      >
                                        <i className="bx bx-trash"></i>
                                      </button>
                                    </div>
                                  </>
                                ) : item.status === "Close" ? (
                                  <>
                                    <div title="Repost Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(event) =>
                                          updateJob(
                                            event,
                                            "Open",
                                            item._id,
                                            item.statHis,
                                            item.coOwners
                                          )
                                        }
                                      >
                                        <i className="bx bx-recycle"></i>
                                      </button>
                                    </div>
                                  </>
                                ) : item.status === "Inactive" ? (
                                  <>
                                    <div align="center" title="Edit Job">
                                      <button
                                        className="jobAction"
                                        onClick={(e) => {
                                          handleViewRequirement(item._id);
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        value={item._id}
                                      >
                                        <i className="bx bx-edit"></i>
                                      </button>
                                    </div>
                                    <div title="Activate Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(event) =>
                                          updateJob(
                                            event,
                                            "Open",
                                            item._id,
                                            item.statHis,
                                            item.coOwners
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-eye"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                        </svg>
                                      </button>
                                    </div>
                                    <div align="center" title="Close Job">
                                      <button
                                        className="jobAction"
                                        type="submit"
                                        onClick={(e) =>
                                          handleShowCloseJob(e, item._id)
                                        }
                                      >
                                        <i className="bx bx-trash"></i>
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    ))}

                    <Modal
                      show={closeJob}
                      dialogClassName="tq__modal-default"
                      onHide={handleCloseJob}
                    >
                      <Modal.Header
                        className="border-0"
                        closeButton
                      ></Modal.Header>
                      <Modal.Body className="tq-form ">
                        <Form.Group>
                          <Form.Label className="modal__heading mb-3">
                            Did this position close through talenQ
                          </Form.Label>

                          <div className="form-control">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="yes"
                                name="is_talenq_closed"
                                value={true}
                                checked={
                                  job.feedback &&
                                  job.feedback.is_talenq_closed === true
                                }
                                onClick={handleInputChange}
                              />
                              <label className="form-check-label" for="pg">
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="no"
                                value={false}
                                name="is_talenq_closed"
                                checked={
                                  job.feedback &&
                                  job.feedback.is_talenq_closed === false
                                }
                                onChange={handleInputChange}
                              />
                              <label className="form-check-label" for="pan">
                                No
                              </label>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="mt-4">
                          <Form.Label className="modal__heading mb-3">
                            All pending resume would be Rejected, Please enter
                            any comment.
                          </Form.Label>
                          <Form.Control
                            value={job.closedComment}
                            onChange={handleInputChange}
                            aria-label="Small"
                            aria-describedby=""
                            name="closedComment"
                          />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer className="justify-content-center border-0">
                        <Button variant="secondary" onClick={handleCloseJob}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={(event) =>
                            updateJob(
                              event,
                              "Close",
                              job.jobId,
                              job.statHis,
                              job.coOwners,
                              job.closedComment
                            )
                          }
                        >
                          Done
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setjobId: bindActionCreators(setjobId, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

//export default connect(mapStateToProps, mapDispatchToProps)(OpenJobs);
export default connect(null, mapDispatchToProps)(BucketDetail);
