import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import "./jobscard.css";

const JobsCard = (cardsProp) => {
  const userType = localStorage.getItem("ut");
  return (
    <div className="JobCard">
      <span>
        <div
          className="cardHeader"
          onClick={cardsProp.jobLink}
          value={cardsProp.CardValue}
        >
          <Row className="position-relative">
            <Col xs={6}>
              <div className="labelArea">
                <Tooltip title="Job Status">
                  <div className="status">{cardsProp.badge}</div>
                </Tooltip>
                {cardsProp.reqId ? (
                  <div className="vCardId reqId">
                    <strong>Req. ID : </strong> <span>{cardsProp.reqId}</span>
                  </div>
                ) : (
                  <></>
                )}
                {cardsProp.jodstatus === true ? (
                  <div className="vCardType">
                    <span className="joday" jodview={cardsProp.jodstatus}>
                      Job of the day
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={6}>
              {cardsProp.ChartDatas ? (
                <div className="chartArea"> {cardsProp.ChartDatas} </div>
              ) : (
                <></>
              )}
            </Col>
            {cardsProp.properties ? (
              <div className="cardRow special__props">
                {cardsProp.properties.notResponding > 0 ? (
                  <>
                    <span
                      className="badge-40 bg-danger text-white"
                      title={`Not Responding : ${cardsProp.properties.notResponding}`}
                    >
                      <i className="bx bxs-timer"></i>
                    </span>{" "}
                    &nbsp;
                  </>
                ) : (
                  ""
                )}
                {userType === "1100" || userType === "1110" ? (
                  cardsProp.properties.newResume &&
                  cardsProp.properties.newResume.length > 0 ? (
                    <>
                      <span
                        className="badge-40 bg-success text-white"
                        title={`New Resume Uploaded : ${
                          cardsProp.properties.newResume &&
                          cardsProp.properties.newResume.length
                        }`}
                      >
                        <i className="bx bxs-file"></i>
                      </span>{" "}
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* {cardsProp.properties && cardsProp.properties.newComemnt ? (
                  <>
                    <span
                      className="badge-40 badge-4 text-white"
                      title={`New Comments`}
                    >
                      <i className="bx bxs-message-square-dots"></i>
                    </span>{" "}
                    &nbsp;
                  </>
                ) : (
                  ""
                )} */}
                {cardsProp.properties.blacklisted > 0 ? (
                  <>
                    <span
                      className="badge-40 red"
                      title={`Blacklisted : ${cardsProp.properties.blacklisted}`}
                    >
                      <i className="bx bx-block"></i>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <></>
            )}
          </Row>
        </div>
        <div className="cardBody" onClick={cardsProp.jobLink}>
          <div className="cardPriority">
            <Link onClick={cardsProp.jobLink}>
              <h2 className="cardTitle">{cardsProp.Heading}</h2>
            </Link>
            {userType === "1000" || userType === "1110" ? (
              cardsProp.company ? (
                <div className="cardRow">
                  <strong>Company :</strong>
                  <p>{cardsProp.company}</p>
                </div>
              ) : (
                <></>
              )
            ) : (
              ""
            )}
            {cardsProp.skills ? (
              <div className="cardRow">
                <strong>Skills :</strong>
                <p>{cardsProp.skills}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="cardPriority otherDetails">
            {cardsProp.posted ? (
              <div className="cardRow">
                <strong>Posted On :</strong>
                <p>{cardsProp.posted}</p>
              </div>
            ) : (
              <></>
            )}
            {cardsProp.location ? (
              <div className="cardRow">
                <strong>Locations :</strong>
                <p>{cardsProp.location}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </span>
      <div className="cardFooter">
        <Row>
          <Col xs={5}>
            <div className="jobType">
              <p className={`m-0 jt-${cardsProp.jobType}`}>
                {cardsProp.jobType}
              </p>
            </div>
          </Col>
          <Col xs={7}>
            <p className="m-0">{cardsProp.FavIcon}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default JobsCard;
