let INITIAL_STATE_ID = {
    userName: ""
};
export default (state = INITIAL_STATE_ID, action) => {
    switch (action.type) {
        case "SET_DB_JOB_ID":
            console.log("payload:", action.payload, "state:", state, "mainjobid in the Reducer");
            // return Object.assign({}, { ...state }, { userName: action.payload });
            return Object.assign(action.payload);
        default:
            return state;
    }
};

