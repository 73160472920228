 const hostUrl = process.env.REACT_APP_HOST_URL;
 const extractionHostUrl = process.env.REACT_APP_EXTRACTION_HOST_URL;
const endPoints = {
  checkUserRegistration: `${hostUrl}/auth/register`,
  loginUser: `${hostUrl}/auth/login`,
  register: `${hostUrl}/auth/register`,
  verifyUser: `${hostUrl}/auth/verify`,
  updateToken: `${hostUrl}/auth/renewtoken`,
  resendOtp: `${hostUrl}/auth/resendotp`,
  signupComplete: `${hostUrl}/auth/register`,
  logOut: `${hostUrl}/auth/logout`,
  healthCheck: `${hostUrl}/healthcheck`,
  healthCheckAll: `${hostUrl}/healthcheckall`,
  videoCall: `${hostUrl}/s3/upload`,
  resetToken: `${hostUrl}/auth/resettoken`,
  forgetPassword: `${hostUrl}/auth/forgetpassword`,
  updatePassword: `${hostUrl}/auth/forgetpassword`,
  validateToken: `${hostUrl}/auth/validateToken`,
  validateUnsubscribe: `${hostUrl}/auth/validateUnsubscribe`,
  Unsubscribe: `${hostUrl}/auth/unsubscribe`,
  publicLookup: `${hostUrl}/pub/lookup`,
  lookup: `${hostUrl}/lookup`,
  redisLookup: `${hostUrl}/lookup`,
  getLookup: `${hostUrl}/lookup/gettrackercolumn`,
  getLocationLookup: `${hostUrl}/lookup/getLocation`,
  getCompanyLookup: `${hostUrl}/lookup/getCompany`,
  getCollegeLookup: `${hostUrl}/lookup/getCollege`,
  getQualificationLookup: `${hostUrl}/lookup/getQualLookup`,
  getDesignationLookup: `${hostUrl}/lookup/getDesigLookup`,
  getLocation: `${hostUrl}/lookup/Location`,
  globalSearch: `${hostUrl}/globalSearch`,
  notifications: `${hostUrl}/getNotification`,
  countNotification: `${hostUrl}/getNotification/count`,
  //notifications: `${hostUrl}/getNotification`,
  undersubscribedJobs: `${hostUrl}/vendor/undersubscribedJobs`,
  uploadResume: `${hostUrl}/vendor/uploadResume`,
  uploadMultipleResume: `${hostUrl}/vendor/uploadMultipleResume`,
  uploadFileResume: `${hostUrl}/file`,
  uploadFile: `${hostUrl}/file`,
  contactUs: `${hostUrl}/contact-us`,
  corporateUploadResume: `${hostUrl}/corp/uploadMultipleResume`,
  getAllTempUser: `${hostUrl}/admin/allTempUser`,
  getAllUser: `${hostUrl}/admin/allActiveUser`,
  getAllPendingJob: `${hostUrl}/admin/allPendingJob`,
  getAllVendorList: `${hostUrl}/admin/allVendor`,
  getAllCorporateList: `${hostUrl}/admin/allCorporate`,
  activateUser: `${hostUrl}/admin/user/activate`,
  blockUser: `${hostUrl}/admin/user/block`,
  captureVideoStream: `${extractionHostUrl}/stream`,
  //annotation: `${extractionHostUrl}/annotation`,
  liveStream: `${hostUrl}/stream/info`,
  createStream: `${hostUrl}/stream/info`,
  getStreamUser: `${hostUrl}/stream/getUser`,
  extracOtherJob: `${extractionHostUrl}/predict/resume`,
  multipleResumeExtract: `${extractionHostUrl}/predict/text`,
  uploadResumeOther: `${hostUrl}/vendor/uploadResumeOther`,
  searchOtherCandidates: `${hostUrl}/admin/searchOtherCandidates`,
  companyCoOwners: `${hostUrl}/admin/getCoOwners`,
  getCompanies: `${hostUrl}/admin/allCompanies`,
  jod: `${hostUrl}/admin/jod`,
  alignJd: `${extractionHostUrl}/align`,
  generatePasswprd: `${hostUrl}/admin/newPassword`,
  // `${extractionHostUrl}/stream`,
  // https://api1.talenq.in/predict/resume
  // (cId, jobId) => `${extractionHostUrl}/predict/jd/${cId}/jobId`,
};

export const dEndPoints = {
  coOwner: (corporateId) => `${hostUrl}/corp/${corporateId}/coowners`,
  coOwnerAdmin: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/coowners/${jobId}`,
  checkReqId: (corporateId) => `${hostUrl}/corp/${corporateId}/req`,
  rateCard: (corporateId) => `${hostUrl}/corp/${corporateId}/req/ratecard`,
  postJobCreation: (corporateId) => `${hostUrl}/corp/${corporateId}/req`,
  updateJob: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}`,
  deleteJob: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}`,
  corporateDashboard: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/dashboard`,
  corpGlobalSearch: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/globalSearch`,
  corporateJobDetails: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}`,
  corpCandidateUpdate: (corporateId, jobId, canProfileId) =>
    `${hostUrl}/corp/${corporateId}/changestatus/${jobId}/${canProfileId}`,
  corpCandidateComment: (corporateId, jobId, canProfileId) =>
    `${hostUrl}/corp/${corporateId}/changestatus/${jobId}/${canProfileId}/createComment`,
  corporateCandidatesDetails: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}/candidates`,
  scheduleInterview: (corporateId, jobId, canProfileId) =>
    `${hostUrl}/corp/${corporateId}/schedule/${jobId}/${canProfileId}`,
  displayInterviews: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/displayInterviews`,
  getAllInterviewSchedule: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/allInterviewSchedule`,
  getAllInterviewScheduleAdmin: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/allInterviewScheduleAdmin`,
  updateInterview: (corporateId, slotId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/updateInterview/${slotId}`,
  candidateInterviews: (corporateId, jobId, candidateProfileId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/candidateInterviews/${jobId}/${candidateProfileId}`,
  instantBookSlot: (corporateId, jobId, candidateProfileId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/instant/${jobId}/${candidateProfileId}`,

  getChat: (corporateId, jobId, corporateUserId, vendorUserId) =>
    `${hostUrl}/corp/${corporateId}/chat/${jobId}/${corporateUserId}/${vendorUserId}`,
  postChat: (corporateId) => `${hostUrl}/corp/${corporateId}/chat`,
  vendorChatList: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/chat/${jobId}/vendorList`,
  getSetting: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/user/${userId}`,
  addUser: (corporateId) => `${hostUrl}/corp/${corporateId}/user`,
  getAllCompUsers: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/user/allusers`,
  updateUser: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/user/${userId}`,
  disableUser: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/user/${userId}`,
  updateAccountSetting: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/userAccount/updateUser/${userId}`,
  updateNotificationSetting: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/userAccount/updateNotification/${userId}`,
  updatePasswordSetting: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/userAccount/updatePassword/${userId}`,
  getCompanyInfo: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/company/info`,
  updateCompanyInfo: (corporateId) => `${hostUrl}/corp/${corporateId}/company`,
  corporateAllResumes: (corporateId) =>
    `${hostUrl}/corp/${corporateId}/allResumes`,
  downloadCorpAllResumes: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/downloadAllResume/${jobId}`,
  downloadTracker: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/export/${jobId}`,
  candidateAnalytics: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/recommend/${jobId}/analytics`,
  getCandidateRecommendation: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/recommend/${jobId}`,
  createRecommendation: (corporateId, candId, jobId) =>
    `${hostUrl}/corp/${corporateId}/recommend/${candId}/${jobId}`,

  updateComment: (corporateId, jobId, candidateId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}/${candidateId}/updateComment`,
  updateCandidateView: (corporateId, jobId, candidateId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}/${candidateId}/updateViewed`,
  moveCandidateToAnotherJobAdmin: (corporateId, jobId, candidateId) =>
    `${hostUrl}/corp/${corporateId}/req/${jobId}/${candidateId}/movetonew`,
  moveCandidateToAnotherJobVendor: (vendorCorporateId, jobId, candidateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}/${candidateId}/movetonew`,
  checkDuplicateByAdmin: (corporateId, candidateId) =>
    `${hostUrl}/corp/${corporateId}/req/${candidateId}/checkduplicate`,
  checkDuplicateByVendor: (vendorCorporateId, candidateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${candidateId}/checkduplicate`,
  vendorDashboard: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/dashboard`,
  vendorJobDetails: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}`,
  vendorCandidatesDetails: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}/candidates`,
  createCanProfile: (vendorCorporateId, jobId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}/candidateProfile/${canProfileId}`,

  vendorCandidateComment: (vendorCorporateId, jobId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}/${canProfileId}/createComment`,
  updateCandidateProfile: (vendorCorporateId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${canProfileId}`,
  updateCandidateProfileAdmin: (jobId, canProfileId) =>
    `${hostUrl}/admin/${jobId}/candidateinterview/${canProfileId}`,
  findRequirement: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/findJobs`,
  undersubscribedJobs: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/undersubscribedJobs`,
  vendorGlobalSearch: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/globalSearch`,
  vendorCandidateUpdate: (vendorCorporateId, jobId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}/changestatus/${canProfileId}`,
  vendorAllInterviewSchedule: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/allInterviewSchedule`,
  interviewScheduleFromVendor: (vendorCorporateId, jobId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/${jobId}/${canProfileId}`,
  updateCommentFromVendor: (vendorCorporateId, jobId, candidateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/job/${jobId}/${candidateId}/updateComment`,
  AcceptSlotWithTime: (vendorCorporateId, jobId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/${jobId}/${canProfileId}/timeSlot`,
  DeclinedSlotFromVendor: (vendorCorporateId, slotId, jobId, canProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/${slotId}/${jobId}/${canProfileId}`,
  getVendorChat: (vendorCorporateId, jobId, corporateUserId, vendorUserId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/chat/${jobId}/${corporateUserId}/${vendorUserId}`,
  postVendorChat: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/chat`,
  getVendorAllCorporateList: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/allCorporate`,
  enrollJob: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/associateJobToVendor`,
  getUserSetting: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/user/${userId}`,
  addVendorUser: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/user`,
  getAllVendorCompUsers: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/user/allusers`,
  updateVendorUser: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/user/${userId}`,
  disableVendorUser: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/user/${userId}`,
  updateVendorAccountSetting: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/userAccount/updateUser/${userId}`,
  updateVendorNotificationSetting: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/userAccount/updateNotification/${userId}`,
  updateVendorPasswordSetting: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/userAccount/updatePassword/${userId}`,
  getVendorCompanyInfo: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/compInfo`,
  updateVendorCompanyInfo: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/compInfo`,
  vendorAllResumes: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/allResumes`,
  uploadCandidateAssignment: (vendorCorporateId, candId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/uploadAssignment/${candId}`,
  vendorCandidateAnalytics: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/recommend/${jobId}`,
  vendorCandidateRecommendation: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/recommend/analytics/${jobId}`,
  vendorCalenderInterviews: (vendorCorporateId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/calenderInterviews`,
  RankingAndRecommendationResume: (jobId, vId, candId) =>
    `${extractionHostUrl}/form/resume/${jobId}/${vId}/${candId}`,
  RecommendationJd: (cId, jobId) =>
    `${extractionHostUrl}/form/jd/${cId}/${jobId}`,
  vendorDownloadAllResumes: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/downloadAllResume/${jobId}`,
  extractResume: (jobId, vId, candId) =>
    `${extractionHostUrl}/predict/resume/${jobId}/${vId}/${candId}`,
  editCandidate: (jobId, vId, candId) =>
    `${extractionHostUrl}/edit/resume/${jobId}/${vId}/${candId}`,
  editJob: (cId, jobId) => `${extractionHostUrl}/edit/jd/${cId}/${jobId}`,
  checkDuplicateResume: (jobId, vId, candId) =>
    `${extractionHostUrl}/form/appliedbefore/${jobId}/${vId}/${candId}`,
  predictionResume: (jobId, vId, candId) =>
    `${extractionHostUrl}/predict/resume/content/${jobId}/${vId}/${candId}`,
  extracJd: (cId, jobId) => `${extractionHostUrl}/predict/jd/${cId}/jobId`,
  annotationDashboard: (dateOutput, role) =>
    `${extractionHostUrl}/dashboard/${dateOutput}/${role}`,
  annotationExtract: (date, status) =>
    `${extractionHostUrl}/annotation/${date}/${status}`,
  updateExtract: (id) => `${extractionHostUrl}/annotation/${id}`,
  predictionJd: (cId, jobId) =>
    `${extractionHostUrl}/predict/jd/content/${cId}/jobId`,
  fetchJdFile: (jobId) => `${extractionHostUrl}/convert/${jobId}`,
  downloadVendorTracker: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/export/${jobId}`,

  approveJob: (jobId) => `${hostUrl}/admin/approve/${jobId}`,
  rejectJob: (jobId) => `${hostUrl}/admin/reject/${jobId}`,
  updateCorporate: (userId) => `${hostUrl}/admin/allCorporate/${userId}`,
  updateVendor: (userId) => `${hostUrl}/admin/allVendor/${userId}`,
  getVendorUserAdmin: (userId) => `${hostUrl}/admin/getVendor/${userId}`,
  getCorporateUserAdmin: (userId) => `${hostUrl}/admin/getCorporate/${userId}`,
  otherCandidate: (otherCandidateId) =>
    `${hostUrl}/admin/${otherCandidateId}/othercandidate`,
  createtrackercolumn: (corporateId) =>
    `${hostUrl}/admin/createtrackercolumn/${corporateId}`,
  getCorporateJobCount: (corporateId) =>
    `${hostUrl}/admin/getCorporateJobCount/${corporateId}`,
  getVendorJobCount: (vendorCorporateId) =>
    `${hostUrl}/admin/getVendorJobCount/${vendorCorporateId}`,
  getCorporateJobDetails: (corporateId) =>
    `${hostUrl}/admin/getCorporateJobDetails/${corporateId}`,
  getAllOpenJobs: () => `${hostUrl}/admin/filterStatusJobs`,
  joboftheday: (jobId) => `${hostUrl}/admin/${jobId}/jobOfTheDay`,
  blacklist: (jobId, candidateProfileId) =>
    `${hostUrl}/admin/${jobId}/${candidateProfileId}/blacklistCandidate`,
  deleteCorporateAdminUser: (corporateId) =>
    `${hostUrl}/admin/corporate/delete/${corporateId}`,
  deleteVendorAdminUser: (vendorCorporateId) =>
    `${hostUrl}/admin/vendor/delete/${vendorCorporateId}`,
  enableDisableResumeUpload: (jobId) =>
    `${hostUrl}/admin/${jobId}/setUploadResume`,
  corporatefavouriteJob: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/${jobId}/favouriteStatus`,
  vendorfavouriteJob: (corporateId, jobId) =>
    `${hostUrl}/vendor/${corporateId}/${jobId}/favouriteStatus`,
  interviewRounds: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/rounds/${jobId}`,
  interviewSlots: (corporateId, roundId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/slots/${roundId}`,
  createInterviewRound: (corporateId, jobId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/${jobId}`,
  slotBooking: (corporateId, slotId, roundId, candidateProfileId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/${slotId}/${roundId}/${candidateProfileId}`,
  candidateSlots: (corporateId, jobId, candidateProfileId) =>
    `${hostUrl}/corp/${corporateId}/interviewScheduling/candidateSlots/${jobId}/${candidateProfileId}`,
  vendorInterviewRounds: (vendorCorporateId, jobId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/vendorrounds/${jobId}`,
  vendorInterviewSlots: (vendorCorporateId, roundId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/vendorslots/${roundId}`,
  vendorcandidateSlots: (vendorCorporateId, jobId, candidateProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/candidateSlots/${jobId}/${candidateProfileId}`,
  updateProposedConfirm: (
    vendorCorporateId,
    jobId,
    candidateProfileId,
    slotId
  ) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/candidateConfirm/${jobId}/${candidateProfileId}/${slotId}`,
  vendorSlotBooking: (vendorCorporateId, slotId, roundId, candidateProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/bookslot/${slotId}/${roundId}/${candidateProfileId}`,
  vendorLandingPage: (userId) => `${hostUrl}/vendor/${userId}/landingPage`,

  sendEmailToCandidate: (userId) => `${hostUrl}/${userId}/sendCandidate`,
  corporateLandingPage: (userId) => `${hostUrl}/corp/${userId}/landingPage`,
  corpBucketDetails: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/${userId}/bucket`,
  superAdminBucketDetails: (userId) => `${hostUrl}/admin/${userId}/bucket`,
  vendorBucketDetails: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/${userId}/bucket`,
  bucketSetting: (userId) => `${hostUrl}/${userId}/bucket`,
  updateBucketCorporate: (corporateId, userId, bucketId) =>
    `${hostUrl}/corp/${corporateId}/${userId}/bucket/${bucketId}`,
  updateBucketVendor: (vendorCorporateId, userId, bucketId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/${userId}/bucket/${bucketId}`,
  deleteBucket: (bucketId) => `${hostUrl}/admin/deleteBucket/${bucketId}`,

  gebucketId: (bucketId) => `${hostUrl}/${bucketId}/bucketOne`,
  createBucket: (corporateId, userId) =>
    `${hostUrl}/corp/${corporateId}/${userId}/bucket`,
  createVendorBucket: (vendorCorporateId, userId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/${userId}/bucket`,
  loadBucketQuery: (userId) => `${hostUrl}/${userId}/query`,
  vendorCandidateInterviews: (vendorCorporateId, jobId, candidateProfileId) =>
    `${hostUrl}/vendor/${vendorCorporateId}/schedule/candidateInterviews/${jobId}/${candidateProfileId}`,
};

export default endPoints;
