import React, { useEffect } from "react";

const DailyResumeGraph = (props) => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return null;
    }
    window.am4core.useTheme(window.am4themes_animated);
    // Create chart instance
    var chart = window.am4core.create("chartdiv", window.am4charts.XYChart);
    var data = props.graphPlotting.map((item) => {
      return { date: item._id, value: item.count };
    });

    chart.data = data;

    // Create axes
    var dateAxis = chart.xAxes.push(new window.am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.minZoomCount = 2;

    // this makes the data to be grouped
    dateAxis.groupData = true;
    dateAxis.groupCount = 500;
    dateAxis.title.text = "Months";
    dateAxis.title.fontWeight = "bold";
    // var valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
    var valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 50;
    valueAxis.title.text = "Resume counts";
    valueAxis.title.fontWeight = "bold";
    var series = chart.series.push(new window.am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.tooltipText = "{valueY}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.background.fillOpacity = 0.5;

    var circleBullet = series.bullets.push(new window.am4charts.CircleBullet());
    circleBullet.circle.stroke = window.am4core.color("#ff00");
    circleBullet.circle.strokeWidth = 2;

    var labelBullet = series.bullets.push(new window.am4charts.LabelBullet());
    labelBullet.label.text = "{value}";
    labelBullet.label.dy = -15;

    chart.cursor = new window.am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    var scrollbarX = new window.am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    chart.scrollbarX = scrollbarX;

    // Add range selector
    var selector = new window.am4plugins_rangeSelector.DateAxisRangeSelector();
    selector.container = document.getElementById("selectordiv");
    selector.axis = dateAxis;
    selector.position = "right";
  }, [props]);
  return (
    <div>
      <div
        id={"chartdiv"}
        style={{
          width: "100%",
          // width: "560px",
          height: "450px",
        }}
      ></div>
      <div
        id={"selectordiv"}
        style={{
          width: "100%",
          // width: "560px",
          height: "450px",
        }}
      ></div>
    </div>
  );
};
export default DailyResumeGraph;
