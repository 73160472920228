import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { dEndPoints } from "../config/end-point";
import axios from "axios";
const NewUsersHome = (props) => {
  const [companyInfo, setCompanyInfo] = useState({});
  useEffect(() => {
    loadCompanyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadCompanyInfo = () => {
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";

    axios
      .get(dEndPoints.getCompanyInfo(corporateId))
      .then((res) => {
        let data = res.data;
        if (data && data.code === 100000) {
          setCompanyInfo(data.data && data.data.responseData);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };
  return (
    <div className="nodata__newuser-logged">
      <Container>
        <Row>
          <Col md={7} className="d-flex align-items-center">
            <div className="welcome__wrapper">
              <h1 className="welcome__msg">Welcome</h1>
              <p className="para">
                {companyInfo.companyName} Now You're able to create Jobs & Hire
                best candidate for your Organization
              </p>
              <Link to="/createjob" className="btn btn-primary">
                Create Job
              </Link>
            </div>
          </Col>
          <Col md={5}>
            <div className="img__wrapper">
              <img
                src="https://talenq.r.worldssl.net/talenq/img/homeHire.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
        <section className="process-effects-layer margin__private-Dashboard">
          <Row>
            <Col md={6} lg={3} className="sm-mb-30">
              <div className="tq-addon-number">
                <div className="number-part">
                  <div className="number-image">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/register.png"
                      alt="register"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      <span className="number-prefix"> 1 </span>
                    </div>
                    <div className="number-title">
                      <h3 className="title"> Create Job</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className="sm-mb-30">
              <div className="tq-addon-number">
                <div className="number-part">
                  <div className="number-image">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/connectReput.png"
                      alt="connect-expert"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      <span className="number-prefix"> 2 </span>
                    </div>
                    <div className="number-title">
                      <h3 className="title">Upload JD or Fill Requirements</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className="sm-mb-30">
              <div className="tq-addon-number">
                <div className="number-part">
                  <div className="number-image">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/relevantProfile.png"
                      alt="relevent-profile"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      {" "}
                      <span className="number-prefix"> 3 </span>
                    </div>
                    <div className="number-title">
                      <h3 className="title">Hit Post Job Button</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className="sm-mb-30">
              <div className="tq-addon-number">
                <div className="number-part">
                  <div className="number-image">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/relevantProfile.png"
                      alt="relevent-profile"
                    />
                  </div>
                  <div className="number-text">
                    <div className="number-area">
                      {" "}
                      <span className="number-prefix"> 4 </span>
                    </div>
                    <div className="number-title">
                      <h3 className="title">
                        Track Your Jobs Status on DashBoard
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <div className="add__user-wrapper mt-5">
          <div className="row">
            <div className="align-self-center col-8">
              <p className="instruction">
                Create Job With Us and Grow Your Business
              </p>
            </div>
            <div className="text-right col-4">
              <Link to="/createjob" className="btn btn-primary">
                Create your First Job Now
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default NewUsersHome;
