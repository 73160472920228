import React, { useState, useEffect } from "react";
import JobVsResume from "../../components/dashboardCharts/JobVsResume";
import schadule from "../../assets/images/schadule.svg";
import resumeImg from "../../assets/images/resumes.svg";
import DailyResumeGraph from "../../components/dashboardLayouts/DailyResumeGraph";
import Row from "react-bootstrap/Row";
import FormControl from "react-bootstrap/FormControl";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import JobsCard from "../../components/jobcards/JobsCard";
import "../../assets/css/dashboard.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserName, setjobId } from "../../actions/index";
import { showLoader, showSnackBar } from "../../actions/index";
import axios from "axios";
import { useHistory } from "react-router";
import Chart from "../../pages/Chart";
import trackEvent from "../../utils/analytics-manager";
import Badge from "../../components/badge/Badge";
import ClickIndicator from "../badge/indicator/ClickIndicator";
import { dEndPoints } from "../../config/end-point";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@material-ui/core/Tooltip";
// --------- Default bucket Card ---------------
const DefaultBucketDashboard = (props) => {
  return (
    <div className="status-card default__bucket">
      <div className="status-card__icon">
        <img src={props.icon} alt={props.icon} />
      </div>
      <div className="status-card__info">
        <h4 className="card__Count">{props.count}</h4>
        <span className="card__title">{props.title}</span>
      </div>
    </div>
  );
};
const CustomeBucketDashboard = (props) => {
  return (
    <div className="status-card default__bucket">
      <div className="status-card__info">
        <img src={props.icon} alt={props.icon} />
        <h4 className="card__Count">{props.count}</h4>
        <span className="card__title">{props.title}</span>
      </div>
    </div>
  );
};
// --------- Quick Action Card ---------------
const QuickActionCard = (props) => {
  return (
    <div className={`card__two ${props.className}`}>
      <ClickIndicator />
      <span className="card__count-No"> {props.count}</span>
      <h3 className="card__title">{props.title}</h3>
    </div>
  );
};

const CorporateDashboard = (props) => {
  const [defaultBucket, setDefaultBucket] = useState([]);
  const [customBucket, setCustomBucket] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [closeJob, setCloseJob] = useState(false);
  //const [sort_createdon, setsort_createdon] = useState(-1);
  const [PendingBucket, setPendingBucket] = useState({});
  const [screenedBucket, setScreenedBucket] = useState({});
  const [immediateBucket, setImmediateBucket] = useState({});
  const [inProcessBucket, setInprocessBucket] = useState({});
  const [todayResume, setTodayResume] = useState({});
  const [plotting, setPlotting] = useState([]);
  const [graphPlotting, setGraphPlotting] = useState([]);
  const [todaySchedule, setTodaySchedule] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState({
    closedComment: [],
    statHis: [],
    coOwners: [],
  });
  let history = new useHistory();
  let userID = localStorage.getItem("i");
  useEffect(() => {
    reloadJobs();
    loadCompanyInfo();
    landingPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editJob = (jobId, data) => {
    let cId = localStorage.getItem("ci");
    axios
      .put(dEndPoints.editJob(cId, jobId), data)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
  };

  const clickDashboardRequirement = (jobId) => {
    history.push(`/alljobs/${jobId}`);
  };

  function handleViewRequirement(jobId) {
    props.setUserName({ userName: jobId });
    history.push(`/createjob/${jobId}`);
    trackEvent({
      type: "EDIT_JOB",
      data: {
        jobId: jobId,
      },
    });
  }
  const reloadJobs = (pageNum = 0) => {
    var sort_createdon = -1;
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while loadig data v", error);
        });
    } else {
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  const updateJob = (e, status, jobId, statHis, coOwners, closedComment) => {
    e.preventDefault();
    const corporateId = localStorage.getItem("ci");

    var cjob = { ...job, statHis, coOwners, closedComment };

    cjob.status = status;

    props.showLoader({ show: true });

    axios
      .patch(dEndPoints.updateJob(corporateId, jobId), cjob)
      .then((res) => {
        props.showLoader({ show: false });
        var data = res.data;
        editJob(jobId, data);
        handleCloseJob();
        reloadJobs();
        landingPage();
        props.showSnackBar({
          message: "job Updated Successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
      })
      .catch((error) => {
        props.showLoader({ show: false });
        props.showSnackBar({
          message: `Something went wrong. Please contact support team`,
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      });
  };
  const handleShowCloseJob = (e, jobId) => {
    e.preventDefault();
    var cjob = { ...job, jobId };
    var feedback = cjob && cjob.feedback ? cjob.feedback : {};
    cjob.feedback = feedback;
    setJob(cjob);
    setCloseJob(true);
  };
  const handleCloseJob = () => {
    setCloseJob(false);
    var cjob = { ...job };
    cjob.closedComment = [];
    setJob({ ...job, ...cjob });
  };

  function handleInputChange(event) {
    var cjob = { ...job };
    if (event.target.name === "is_talenq_closed") {
      var closeJob = cjob && cjob.feedback ? cjob.feedback : {};
      closeJob[event.target.name] = event.target.value === "true";
      cjob.feedback = closeJob;
    }
    if (event.target.name === "closedComment") {
      cjob[event.target.name] = event.target.value;
    } else {
      cjob[event.target.id] = event.target.value;
    }

    setJob({ ...job, ...cjob });
  }
  const loadCompanyInfo = () => {
    const corporateId = localStorage.getItem("ci");
    axios
      .get(dEndPoints.getCompanyInfo(corporateId))
      .then((res) => {
        let data = res.data;
        if (data && data.code === 100000) {
          setCompanyInfo(data.data && data.data.responseData);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };
  const userType = localStorage.getItem("ut");

  var landingPage = (pageNum = 0) => {
    // const corporateId = localStorage.getItem("ci");
    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    if (userType === "1000") {
      axios
        .get(dEndPoints.vendorLandingPage(userId), {})
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100185) {
            if (data.data.defaultBucket) {
              setDefaultBucket(data.data.defaultBucket);
            }

            setLoading(false);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      axios
        .get(dEndPoints.corporateLandingPage(userId), {})
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100185) {
            if (data.data.defaultBucket) {
              setDefaultBucket(data.data.defaultBucket);
            }
            if (data.data.pendingResume) {
              setPendingBucket(data.data.pendingResume);
            }
            if (data.data.screenedResume) {
              setScreenedBucket(data.data.screenedResume);
            }
            if (data.data.todayResume) {
              setTodayResume(data.data.todayResume);
            }
            if (data.data.customBucket) {
              setCustomBucket(data.data.customBucket);
            }
            if (data.data.todaySchedule) {
              setTodaySchedule(data.data.todaySchedule);
            }
            if (data.data.plotGraph) {
              setPlotting(data.data.plotGraph);
            }
            if (data.data.dailyResumeFlow) {
              setGraphPlotting(data.data.dailyResumeFlow);
            }
            if (data.data.immediadte) {
              setImmediateBucket(data.data.immediadte);
            }
            if (data.data.inprocessResume) {
              setInprocessBucket(data.data.inprocessResume);
            }
            setLoading(false);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const favouriteJob = (jobId, favjob) => {
    let vendorCorpId = localStorage.getItem("vci");
    const corporateId = localStorage.getItem("ci");
    if (userType === "1110" || userType === "1100") {
      axios
        .patch(dEndPoints.corporatefavouriteJob(corporateId, jobId), null, {
          params: {
            fav: favjob,
          },
        })
        .then((res) => {
          var data = res.data;
          if (data && data.code === 100141) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          } else if (data && data.code === 100173) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100142
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    } else {
      axios
        .patch(dEndPoints.vendorfavouriteJob(vendorCorpId, jobId), null, {
          params: {
            fav: favjob,
          },
        })
        .then((res) => {
          var data = res.data;
          if (data && data.code === 100141) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          } else if (data && data.code === 100173) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            reloadJobs();
            return;
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === 100142
          ) {
            props.showSnackBar({
              message: error.response.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          }
        });
    }
  };
  const BucketCard = (props) => {
    return (
      <div className="bucket__card status-card">
        <i className={props.icon}></i>
        <div className="status-card__info">
          <h4>{props.title}</h4>
        </div>
      </div>
    );
  };
  var finaJobs = [];
  jobs &&
    jobs.map((item, index) => {
      if (item.status === "Open") {
        finaJobs.push(item);
      }
    });
  const itemsJob = finaJobs ? finaJobs.slice(0, 3) : [];
  return (
    <Container fluid className="carporate__dashboard-page">
      <div className="page__header-wrapp mb-4">
        <h2 className="page-header">Dashboard</h2>
      </div>

      <Row>
        {/* --------------------- corporate dashboard starts here ---------- */}
        {plotting.length > 0 ? (
          <Col sm={8} className="mb-4">
            <h3 className="dashboard__sub-heading mb-4">
              Overview of Resume flow across Requirements
            </h3>
            <h6>(Total Resume Vs Total Position)</h6>

            {loading === true ? (
              <Skeleton height={180} />
            ) : (
              <div className="plot__graph position-relative mt-4">
                <small>Scroll the below bar for graph details. </small>
                <JobVsResume plotting={plotting} />
              </div>
            )}
          </Col>
        ) : (
          ""
        )}
        <Col sm={4} className="mb-4 mt-5">
          <div className="uniq__card-wrapp">
            {/* ------- Today's Schadule card */}

            <div className="u__card-1 uniq__cards mb-4">
              <ClickIndicator />
              <span className="card__texture">
                {" "}
                <img src={schadule} alt="Schadule" />
              </span>

              <Link to={`/tabledetails?${todaySchedule.query}`}>
                <span className="card__count-No">
                  {" "}
                  {todaySchedule ? todaySchedule.count : ""}
                </span>
                <h3 className="card__title">
                  {todaySchedule ? todaySchedule.title : ""}
                </h3>
              </Link>
            </div>

            {/* ------- uploded profiles card */}

            <div className="u__card-2 uniq__cards">
              <ClickIndicator />
              <span className="card__texture">
                {" "}
                <img src={resumeImg} alt="Schadule" />
              </span>

              <Link to={`/tabledetails?${todayResume.query}`}>
                <span className="card__count-No ">
                  {" "}
                  {todayResume ? todayResume.count : ""}
                </span>
                <h3 className="card__title">
                  {todayResume ? todayResume.title : ""}
                </h3>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="my-5 ">
        <h3 className="dashboard__sub-heading mb-5 p-0">Latest Jobs</h3>
        {itemsJob.map((item, index) => (
          <Col md={4} className="mt-5">
            <JobsCard
              badge={<Badge type={item.status} content={item.status} />}
              ChartDatas={
                !item.rCt ||
                (item.rCt &&
                  !item.rCt.Pending &&
                  !item.rCt.Screened &&
                  !item.rCt.OfferRejected &&
                  !item.rCt.InProcess &&
                  !item.rCt.Onhold &&
                  !item.rCt.RoundClear &&
                  !item.rCt.Scheduled &&
                  !item.rCt.RoundCompleted &&
                  !item.rCt.OfferRevoked &&
                  !item.rCt.Offered &&
                  !item.rCt.Proposed &&
                  !item.rCt.Dropped &&
                  !item.rCt.Declined &&
                  !item.rCt.Rejected &&
                  !item.rCt.OfferAccepted &&
                  !item.rCt.Joined) ? (
                  <div align="center">
                    <span>No resume yet!</span>
                    {/* <p>We're work on it.</p> */}
                  </div>
                ) : (
                  <div align="center">
                    <Chart
                      jobId={item._id}
                      resumeCount={item.rCt ? item.rCt : {}}
                    />
                  </div>
                )
              }
              jobLink={() => clickDashboardRequirement(item._id)}
              CardValue={item._id}
              jodstatus={item.jobOfTheDay}
              jobType={item.jobType}
              reqId={item.reqId}
              Heading={item.desig}
              posted={new Date(item.cOn).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              company={item.cName}
              skills={item.pSkills.toString()}
              location={item.city + "    "}
              FavIcon={
                <div className="buttonsGroup">
                  {item.status === "Open" ? (
                    <>
                      <div>
                        {item.favourite &&
                        item.favourite.filter((item) => item === userID)[0] ? (
                          <button
                            title="Remove favourite"
                            className="jobAction"
                          >
                            <i
                              className="bx bxs-heart"
                              style={{ color: "red" }}
                              onClick={() => {
                                favouriteJob(item._id, "false");
                              }}
                            ></i>
                          </button>
                        ) : (
                          <button title="Favourite" className="jobAction">
                            <i
                              className="bx bx-heart"
                              onClick={() => {
                                favouriteJob(item._id, "true");
                              }}
                            ></i>
                          </button>
                        )}
                      </div>
                      <div align="center" title="Edit Job">
                        <button
                          className="jobAction"
                          onClick={(e) => {
                            handleViewRequirement(item._id);
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          value={item._id}
                        >
                          <i className="bx bx-edit"></i>
                        </button>
                      </div>
                      <div title="Inactive Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(event) =>
                            updateJob(
                              event,
                              "Inactive",
                              item._id,
                              item.statHis,
                              item.coOwners
                            )
                          }
                        >
                          <i className="bx bx-hide"></i>
                        </button>
                      </div>
                      <div align="center" title="Close Job">
                        <button
                          className="jobAction"
                          justIcon
                          onClick={(e) => handleShowCloseJob(e, item._id)}
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </>
                  ) : item.status === "Awaiting Approval" ? (
                    <>
                      <div align="center" title="Edit Job">
                        <button
                          className="jobAction"
                          onClick={(e) => {
                            handleViewRequirement(item._id);
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          value={item._id}
                        >
                          <i className="bx bx-edit"></i>
                        </button>
                      </div>
                      <div align="center" title="Close Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(e) => handleShowCloseJob(e, item._id)}
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </>
                  ) : item.status === "Draft" ? (
                    <>
                      <div align="center" title="Edit Job">
                        <button
                          className="jobAction"
                          onClick={(e) => {
                            handleViewRequirement(item._id);
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          value={item._id}
                        >
                          <i className="bx bx-edit"></i>
                        </button>
                      </div>
                      <div title="Post Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(event) =>
                            updateJob(
                              event,
                              "Awaiting Approval",
                              item._id,
                              item.statHis,
                              item.coOwners
                            )
                          }
                        >
                          <i className="bx bx-check-square"></i>
                        </button>
                      </div>
                      <div align="center" title="Close Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(e) => handleShowCloseJob(e, item._id)}
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </>
                  ) : item.status === "Close" ? (
                    <>
                      <div title="Repost Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(event) =>
                            updateJob(
                              event,
                              "Open",
                              item._id,
                              item.statHis,
                              item.coOwners
                            )
                          }
                        >
                          <i className="bx bx-repost"></i>
                        </button>
                      </div>
                    </>
                  ) : item.status === "Inactive" ? (
                    <>
                      <div align="center" title="Edit Job">
                        <button
                          className="jobAction"
                          onClick={(e) => {
                            handleViewRequirement(item._id);
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                          value={item._id}
                        >
                          <i className="bx bx-edit"></i>
                        </button>
                      </div>
                      <div title="Activate Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(event) =>
                            updateJob(
                              event,
                              "Open",
                              item._id,
                              item.statHis,
                              item.coOwners
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                          </svg>
                        </button>
                      </div>
                      <div align="center" title="Close Job">
                        <button
                          className="jobAction"
                          type="submit"
                          onClick={(e) => handleShowCloseJob(e, item._id)}
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              }
            />
          </Col>
        ))}
      </Row>
      <Row className="my-5 ">
        <h3 className="dashboard__sub-heading mb-5 p-0">
          Attention Needed on Profiles
        </h3>
        <Col sm={3} className="mb-sm-5 mb-md-0">
          <Link to={`/tabledetails?${immediateBucket.query}`}>
            <QuickActionCard
              className="qui__card-2"
              count={immediateBucket ? immediateBucket.count : ""}
              title={immediateBucket ? immediateBucket.title : ""}
            />
          </Link>
        </Col>
        <Col sm={3} className="mb-sm-5 mb-md-0">
          <Link to={`/tabledetails?${PendingBucket.query}`}>
            <QuickActionCard
              className="qui__card-3"
              count={PendingBucket ? PendingBucket.count : ""}
              title={PendingBucket ? PendingBucket.title : ""}
            />
          </Link>
        </Col>
        <Col sm={3}>
          <Link to={`/tabledetails?${screenedBucket.query}`}>
            <QuickActionCard
              className="qui__card-1"
              count={screenedBucket ? screenedBucket.count : ""}
              title={screenedBucket ? screenedBucket.title : ""}
            />
          </Link>
        </Col>

        <Col sm={3} className="mb-sm-5 mb-md-0">
          <Link to={`/tabledetails?${inProcessBucket.query}`}>
            <QuickActionCard
              className="qui__card-4"
              count={inProcessBucket ? inProcessBucket.count : ""}
              title={inProcessBucket ? inProcessBucket.title : ""}
            />
          </Link>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12} className="mb-4">
          <h3 className="dashboard__sub-heading mb-5">Job Summary</h3>
          <Row>
            {defaultBucket.map((item, index) => (
              <div className="col-3 mb-3" key={index}>
                <Link to={`/bucketdetails?${item.query}`}>
                  <DefaultBucketDashboard
                    icon={item.icon}
                    count={item.counts}
                    title={item.title}
                  />
                </Link>
              </div>
            ))}
          </Row>
        </Col>
        <Col sm={12} className="mb-4">
          <h3 className="dashboard__sub-heading mb-5">
            Customize Your Job Bucket
          </h3>
          <Row>
            <Col xs={6} md={3} className="mb-4 position-relative">
              <Link to="/bucketSettings/create-bucket">
                <Tooltip title="Create Job Bucket">
                  <span className="info__button">
                    <i className="bx bx-info-circle"></i>
                  </span>
                </Tooltip>
                <BucketCard icon="bx bx-add-to-queue" title="Create Bucket" />
              </Link>
            </Col>
            {customBucket.map((item, index) => (
              <div className="col-3 mb-3" key={index}>
                <Link to={`/custombucketdetails?${item.query}`}>
                  <CustomeBucketDashboard
                    icon={item.icon}
                    count={item.counts}
                    title={item.title}
                  />
                </Link>
              </div>
            ))}
          </Row>
        </Col>
        {graphPlotting.length > 0 ? (
          <Col sm={12}>
            <h3 className="dashboard__sub-heading mb-5">Daily Resume Flow</h3>
            {loading === true ? (
              <Skeleton height={180} />
            ) : (
              <div className="plot__graph position-relative mt-4">
                <small>Scroll the below bar for graph details. </small>
                <DailyResumeGraph graphPlotting={graphPlotting} />
              </div>
            )}
          </Col>
        ) : (
          ""
        )}
        {/* --------------------- corporate dashboard ends here ---------- */}
      </Row>
      <Modal
        show={closeJob}
        dialogClassName="tq__modal-default"
        onHide={handleCloseJob}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Close Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tq-form ">
          <Form.Group>
            <Form.Label className="modal__heading mb-3">
              Did this position close through talenQ
            </Form.Label>
            <div className="form-control">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="yes"
                  name="is_talenq_closed"
                  value={true}
                  checked={
                    job.feedback && job.feedback.is_talenq_closed === true
                  }
                  onClick={handleInputChange}
                />
                <label className="form-check-label" for="pg">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="no"
                  value={false}
                  name="is_talenq_closed"
                  checked={
                    job.feedback && job.feedback.is_talenq_closed === false
                  }
                  onChange={handleInputChange}
                />
                <label className="form-check-label" for="pan">
                  No
                </label>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label className="modal__heading mb-3">
              All pending resume would be Rejected, Please enter any comment.
            </Form.Label>
            <FormControl
              value={job.closedComment}
              onChange={handleInputChange}
              aria-label="Small"
              aria-describedby=""
              placeholder="Your Comment Here...."
              name="closedComment"
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="secondary" onClick={handleCloseJob}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(event) =>
              updateJob(
                event,
                "Close",
                job.jobId,
                job.statHis,
                job.coOwners,
                job.closedComment
              )
            }
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
//export default CorporateDashboard;
function mapStateToProps(state) {
  return {
    xyz: state.main,
    jobId: state.mainjobid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setjobId: bindActionCreators(setjobId, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateDashboard);
