import React from "react";
// import "../main.css";

// import logo from "../images/b_talenq.svg";
import facebook from "../images/facebook.svg";
import linkedin from "../images/linkedin.svg";
import twitter from "../images/twitter.svg";
// import footerLogo from "../../assets/img/footerLogo.png";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-top tq-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-7 mt-md-0 mt-sm-0 mt-4">
              {/* <img src={footerLogo} alt="TalenQLogo" className="img-fluid" /> */}
              <p className="footer-text my-3">
                TalenQ brings Employers and Hiring Partners together in order to
                identify and hire them.
              </p>
              <div className="footer__socialIcons mt-0">
                <a
                  href="https://www.facebook.com/talenqsoftware/?view_public_for=1480136818789389"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__socialIcon -mrf-8"
                >
                  <img src={facebook} className="w-160" alt="facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/talenq/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__socialIcon -mrf-8"
                >
                  <img src={linkedin} className="w-160" alt="linkedin" />
                </a>
                <a
                  href="https://twitter.com/talenq_com?s=08"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__socialIcon -mrf-8"
                >
                  <img src={twitter} className="w-160" alt="twitter" />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-5 col-6 mt-md-0 mt-sm-0 mt-4">
              <h3 className="footer-title">Resources</h3>
              <a href="/" className="footer-link">
                Home
              </a>
              {/* <a href="/" className="footer-link">
                Blog & Resources
              </a> */}
              <a href="/employers" className="footer-link">
                Employers
              </a>
              <a href="/hiring-partner" className="footer-link">
                Hiring Partners
              </a>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-4 col-6 mt-md-0 mt-4">
              <h3 className="footer-title">Company</h3>
              <a href="/contactus" className="footer-link">
                Contact Us
              </a>
              <a href="/about" className="footer-link">
                About Us
              </a>
              <a href="/" className="footer-link">
                Terms & Conditions
              </a>
              {/* <a href="" className="footer-link">
                Help?
              </a> */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8 mt-md-0 mt-4">
              <h3 className="footer-title">Who we're</h3>
              <p className="footer-text">
                TalenQ brings Employers and Hiring Partners together in order to
                identify and hire the right candidate in a timely manner at an
                affordable cost.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container text-center">
          <p className="text-white">
            © 2022 TalenQ Software India Pvt Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
