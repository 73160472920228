import React, { useState, useEffect } from "react";
import JobVsResume from "../../components/dashboardCharts/JobVsResume";
import schadule from "../../assets/images/schadule.svg";
import resumeImg from "../../assets/images/resumes.svg";
import DailyResumeGraph from "../../components/dashboardLayouts/DailyResumeGraph";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserName, setjobId } from "../../actions/index";
import { showLoader, showSnackBar } from "../../actions/index";
import axios from "axios";
import ClickIndicator from "../badge/indicator/ClickIndicator";
import { dEndPoints } from "../../config/end-point";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@material-ui/core/Tooltip";

// --------- Default bucket Card ---------------
const DefaultBucketDashboard = (props) => {
  return (
    <div className="status-card default__bucket">
      <div className="status-card__icon">
        <img src={props.icon} alt={props.icon} />
      </div>
      <div className="status-card__info">
        <h4 className="card__Count">{props.count}</h4>
        <span className="card__title">{props.title}</span>
      </div>
    </div>
  );
};
// --------- Quick Action Card ---------------
const QuickActionCard = (props) => {
  return (
    <div className={`card__two ${props.className}`}>
      <ClickIndicator />
      <span className="card__count-No"> {props.count}</span>
      <h3 className="card__title">{props.title}</h3>
    </div>
  );
};

const SuperAdminDashboard = (props) => {
  const [defaultBucket, setDefaultBucket] = useState([]);
  const [customBucket, setCustomBucket] = useState([]);
  //const [jobBucket, setJobBucket] = useState({});
  const [jobs, setJobs] = useState([]);
  // const [sort_createdon, setsort_createdon] = useState(-1);
  const [PendingBucket, setPendingBucket] = useState({});
  const [screenedBucket, setScreenedBucket] = useState({});
  const [immediateBucket, setImmediateBucket] = useState({});
  const [inProcessBucket, setInprocessBucket] = useState({});
  const [todayResume, setTodayResume] = useState({});
  const [plotting, setPlotting] = useState([]);
  const [graphPlotting, setGraphPlotting] = useState([]);
  const [todaySchedule, setTodaySchedule] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    //setTimeout(() => {
    reloadJobs();
    loadCompanyInfo();
    landingPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadJobs = (pageNum = 0) => {
    var sort_createdon = -1;
    //const corporateId = cacheData.ci;
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while loadig data v", error);
        });
    } else {
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      //setIsLoading(true);
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  const loadCompanyInfo = () => {
    const corporateId = localStorage.getItem("ci");
    //const corporateId = cacheData ? cacheData.ci : "";

    axios
      .get(dEndPoints.getCompanyInfo(corporateId))
      .then((res) => {
        let data = res.data;
        if (data && data.code === 100000) {
          setCompanyInfo(data.data && data.data.responseData);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };
  const userType = localStorage.getItem("ut");

  var landingPage = (pageNum = 0) => {
    //const corporateId = cacheData.ci;

    //  const corporateId = localStorage.getItem("ci");
    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    //setIsLoading(true);
    if (userType === "1000") {
      axios
        .get(dEndPoints.vendorLandingPage(userId), {})
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;

          if (data && data.code === 100185) {
            if (data.data.defaultBucket) {
              setDefaultBucket(data.data.defaultBucket);
            }

            if (data.corporateCompanies) {
              setCustomBucket(data.data.corporateCompanies);
            }
            setLoading(false);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    } else {
      axios
        .get(dEndPoints.corporateLandingPage(userId), {})
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100185) {
            if (data.data.defaultBucket) {
              setDefaultBucket(data.data.defaultBucket);
            }
            if (data.data.pendingResume) {
              setPendingBucket(data.data.pendingResume);
            }
            if (data.data.screenedResume) {
              setScreenedBucket(data.data.screenedResume);
            }
            if (data.data.todayResume) {
              setTodayResume(data.data.todayResume);
            }
            if (data.data.todaySchedule) {
              setTodaySchedule(data.data.todaySchedule);
            }
            if (data.data.plotGraph) {
              setPlotting(data.data.plotGraph);
            }
            if (data.data.dailyResumeFlow) {
              setGraphPlotting(data.data.dailyResumeFlow);
            }
            if (data.corporateCompanies) {
              setCustomBucket(data.data.corporateCompanies);
            }
            if (data.data.immediadte) {
              setImmediateBucket(data.data.immediadte);
            }
            if (data.data.inprocessResume) {
              setInprocessBucket(data.data.inprocessResume);
            }
            setLoading(false);
          }
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };
  const BucketCard = (props) => {
    return (
      <div className="bucket__card status-card">
        <i className={props.icon}></i>
        <div className="status-card__info">
          <h4>{props.title}</h4>
          {/* <span className={`bucket__status-${props.active}`}>{props.status}</span> */}
        </div>
      </div>
    );
  };
  return (
    <Container fluid className="carporate__dashboard-page">
      <div className="page__header-wrapp mb-4">
        <h2 className="page-header">Dashboard</h2>
      </div>
      <Row>
        {/* --------------------- corporate dashboard starts here ---------- */}
        {plotting.length > 0 ? (
          <Col sm={8} className="mb-4">
            <h3 className="dashboard__sub-heading mb-4">
              Total Resume Vs Total Position
            </h3>
            {loading === true ? (
              <Skeleton height={180} />
            ) : (
              <JobVsResume plotting={plotting} />
            )}
          </Col>
        ) : (
          ""
        )}
        <Col sm={4} className="mb-4 mt-5">
          <div className="uniq__card-wrapp">
            {/* ------- Today's Schadule card */}

            <div className="u__card-1 uniq__cards mb-4">
              <ClickIndicator />
              <span className="card__texture">
                {" "}
                <img src={schadule} alt="Schadule" />
              </span>

              <Link to={`/tabledetails?${todaySchedule.query}`}>
                <span className="card__count-No">
                  {" "}
                  {todaySchedule ? todaySchedule.count : ""}
                </span>
                <h3 className="card__title">
                  {todaySchedule ? todaySchedule.title : ""}
                </h3>
              </Link>
            </div>

            {/* ------- uploded profiles card */}

            <div className="u__card-2 uniq__cards">
              <ClickIndicator />
              <span className="card__texture">
                {" "}
                <img src={resumeImg} alt="Schadule" />
              </span>

              <Link to={`/tabledetails?${todayResume.query}`}>
                <span className="card__count-No ">
                  {" "}
                  {todayResume ? todayResume.count : ""}
                </span>
                <h3 className="card__title">
                  {todayResume ? todayResume.title : ""}
                </h3>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="my-5 ">
        <h3 className="dashboard__sub-heading mb-5 p-0">
          Attention Needed on Profiles
        </h3>
        <Col sm={3}>
          <Link to={`/tabledetails?${immediateBucket.query}`}>
            <QuickActionCard
              className="qui__card-2"
              count={immediateBucket ? immediateBucket.count : ""}
              title={immediateBucket ? immediateBucket.title : ""}
            />
          </Link>
        </Col>
        <Col sm={3}>
          <Link to={`/tabledetails?${PendingBucket.query}`}>
            <QuickActionCard
              className="qui__card-3"
              count={PendingBucket ? PendingBucket.count : ""}
              title={PendingBucket ? PendingBucket.title : ""}
            />
          </Link>
        </Col>
        <Col sm={3}>
          <Link to={`/tabledetails?${screenedBucket.query}`}>
            <QuickActionCard
              className="qui__card-1"
              count={screenedBucket ? screenedBucket.count : ""}
              title={screenedBucket ? screenedBucket.title : ""}
            />
          </Link>
        </Col>
        <Col sm={3}>
          <Link to={`/tabledetails?${inProcessBucket.query}`}>
            <QuickActionCard
              className="qui__card-4"
              count={inProcessBucket ? inProcessBucket.count : ""}
              title={inProcessBucket ? inProcessBucket.title : ""}
            />
          </Link>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12} className="mb-4">
          <h3 className="dashboard__sub-heading mb-5">Default Buckets</h3>
          <Row>
            {defaultBucket.map((item, index) => (
              <div className="col-3 mb-3" key={index}>
                <Link to={`/bucketdetails?${item.query}`}>
                  <DefaultBucketDashboard
                    icon={item.icon}
                    count={item.counts}
                    title={item.title}
                  />
                </Link>
              </div>
            ))}

            <Col xs={6} md={3} className="mb-4 position-relative">
              <Link to="/bucketSettings/create-bucket">
                <Tooltip title="Create Job Bucket">
                  <span className="info__button">
                    <i className="bx bx-info-circle"></i>
                  </span>
                </Tooltip>
                <BucketCard
                  icon="bx bx-add-to-queue"
                  title="Create Bucket"
                  // status="Active"
                  // active="true"
                />
              </Link>
            </Col>
          </Row>
        </Col>
        {graphPlotting.length > 0 ? (
          <Col sm={12}>
            <h3 className="dashboard__sub-heading mb-5">Daily Resume Flow</h3>
            {loading === true ? (
              <Skeleton height={180} />
            ) : (
              <DailyResumeGraph graphPlotting={graphPlotting} />
            )}
          </Col>
        ) : (
          ""
        )}
        {/* --------------------- corporate dashboard ends here ---------- */}
      </Row>
    </Container>
  );
};
function mapStateToProps(state) {
  return {
    xyz: state.main,
    jobId: state.mainjobid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setjobId: bindActionCreators(setjobId, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminDashboard);
