import React from "react";
import { useHistory } from "react-router-dom";

const GoBackButton = (props) => {
  let history = useHistory();
  // const location = useLocation();
  return (
    <div className="goback__wrapper">
      <span className="go__back" onClick={() => history.goBack()}>
        <i className={`bx bx-arrow-back ${props.classNameMargin}`}></i>
      </span>
      {props.pageHeading ? (
        <div className="tq__page-header">
          <p className="page-header">{props.Page_Heading}</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default GoBackButton;
