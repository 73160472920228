import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import schadule from "../../assets/images/schadule.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import JobVsResume from "../../components/dashboardCharts/JobVsResume";
// import resumeImg from "../../assets/images/resumes.svg";
// import NewUsersHome from "../../pages/NewUsersHome";
// import statusCards from "../../assets/jsonDatas/statusCardData.json";
// import jobData from "../../assets/jsonDatas/findJobs.json";
// import TableViewPage from "./TableViewPage";
import "../../assets/css/dashboard.css";
import CircularRSCounter from "../badge/CircularRSCounter";
import Badge from "../badge/Badge";
import FindJobCard from "../jobcards/FindJobCard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserName, setjobId } from "../../actions/index";
import { showLoader, showSnackBar } from "../../actions/index";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import JodTable from "../jobcards/JodTable";
import ClickIndicator from "../badge/indicator/ClickIndicator";
import StackedGraph from "../dashboardCharts/StackedGraph";
import Skeleton from "react-loading-skeleton";
import Tooltip from "@material-ui/core/Tooltip";

// --------- Default bucket Card ---------------
const DefaultBucketDashboard = (props) => {
  return (
    <div className="status-card default__bucket">
      <div className="status-card__icon">
        <img src={props.icon} alt={props.icon} />
      </div>
      <div className="status-card__info">
        <h4 className="card__Count">{props.count}</h4>
        <span className="card__title">{props.title}</span>
      </div>
    </div>
  );
};

const CustomeBucketDashboard = (props) => {
  return (
    <div className="status-card default__bucket">
      <div className="status-card__info">
        <img src={props.icon} alt={props.icon} />
        <h4 className="card__Count">{props.count}</h4>
        <span className="card__title">{props.title}</span>
      </div>
    </div>
  );
};
// --------- Quick Action Card ---------------
const QuickActionCard = (props) => {
  return (
    <div className={`card__two ${props.className}`}>
      <span className="card__count-No"> {props.count}</span>
      <h3 className="card__title">{props.title}</h3>
    </div>
  );
};

const VendorDashboard = (props) => {
  const [defaultBucket, setDefaultBucket] = useState([]);
  const [customBucket, setCustomBucket] = useState([]);
  const [rejectedBucket, setRejectBucket] = useState({});
  const [screenedBucket, setScreenedBucket] = useState({});
  const [pendingBucket, setPendingBucket] = useState({});
  const [inProcessBucket, setInProcessBucket] = useState({});
  const [todayResume, setTodayResume] = useState(null);
  const [plotting, setPlotting] = useState([]);
  const [slotPlotting, setSlotPlotting] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [linkedJobs, setLinkedJobs] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [jobCount, setJobCount] = useState(0);
  const [graphPlotting, setGraphPlotting] = useState([]);
  const [jobOfTheDay, getJobOfTheDay] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [followUp, setFollowUp] = useState({});
  const [scheduleCount, setScheduleCount] = useState({});
  const [noShowCount, setNoShowCount] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadCompanyInfo();
    landingPage();
    handleSearchJob("", pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCompanyInfo = () => {
    const vendorCorporateId = localStorage.getItem("vci");
    axios
      .get(dEndPoints.getVendorCompanyInfo(vendorCorporateId))
      .then((res) => {
        let data = res.data;
        if (data && data.code === 100000) {
          setCompanyInfo(data.data && data.data.responseData);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };
  const handleSearchJob = (serchInput, pageNum) => {
    const vendorCorporateId = localStorage.getItem("vci");
    const data = {
      keywords: serchInput,
    };
    props.showLoader({ show: true });
    axios
      .post(dEndPoints.findRequirement(vendorCorporateId), data, {
        params: {
          limit: 30,
          pageNumber: pageNum,
        },
      })
      .then((res) => {
        props.showLoader({ show: false });
        if (res.data.data.jobs) {
          setJobData(res.data.data.jobs);
          setLinkedJobs(res.data.data.linkedJob);
          setJobCount(Math.floor(data.jobCount / 12) + 1);
        }
      })
      .catch((error) => {
        props.showLoader({ show: false });
        console.log("error", error);
      });
  };

  var landingPage = (pageNum = 0) => {
    //const corporateId = cacheData.ci;

    const userId = localStorage.getItem("i");
    props.showLoader({ show: true });
    //setIsLoading(true);

    axios
      .get(dEndPoints.vendorLandingPage(userId), {
        // params: {
        //   getFacets: true,
        //   limit: 18,
        //   pageNumber: pageNum,
        //   cOn: sort_createdon,
        // },
      })
      .then((response) => {
        props.showLoader({ show: false });
        var data = response.data;

        if (data && data.code === 100185) {
          if (data.data.defaultBucket) {
            setDefaultBucket(data.data.defaultBucket);
          }
          if (data.data.jobOfTheDay) {
            getJobOfTheDay(data.data.jobOfTheDay);
          }
          if (data.data.ScreenedRatio) {
            setRejectBucket(data.data.ScreenedRatio.totalRejected);
          }
          if (data.data.ScreenedRatio) {
            setPendingBucket(data.data.ScreenedRatio.totalPending);
          }
          if (data.data.ScreenedRatio) {
            setInProcessBucket(data.data.ScreenedRatio.totalInprocess);
          }
          if (data.data.ScreenedRatio) {
            setScreenedBucket(data.data.ScreenedRatio.totalScreened);
          }
          if (data.data.totalResumeUpload) {
            setTodayResume(data.data.totalResumeUpload);
          }
          if (data.data.rejectionGraph.getRejectGraphData) {
            setGraphPlotting(data.data.rejectionGraph.getRejectGraphData);
          }
          if (data.data.customBucket) {
            setCustomBucket(data.data.customBucket);
          }
          if (data.data.joinedGraph.getOfferedGraphData) {
            setPlotting(data.data.joinedGraph.getOfferedGraphData);
          }
          if (data.data.getFollowUpCount) {
            setFollowUp(data.data.getFollowUpCount);
          }
          if (data.data.getScheduleCount) {
            setScheduleCount(data.data.getScheduleCount);
          }
          if (data.data.getNoShowCount) {
            setNoShowCount(data.data.getNoShowCount);
          }
          if (data.data.joinedGraph.getJoinedGraphData) {
            setSlotPlotting(data.data.joinedGraph.getJoinedGraphData);
          }
          setLoading(false);
          // if (data.data.stats) {
          //   setJobStatus(data.data.stats);
          // }
          // setJobCount(Math.ceil(data.data.total / 12));
        }
      })
      .catch(function (error) {
        props.showLoader({ show: false });
        console.log(error);
      });
  };
  var linkjobIds = linkedJobs.map((job) => {
    return job.jobId;
  });
  const BucketCard = (props) => {
    return (
      <div className="bucket__card status-card">
        <i className={props.icon}></i>
        <div className="status-card__info">
          <h4>{props.title}</h4>
          {/* <span className={`bucket__status-${props.active}`}>{props.status}</span> */}
        </div>
      </div>
    );
  };
  var finaJobs = [];
  jobData &&
    jobData.map((item, index) => {
      var newJob = linkjobIds.indexOf(item._id) > -1 ? true : false;

      if (newJob === false) {
        finaJobs.push(item);
      }
    });
  //console.log("finaJobs", finaJobs);

  const itemsJob = finaJobs ? finaJobs.slice(0, 3) : [];
  return (
    <div className="carporate__dashboard-page">
      <div className="page__header-wrapp mb-4">
        <h2 className="page-header">Dashboard</h2>
        {/* <small className="profile__type">{companyInfo.companyName}</small> */}
      </div>
      <Row>
        {/* --------------------- corporate dashboard starts here ---------- */}
        <Col sm={7} className="mb-4">
          <div className="tq__page-header mb-3">
            {" "}
            <h2 className="dashboard__sub-heading mb-3">Job of The Day</h2>
            {/* <div className="filter__wrapper filter__btn flex__center border-0 bg-white">
              See All
            </div> */}
          </div>
          {loading === true ? (
            <Skeleton height={180} />
          ) : (
            <JodTable jobOfTheDay={jobOfTheDay} />
          )}
          {/* <JobVsResume plotting={plotting} slotPlotting={slotPlotting} /> */}
        </Col>
        <Col sm={5} className="mb-4 mt-5">
          <>
            {loading === true ? (
              <Skeleton height={80} />
            ) : (
              <>
                <div className="uniq__card-wrapp">
                  {/* ------- Today's Schadule card */}
                  <div className="u__card-1 uniq__cards mb-4">
                    <ClickIndicator />
                    <span className="card__texture">
                      {" "}
                      <img src={schadule} alt="Schadule" />
                    </span>
                    <Link to={`/tabledetails?${followUp.query}`}>
                      <span className="card__count-No">
                        {followUp ? followUp.count : ""}
                      </span>
                      <h3 className="card__title">Follow Up Reminders</h3>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </>
          <>
            {loading === true ? (
              <Skeleton height={80} />
            ) : (
              <>
                {/* ------- Today's Schadule card */}
                <div className="u__card-2 uniq__cards">
                  <ClickIndicator />
                  <span className="card__texture">
                    {" "}
                    <img src={schadule} alt="Schadule" />
                  </span>
                  <Link to={`/tabledetails?${scheduleCount.query}`}>
                    <span className="card__count-No">
                      {scheduleCount ? scheduleCount.count : ""}
                    </span>
                    <h3 className="card__title">Upcoming Interviews</h3>
                  </Link>
                </div>
              </>
            )}
          </>
        </Col>
        <Col sm={12}>
          <div className="tq__page-header mb-0">
            {" "}
            <h2 className="dashboard__sub-heading">Latest Requirements</h2>
            <div className="filter__wrapper filter__btn flex__center border-0 bg-white">
              <Link to="/find-jobs">See All</Link>
            </div>
          </div>
          <Row>
            {itemsJob &&
              itemsJob.map((item, index) => (
                <Col md={4} className="mt-5" key={index}>
                  <FindJobCard
                    _id={item._id}
                    badge={<Badge type={item.status} content={item.status} />}
                    ChartDatas={
                      <CircularRSCounter
                        id={item._id}
                        //id={12}
                        resumeStatus={item.jobFillStatus}
                        //resumeStatus={20}
                      />
                    }
                    jobLink={item._id}
                    jobOfTheDay={item.jobOfTheDay}
                    rate={item.vRate}
                    notice={item.nPeriod}
                    jodstatus={item.jodstatus}
                    jobType={item.jobType}
                    reqId={item.reqId}
                    Heading={item.desig}
                    posted={new Date(item.cOn).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                    company={item.cName}
                    skills={item.pSkills.toString()}
                    location={item.city[0]}
                    enrolled={linkjobIds.indexOf(item._id) > -1 ? true : false}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <Row className="my-5 ">
        <Col sm={12}>
          <h3 className="dashboard__sub-heading mb-5 p-0">Profile Summary</h3>
        </Col>
        <Col sm={3} className="mb-4">
          <Link to={`/tabledetails?${noShowCount.query}`}>
            <QuickActionCard
              className="qui__card-4"
              count={noShowCount.count}
              title="No Show Candidates"
            />
          </Link>
        </Col>
        <Col sm={3} className="mb-4">
          <Link to={`/tabledetails?${pendingBucket.query}`}>
            <QuickActionCard
              className="qui__card-1"
              count={pendingBucket.count}
              title="All Pending Candidates"
            />
          </Link>
        </Col>
        <Col sm={3} className="mb-4">
          <Link to={`/tabledetails?${screenedBucket.query}`}>
            <QuickActionCard
              className="qui__card-2"
              count={screenedBucket.count}
              title="All Screened Candidates"
            />
          </Link>
        </Col>
        <Col sm={3} className="mb-4">
          <Link to={`/tabledetails?${inProcessBucket.query}`}>
            <QuickActionCard
              className="qui__card-3"
              count={inProcessBucket.count}
              title="All InProcess Candidates"
            />
          </Link>
        </Col>
        <Col sm={3} className="mb-4">
          <Link to={`/tabledetails?${rejectedBucket.query}`}>
            <QuickActionCard
              className="qui__card-5"
              count={rejectedBucket.count}
              title="All Rejected Candidates"
            />
          </Link>
        </Col>

        <Col sm={3} className="mb-4">
          <Link to={"/tabledetails?allResume"}>
            <QuickActionCard
              className="qui__card-6"
              count={todayResume}
              title="Total Resumes"
            />
          </Link>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12} className="mb-4">
          <h3 className="dashboard__sub-heading mb-4">Job Summary</h3>
          <Row>
            {defaultBucket.map((item, index) => (
              <div className="col-sm-3 col-6 mb-3" key={index}>
                <Link to={`/bucketdetails?${item.query}`}>
                  <DefaultBucketDashboard
                    count={item.jobCounts}
                    title={item.title}
                    icon={item.icon}
                  />
                </Link>
              </div>
            ))}
          </Row>
        </Col>
        <Col sm={12} className="mb-4">
          <h3 className="dashboard__sub-heading mb-5">
            Customize Your Job Bucket
          </h3>
          <Row>
            <Col xs={6} md={3} className="mb-4 position-relative">
              <Link to="/bucketSettings/create-bucket">
                <Tooltip title="Create Job Bucket">
                  <span className="info__button">
                    <i className="bx bx-info-circle"></i>
                  </span>
                </Tooltip>
                <BucketCard icon="bx bx-add-to-queue" title="Create Bucket" />
              </Link>
            </Col>
            {customBucket.map((item, index) => (
              <div className="col-3 mb-3" key={index}>
                <Link to={`/custombucketdetails?${item.query}`}>
                  <CustomeBucketDashboard
                    icon={item.icon}
                    count={item.counts}
                    title={item.title}
                  />
                </Link>
              </div>
            ))}
          </Row>
        </Col>
        {graphPlotting.length > 0 ? (
          <Col sm={12}>
            <h3 className="dashboard__sub-heading mb-4">
              Overview of Candidate Status across Requirements
            </h3>
            {loading === true ? (
              <Skeleton height={180} />
            ) : (
              <StackedGraph graphPlotting={graphPlotting} />
            )}
          </Col>
        ) : (
          ""
        )}
        {/* --------------------- corporate dashboard ends here ---------- */}
      </Row>
      {/* <TableViewPage /> */}
    </div>
  );
};
//export default CorporateDashboard;
function mapStateToProps(state) {
  return {
    xyz: state.main,
    jobId: state.mainjobid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setjobId: bindActionCreators(setjobId, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDashboard);
