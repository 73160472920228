import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import "./loginsignup.css";
import Header from "../../home/common/Header";
import Footer from "../../home/common/Footer";
import { showSnackBar } from "..//../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle";
import { withStyles } from "@material-ui/core/styles";
import endPoints from "../../config/end-point";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
//css
const useStyles = makeStyles({
  root: {
    width: "70%",
    margin: "2rem auto",
    padding: "50px 10px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "22px",
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#f7d000",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#00b013",
    },
  },
  formWrap: {
    width: "60%",
    margin: "2rem auto",
    position: "relative",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    borderRadius: "22px",
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#f7d000",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#00b013",
    },
  },
});

const ResetPassword = (props) => {
  const classes = useStyles();

  const TokenStatus = {
    Validating: "Validating",
    Valid: "Valid",
    Invalid: "Invalid",
  };

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { passwordResetCode, emailId } = useParams();
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);
  useEffect(() => {
    const Tokendata = {
      passwordResetCode: passwordResetCode,
      emailId: emailId,
    };

    axios
      .post(endPoints.validateToken, Tokendata)
      .then((res) => {
        setTokenStatus(TokenStatus.Valid);
        var data = res.data;

        if (data.code === 100131) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
        }
      })
      .catch(() => {
        setTokenStatus(TokenStatus.Invalid);
      });
  }, [window.location]);
  function getForm() {
    // const initialvalues = {
    //   emailId: "",
    //   password: "",
    //   passwordResetCode: "",
    // };

    const updatePass = () => {
      fetch(endPoints.updatePassword, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId,
          password,
          passwordResetCode,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 100059) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            props.history.push("/login-signup");
            return;
          }

          if (data.code === 100060) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
          }
        })

        .catch((error) => {
          console.log("error in data Fetching", error);
        });
    };

    return (
      <div className="personal-details">
        <Header />

        <div className={classes.formWrap}>
          <img
            src="https://talenq.r.worldssl.net/talenq/img/login/form-texture.png"
            alt="login-texture"
            className="img-fluid login-texture"
          />
          <div className="tq-form-container text-center">
            <Typography variant="h3" className="tq-form-heading">
              Reset Password
            </Typography>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    variant="outlined"
                    placeholder="Email"
                    value={emailId}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="New Password"
                    variant="outlined"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Re-enter Password"
                    variant="outlined"
                    placeholder="Re-Enter Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button className="tq-button" onClick={updatePass}>
                Reset Password
              </Button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  function getBody() {
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return getForm();
      case TokenStatus.Invalid:
        return (
          <div>
            Token validation failed, if the token has expired you can get a new
            one at the <Link to="/forgotpassword">forgot password</Link> page.
          </div>
        );
      case TokenStatus.Validating:
        return <div>Validating token...</div>;
      default:
        return;
    }
  }
  return (
    <div>
      <div className="card-body">{getBody()}</div>
    </div>
  );
};
ResetPassword.propTypes = {
  classes: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(ResetPassword));
