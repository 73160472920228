import { Tooltip } from "@material-ui/core";

const CircularRSCounter = (props) => {
  var strokeWidth = 20;
  var percentage = 0;
  const resumeStatus = props.resumeStatus;

  if (resumeStatus === 10) {
    percentage = 100;
  } else if (resumeStatus === 5) {
    percentage = 50;
  } else if (resumeStatus === 2) {
    percentage = 25;
  } else if (resumeStatus === 1) {
    percentage = 10;
  } else {
    percentage = 0;
  }
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: percentage < 31 ? "green" : percentage < 71 ? "#f3ca00" : "#d91400",
    strokeLinecap: "round",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };
  //   console.log("props value", props.jobFillStatus);
  return (
    <Tooltip
      title={
        percentage < 10
          ? "Job has only got less then 10% resumes so far, Expect faster response for the profiles you upload."
          : percentage <= 10
          ? "Job has only got 10% resumes so far, Expect faster response for the profiles you upload."
          : percentage <= 30
          ? "Job has only got 30% resumes so far, Expect faster response for the profiles you upload."
          : percentage <= 70
          ? "Job has got 70% resumes so far, Expect slower response for the profiles you upload."
          : percentage <= 100
          ? "Job has many pending profiles, Expect slower response for the profiles you upload."
          : ""
      }
    >
      <svg
        className={"CircularProgressbar"}
        viewBox="0 0 100 100"
        width={100}
        height={100}
      >
        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={{
            stroke: "#d6d6d6",
          }}
        />

        <path
          className="CircularProgressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />

        <text
          className="CircularProgressbar-text"
          x={50}
          y={50}
          style={{
            fill:
              percentage < 31
                ? "green"
                : percentage < 71
                ? "#f3ca00"
                : percentage <= 100
                ? "#d91400"
                : "",
            fontSize: percentage === 31 ? "20px" : "24px",
            fontWeight: "600",
            dominantBaseline: "central",
            textAnchor: "middle",
          }}
        >
          {`${percentage}%`}
        </text>
      </svg>
    </Tooltip>
  );
};

export default CircularRSCounter;
