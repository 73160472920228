import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import Container from "react-bootstrap/Container";
// import "../main.css";
// import "../tqui.css";

const Header = () => {
  return (
    <header className="bg-white tqHeader" style={{ padding: "5px 0" }}>
      <Navbar className="bg-white tqHeader" collapseOnSelect expand="lg">
        <div className="container">
          <Navbar.Brand href="/">
            <img
              src="https://talenq.r.worldssl.net/TalenQ/logomain.svg"
              alt="talenq logo"
              style={{ width: "121px", height: "45px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              {/* <Nav.Link href="#features">Features</Nav.Link> */}
            </Nav>
            <Nav>
              <Nav.Link href="/employers">Employers</Nav.Link>
              <Nav.Link href="/hiring-partner">Hiring Partners</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contactus">Contact Us</Nav.Link>
              {/* <Nav.Link href="/contactus">Blogs & Resources</Nav.Link> */}
              <div className="login_control">
                <a href="/login-signup" className="btn tqBtn nav-link">
                  Login / Signup
                </a>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};
export default Header;
