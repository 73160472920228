import React from "react";
// import "../main.css";
// import "../tqui.css";

const Ctatwo = (ctaTwo) => {
  return (
    <section className="footer-cta-section tq-section-padding">
      <div className="container">
        <div className="tq-ctaTwo">
          <span className="cta-title">
            {ctaTwo.Line1}
            {/* Struggling to close your hiring requirements? */}
            <p className="cta-text">
              {ctaTwo.Line2}
              {/* We add value to your recruitment process and deliver best talents
              in time. */}
            </p>
          </span>
          <a href={ctaTwo.BtnLink} className="btn-tq tqBtn m-0">
            {ctaTwo.ButtonText}
            {/* Try TalenQ */}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Ctatwo;
