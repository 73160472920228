import React from "react";
import "../tqui.css";

const TqBenifitCard = (tQcard) => {
  return (
    <div className="tq-card">
      <img
        src={tQcard.ThumbImg}
        alt={tQcard.ImgAlt}
        className="img-fluid tq-card-thumb"
      />
      <h3 className="card-title">{tQcard.CardTitle}</h3>
      <p className="card-desc">{tQcard.CardPara}</p>
    </div>
  );
};
export default TqBenifitCard;
