let INITIAL_STATE = {
  open: false,
  message: "",
  type: "success",
  vertical: "bottom",
  horizontal: "left"
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOGGLE_SNACKBAR":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
