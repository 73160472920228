import React from "react";
import { Link } from "react-router-dom";

const RegHeader = () => {
  return (
    <header className="tqHeader">
      <nav className="navbar container navbar-expand-lg navbar-light">
        {/* <a className="navbar-brand" href="#">
      TalenQ
    </a> */}
        <Link className="navbar-brand" to="/">
          <img
            src="https://talenq.r.worldssl.net/TalenQ/logomain.svg"
            alt="talenq logo"
            style={{ width: "121px", height: "45px" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-space"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a className="nav-link" href="/services">
                Employers
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/services">
                Hiring Partners
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/about">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contactus">
                Contact Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contactus">
                Blog/Resources
              </a>
            </li> */}
          </ul>
          <div className="login_control">
            <a className="tq-theme text-white px-5 nav-link" href="/contactus">
              Contact Us
            </a>
            {/* <a href="/login-signup" className="btn tqBtn nav-link">
              Login / Signup
            </a> */}
          </div>
        </div>
      </nav>
    </header>
  );
};
export default RegHeader;
