import React, { useState, useEffect } from "react";
import "../assets/css/dashboard.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserName, setjobId } from "../actions/index";
import { showLoader, showSnackBar } from "../actions/index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import axios from "axios";
//import IdleTimer from "react-idle-timer";
import TopNav from "../components/topnav/TopNav";
import SideNav from "../components/sidenav/SideNav";
import { dEndPoints } from "../config/end-point";
import CorporateDashboard from "../components/dashboardLayouts/CorporateDashboard";
import VendorDashboard from "../components/dashboardLayouts/VendorDashboard";
import NewUsersHome from "./NewUsersHome";
import SuperAdminDashboard from "../components/dashboardLayouts/SuperAdminDashboard";
import Skeleton from "react-loading-skeleton";

const Dashboard = (props) => {
  const userType = localStorage.getItem("ut");
  const [open, setopen] = useState(true);
  const [jobs, setJobs] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reloadJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadJobs = (pageNum = 0) => {
    //const corporateId = cacheData.ci;
    var sort_createdon = -1;
    if (userType === "1000") {
      const vendorCorporateId = localStorage.getItem("vci");
      props.showLoader({ show: true });
      axios
        .get(dEndPoints.vendorDashboard(vendorCorporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((res) => {
          props.showLoader({ show: false });
          var data = res.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          console.log("error while loadig data v", error);
        });
    } else {
      const corporateId = localStorage.getItem("ci");
      props.showLoader({ show: true });
      setLoading(true);
      axios
        .get(dEndPoints.corporateDashboard(corporateId), {
          params: {
            getFacets: true,
            limit: 18,
            pageNumber: pageNum,
            cOn: sort_createdon,
          },
        })
        .then((response) => {
          props.showLoader({ show: false });
          var data = response.data;
          if (data && data.code === 100000) {
            if (data.data.jobs) {
              setJobs(data.data.jobs);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          props.showLoader({ show: false });
          console.log(error);
        });
    }
  };

  return (
    <Container fluid>
      <div className="tq-defaultDashboard">
        <Row>
          <Col
            md={2}
            className="position-relative"
            id={`${open ? "working" : ""}`}
          >
            <SideNav {...props} />
            <div className="toggle__button-wrap">
              <Button onClick={() => setopen(!open)}>Ok</Button>
            </div>
          </Col>
          <Col md={10} bg="talenQ">
            <TopNav />

            {userType === "1100" ? (
              <>
                {jobs.length === 0 ? (
                  loading === true ? (
                    <Skeleton height={480} />
                  ) : (
                    <NewUsersHome />
                  )
                ) : (
                  <div className="carporates__dashboard">
                    <CorporateDashboard />
                  </div>
                )}
              </>
            ) : userType === "1000" ? (
              <div className="vendor__dashboard">
                <VendorDashboard />
              </div>
            ) : userType === "1110" ? (
              <div className="carporates__dashboard">
                <SuperAdminDashboard />
              </div>
            ) : (
              ""
            )}
            {/* </AppLogout> */}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    xyz: state.main,
    jobId: state.mainjobid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserName: bindActionCreators(setUserName, dispatch),
    setjobId: bindActionCreators(setjobId, dispatch),
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
//export default Dashboard;
