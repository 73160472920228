let INITIAL_STATE = {
  show: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
