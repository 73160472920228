import React, { useState } from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import UserType from "./UserType";
import PersonalDetails from "./PersonalDetails";
import OtpVerification from "./OtpVerification";
import BusinessDetails from "./BusinessDetails";
import Footer from "../../home/common/Footer";
import "./loginsignup.css";
import "../../home/home/tqResponsive.css";
import RegHeader from "./RegHeader";

const Registration = (props) => {
  const [signupUser, setSignupUser] = useState({
    firstName: "",
    lastName: "",
    contact: props.history.location.state.userData.contact,
    emailId: props.history.location.state.userData.username,
    password: "",
    confirmPassword: "",
    userAccess: "",
    userType: "",
  });

  const handleInputChange = (e) => {
    const info = { ...signupUser };

    info[e.target.id] = e.target.value;
    setSignupUser(info);
  };

  const [userType, setUserType] = useState("");

  const handleChange = (event) => {
    setUserType(event.target.value);
  };
  let stepCount;

  if (props.history.location.state.userData.status === "Initiated") {
    stepCount = 2; // go to case 2, OTP verification
  } else if (props.history.location.state.userData.status === "Verified") {
    stepCount = 3; // go to case 3, business details
  } else {
    stepCount = 0;
  }

  const [activeStep, setActiveStep] = useState(stepCount);

  function getSteps() {
    return [
      "USER TYPE",
      "PERSONAL DETAILS",
      "OTP VERIFICATION",
      "BUSINESS DETAILS",
    ];
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = getSteps();
  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <UserType
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
            userType={userType}
            handleChange={handleChange}
            signupUser={signupUser ? signupUser : ""}
          />
        );
      case 1:
        return (
          <PersonalDetails
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
            signupUser={signupUser ? signupUser : ""}
            handleInputChange={handleInputChange}
            userType={userType}
          />
        );
      case 2:
        return (
          <OtpVerification
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
            signupUser={signupUser ? signupUser : ""}
          />
        );
      case 3:
        return (
          <BusinessDetails
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
            signupUser={signupUser ? signupUser : ""}
            userType={userType}
          />
        );
      default:
        return <span hidden>unknown step</span>;
    }
  }
  return (
    <div className="tqlogModal">
      <RegHeader />
      <Stepper className="steperHead" alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="register-fromWraper">
        <img
          src="https://talenq.r.worldssl.net/talenq/img/login/form-texture.png"
          alt="login-texture"
          className="img-fluid login-texture"
        />
        {getStepsContent(activeStep)}
      </div>
      <Footer />
    </div>
  );
};

export default Registration;
