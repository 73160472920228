import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TqBenifitCard from "./TqBenifitCard";
import CtaOne from "../common/CtaOne";
import AchivementsCount from "./AchivementsCount";

const HiringPartners = () => {
  return (
    <div className="hiringPart-page">
      <Header />
      <section className="hiring-banner tq-section-padding">
        <Container>
          <p className="hero-small">Hiring Partners </p>
          <h1 className="hero-title">
            Expand Your Business <br />& Grow More
          </h1>
        </Container>
      </section>
      <AchivementsCount
        setClassName="hiringPartners-values"
        Counts1="20+"
        CountTitle1="Corporates"
        Counts2="100+"
        CountTitle2="Active Jobs"
        Counts3="50k+"
        CountTitle3="Avg Invoice Amount"
        Counts4="5L+"
        CountTitle4="Max Invoice Amount"
      />
      <section className="services-section tq-section-padding">
        <Container>
          <div className="tq-heading-wrapper mb-5">
            <p className="top-heading">Hiring Partner's </p>
            <h2 className="section-heading">Key Benefits</h2>
          </div>
          <Row>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/hiringPartner/expandBuss.png"
                ImgAlt="Expand your business "
                CardTitle="Expand your business with no sales efforts"
                CardPara="Connect with reputed employers via TalenQ with access to diverse Industries across India."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/hiringPartner/contiFlow.png"
                ImgAlt="Continuous Flow"
                CardTitle="Continuous flow of requirements"
                CardPara="More Requirements, just work dedicatedly with quality candidates."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/singlePoint.png"
                ImgAlt="Single-point-contact"
                CardTitle="Single point of contact"
                CardPara="Easy to engage and communicate with all employers via TalenQ."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/realtime.png"
                ImgAlt="real-time-insights"
                CardTitle="Real time insights on candidate status"
                CardPara="Access anywhere, anytime with real time updates on your candidates."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/hiringPartner/transparentCom.png"
                ImgAlt="Transparent-Commercials"
                CardTitle="Transparent commercials"
                CardPara="Your trust is our priority, commercials for each requirement are mentioned on the portal."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/hiringPartner/revGrow.png"
                ImgAlt="more-revenue"
                CardTitle="More revenue"
                CardPara="Work with employers across various sectors via TalenQ and earn more."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/hiringPartner/smartDb.png"
                ImgAlt="Smart DB"
                CardTitle="Smart utilization of database"
                CardPara="Use Your existing candidates with the right skill sets on the requirements posted on TalenQ."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/hiringPartner/free.png"
                ImgAlt="free-register"
                CardTitle="Free registration"
                CardPara="No upfront fees, simple contract. "
              />
            </Col>
          </Row>
        </Container>
      </section>
      <CtaOne
        Text="Are you a Hiring Agency ? Join us as a Hiring Partner and Generate More Revenue"
        ButtonText="Register Free"
        BtnLink="/login-signup"
      />
      <section className="talenq-flow bg-f9 tq-section-padding">
        <Container>
          <div className="row y-middle">
            <div className="col-lg-12">
              <div className="tq-heading-wrapper mb-5">
                <h2 className="section-heading text-left">
                  Our Working Process - How We Work For Our Hiring Partners
                </h2>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="process-effects-layer">
            <div className="row">
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/register.png"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 1 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title"> Register with TalenQ</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/connectReput.png"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 2 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">
                          Connect with reputed organizations
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/shareProfile.png"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 3 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Share relevant profiles</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 ">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/earnMoreRev.png"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 4 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Get closures and earn more</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
export default HiringPartners;
