import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import "./loginsignup.css";
import Header from "../../home/common/Header";
import Footer from "../../home/common/Footer";
import { showSnackBar } from "..//../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle";
import { withStyles } from "@material-ui/core/styles";
import endPoints from "../../config/end-point";
import { useParams } from "react-router-dom";
import axios from "axios";
const useStyles = makeStyles({
  root: {
    width: "70%",
    margin: "2rem auto",
    padding: "50px 10px",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "22px",
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#f7d000",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#00b013",
    },
  },
  formWrap: {
    width: "60%",
    margin: "2rem auto",
    position: "relative",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    borderRadius: "22px",
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#f7d000",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#00b013",
    },
  },
});

const Unsubscribe = (props) => {
  const classes = useStyles();

  const TokenStatus = {
    Validating: "Validating",
    Valid: "Valid",
    Invalid: "Invalid",
  };
  const { userId, emailId } = useParams();
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);
  useEffect(() => {
    const Tokendata = {
      userId: userId,
      emailId: emailId,
    };

    axios
      .post(endPoints.validateUnsubscribe, Tokendata)
      .then((res) => {
        setTokenStatus(TokenStatus.Valid);
        var data = res.data;

        if (data.code === 100131) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
        }
      })
      .catch(() => {
        setTokenStatus(TokenStatus.Invalid);
      });
  }, [window.location]);

  const notification = { emailId, userId, email: false };
  function getForm() {
    const updatePass = () => {
      axios
        .patch(endPoints.Unsubscribe, notification)
        .then((response) => {
          var data = response.data;
          if (data && data.code === 100000) {
            props.showSnackBar({
              message: data.data.message,
              open: true,
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            props.history.push("/login-signup");
            return;
          }

          if (data.code === 100060) {
            props.showSnackBar({
              message: data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
          }
        })

        .catch((error) => {
          console.log("error in data Fetching", error);
        });
    };

    return (
      <div className="personal-details">
        <Header />

        <div className={classes.formWrap}>
          <img
            src="https://talenq.r.worldssl.net/talenq/img/login/form-texture.png"
            alt="login-texture"
            className="img-fluid login-texture"
          />
          <div className="tq-form-container text-center">
            <h3 className="tq-form-heading">Unsubscribe ?</h3>
            <form>
              <p>
                Please confirm that you would like to unsubscribe your email
                address, {emailId}, from future emails from TalenQ.
              </p>
              <Button className="tq-button" onClick={updatePass}>
                Confirm
              </Button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  function getBody() {
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return getForm();
      case TokenStatus.Invalid:
        return "";
      case TokenStatus.Validating:
        return <div>Validating token...</div>;
      default:
        return;
    }
  }
  return (
    <div>
      <div className="card-body">{getBody()}</div>
    </div>
  );
};
Unsubscribe.propTypes = {
  classes: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(Unsubscribe));
