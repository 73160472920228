import React, { useState } from "react";
import "./loginsignup.css";
import Header from "../../home/common/Header";
import Footer from "../../home/common/Footer";
import axios from "axios";
import endPoints from "../../config/end-point";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

const ForgotPassword = (props) => {
  const userId = props.location.state.userId;

  const [showHidelogin, setShowHidelogin] = useState(false);
  const [username, setUsername] = useState(userId);

  const showlogin = () => {
    setShowHidelogin(true);
    props.history.push("/login-signup");
  };

  const forgotPasswod = () => {
    axios
      .get(endPoints.forgetPassword, {
        params: {
          emailId: username,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.code === 100058) {
          setUsername("");
          props.history.push("/login-signup");
          props.showSnackBar({
            message: "Reset Link has been shared successfully to your Email ID",
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        console.log("error in data Fetching", error);
      });
  };

  return (
    <div className="personal-details">
      <Header />

      <div className="forgetCard">
        <img
          src="https://talenq.r.worldssl.net/talenq/img/login/form-texture.png"
          alt="login-texture"
          className="img-fluid login-texture"
        />
        <div className="tq-form-container text-center">
          <h3 className="tq-form-heading">Forgot Password</h3>
          <form className="tq-form">
            <Row className="text-left">
              <Form.Group as={Col} xs={12} sm={12}>
                <p className="mb-4 w-75 text-center m-auto">
                  TalenQ will send a reset link to your email -
                  <strong>{username}</strong> Please click on the below button.
                </p>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Button className="tq-button" onClick={forgotPasswod}>
              Send Link
            </Button>
          </form>

          <Row>
            <Form.Group as={Col} className="text-center" item xs={12} sm={12}>
              <p>
                Already have password? &nbsp;
                <span onClick={showlogin} className="text-button-tq">
                  Login
                </span>
              </p>
            </Form.Group>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(ForgotPassword);
