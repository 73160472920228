import React, { useState, useEffect } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { dEndPoints } from "../config/end-point";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoader } from "../actions/index";
import { showSnackBar } from "../actions/index";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
// import { useHistory } from "react-router-dom";
import TopNav from "../components/topnav/TopNav";
import axios from "axios";
import SideNav from "../components/sidenav/SideNav";
import Select from "react-select";
import Popover from "@material-ui/core/Popover";
import InputBase from "@material-ui/core/InputBase";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Refresh, Search } from "@material-ui/icons";
//import Badge from "../components/badge/Badge";
//const now = new Date();

const x = {
  Proposed: 1,
  Noshow: 3,
  Selected: 4,
  Rejected: 5,
  Scheduled: 2,
};

const statusColrMapping = {
  Proposed: "#679903",
  Noshow: "#dc3545",
  Selected: "#198754",
  Rejected: "#dc3545",
  Scheduled: "#faba0a",
};

const resourceMap = [
  { resourceId: 1, resourceTitle: "Proposed" },
  { resourceId: 2, resourceTitle: "Scheduled" },
  { resourceId: 3, resourceTitle: "Noshow" },
  { resourceId: 4, resourceTitle: "Selected" },
  { resourceId: 5, resourceTitle: "Rejected" },
];
const selectStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
};
const Schedules = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  // const [searchType, setSearchType] = useState(false);
  const [queryInput, setQueryInput] = useState([]);
  const [searchJobs, setSearchJobs] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const [adminMatches, setAdminMatches] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [backupInterviews, setBackupInterviews] = useState([]);
  const localizer = momentLocalizer(moment);
  useEffect(() => {
    scheduledCandidates(queryInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var searchType = false;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSearch = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };
  const resetFilter = () => {
    setQueryInput([]);
    scheduledCandidates([]);
  };

  const scheduledCandidates = (query) => {
    const userType = localStorage.getItem("ut");

    /* Admin axios & filtering*/
    if (userType === "1110") {
      const corporateId = localStorage.getItem("ci");
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      var sTime = new Date(y, m - 1, 1);

      var eTime = new Date(y, m + 2, 1);

      var reqData = { query: search };

      if (searchType) {
        reqData.searchType = "Letter";
      }

      if (query.length === 0 && reqData.query === "") {
        /*axios for new api for admin*/
        props.showLoader({ show: true });
        axios
          .get(dEndPoints.displayInterviews(corporateId))
          .then((res) => {
            props.showLoader({ show: false });
            var slotData = res.data.data.result;
            const info1 = [];

            slotData &&
              slotData.forEach((dt) => {
                var options = {
                  timeZone: "Asia/Kolkata",
                  timeZoneName: "short",
                  hour12: true,
                };
                let newTime = new Date(dt.sTime).toLocaleTimeString(
                  "en-IN",
                  options
                );
                let newTimeEnd = new Date(dt.eTime).toLocaleTimeString(
                  "en-IN",
                  options
                );
                const dateTimeStart = moment(
                  `${dt.date} ${newTime}`,
                  "YYYY-MM-DD HH:mm:ss a"
                ).format();
                const dateTimeEnd = moment(
                  `${dt.date} ${newTimeEnd}`,
                  "YYYY-MM-DD HH:mm:ss a"
                ).format();

                info1.push({
                  title: `${dt.Candidate[0].name} - ${dt.Candidate[0].cDesig} (${dt.Jobdata[0].cName})`,
                  start: new Date(dateTimeStart),
                  // start: new Date(dt.sTime),
                  end: new Date(dateTimeEnd),
                  resourceId: x[dt.status],
                  id: dt.Candidate[0]._id,
                  hexColor: statusColrMapping[dt.status],
                  reqId: dt.Jobdata[0].reqId,
                  desig: dt.Jobdata[0].desig,
                  cName: dt.Jobdata[0].cName,
                  cDesig: dt.Candidate[0].cDesig,
                });
              });

            setBackupInterviews(info1);
            setInterviews(info1);
            setAdminMatches([]);
          })
          .catch((error) => {
            props.showLoader({ show: false });
            console.log("error while fetching data", error);
          });
      } else if (query.length === 0 && reqData.query !== "") {
        let filterIntreviews = backupInterviews.filter(
          (slot) =>
            slot.reqId === reqData.query ||
            slot.cName === reqData.query ||
            slot.desig === reqData.query
        );

        if (filterIntreviews.length === 0) {
          setSearchResult(false);
        } else {
          const matchOptions = [];
          filterIntreviews.map((ob) => {
            let createObj = {
              id: ob.id,
              cName: ob.cName,
              reqId: ob.reqId,
              desig: ob.desig,
              cDesig: ob.cDesig,
            };
            matchOptions.push(createObj);
          });

          var uniqueOptions = Array.from(
            new Set(matchOptions.map(JSON.stringify))
          ).map(JSON.parse);

          setSearchResult(true);
          setAdminMatches(uniqueOptions);
        }
      }
    } else if (userType === "1100") {
      /* Corporate axios & filtering */
      const corporateId = localStorage.getItem("ci");
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      // var sTime = new Date(y, m, 1);
      // var eTime = new Date(y, m + 2, 1);

      if (query === null) {
        window.location.reload();
      } else if (query.length > 0) {
        const query2 = [];

        for (var i = 0; i < query.length; i++) {
          query2.push(query[i].value);
        }

        //let data = query.map((desig) => desig.value).toString();

        let filterIntreviews = backupInterviews.filter((slot) =>
          query2.includes(slot.desig)
        );

        setInterviews(filterIntreviews);
      } else if (query.length === 0) {
        setInterviews(backupInterviews);
        /*axios for new api corporate*/
        props.showLoader({ show: true });
        axios
          .get(dEndPoints.displayInterviews(corporateId))
          .then((res) => {
            props.showLoader({ show: false });
            var slotData = res.data.data.result;
            const info1 = [];

            setSearchJobs(res.data.data.options);
            slotData &&
              slotData.forEach((dt) => {
                var options = {
                  timeZone: "Asia/Kolkata",
                  timeZoneName: "short",
                };
                let newTime = new Date(dt.sTime).toLocaleTimeString(
                  "en-IN",
                  options
                );
                let newTimeEnd = new Date(dt.eTime).toLocaleTimeString(
                  "en-IN",
                  options
                );
                const dateTimeStart = moment(
                  `${dt.date} ${newTime}`,
                  "YYYY-MM-DD HH:mm:ss a"
                ).format();
                const dateTimeEnd = moment(
                  `${dt.date} ${newTimeEnd}`,
                  "YYYY-MM-DD HH:mm:ss a"
                ).format();

                info1.push({
                  title: `${dt.Candidate[0].name} - ${dt.Candidate[0].cDesig} (${dt.Candidate[0].cName})`,
                  start: new Date(dateTimeStart),
                  //start: new Date(dt.sTime),
                  end: new Date(dateTimeEnd),
                  resourceId: x[dt.status],
                  id: dt.Candidate[0]._id,
                  hexColor: statusColrMapping[dt.status],
                  desig: dt.Jobdata[0].desig,
                  cDesig: dt.Candidate[0].cDesig,
                });
              });
            setBackupInterviews(info1);
            setInterviews(info1);
          })
          .catch((error) => {
            props.showLoader({ show: false });
            console.log("error while fetching data", error);
          });
      }
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      const date = new Date();
      // const y = date.getFullYear();
      // const m = date.getMonth();
      // var sTime = new Date(y, m - 1, 1);
      // var eTime = new Date(y, m + 2, 1);

      if (query === null) {
        window.location.reload();
      } else if (query.length > 0) {
        const query2 = [];

        for (var i = 0; i < query.length; i++) {
          query2.push(query[i].value);
        }
        //  let data = query.map((desig) => desig.value).toString();
        let filterIntreviews = backupInterviews.filter((slot) =>
          query2.includes(slot.desig)
        );
        setInterviews(filterIntreviews);
      } else if (query.length === 0) {
        setInterviews(backupInterviews);
        /*axios for new api vendor*/
        props.showLoader({ show: true });
        axios
          .get(dEndPoints.vendorCalenderInterviews(vendorCorporateId))
          .then((res) => {
            props.showLoader({ show: false });
            var slotData = res.data.data.result;
            const info1 = [];
            setSearchJobs(res.data.data.options);
            slotData &&
              slotData.forEach((dt) => {
                var options = {
                  timeZone: "Asia/Kolkata",
                  timeZoneName: "short",
                };
                let newTime = new Date(dt.sTime).toLocaleTimeString(
                  "en-IN",
                  options
                );
                let newTimeEnd = new Date(dt.eTime).toLocaleTimeString(
                  "en-IN",
                  options
                );
                const dateTimeStart = moment(
                  `${dt.date} ${newTime}`,
                  "YYYY-MM-DD HH:mm:ss a"
                ).format();
                const dateTimeEnd = moment(
                  `${dt.date} ${newTimeEnd}`,
                  "YYYY-MM-DD HH:mm:ss a"
                ).format();

                info1.push({
                  title: `${dt.Candidate[0].name} - ${dt.Candidate[0].cDesig} (${dt.Candidate[0].cName})`,
                  start: new Date(dateTimeStart),
                  //start: new Date(dt.sTime),
                  end: new Date(dateTimeEnd),
                  resourceId: x[dt.status],
                  id: dt.Candidate[0]._id,
                  hexColor: statusColrMapping[dt.status],
                  desig: dt.Jobdata[0].desig,
                  cDesig: dt.Candidate[0].cDesig,
                });
              });
            setBackupInterviews(info1);
            setInterviews(info1);
          })
          .catch((error) => {
            props.showLoader({ show: false });
            console.log("error while fetching data", error);
          });
      }
    }
  };

  const eventStyleGetter = (event) => {
    var backgroundColor = event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  if (searchJobs.length > 0) {
    const newOptions = searchJobs.filter(
      (v, i, a) => a.findIndex((t) => t.desig === v.desig) === i
    );
    var desigReqId = newOptions.map((data) => {
      return {
        value: data.desig,
        label: `${data.desig}- (${data.reqId})`,
      };
    });
  }
  const adminInterviews = (filterId) => {
    let filterIntreviews = backupInterviews.filter(
      (slot) => slot.id === filterId
    );

    if (filterIntreviews.length === 0) {
      setSearchResult(false);
    } else {
      setSearchResult(true);
      setInterviews(filterIntreviews);
      setAdminMatches([]);
    }
  };
  const userType = localStorage.getItem("ut");
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <SideNav {...props} />
        </Col>
        <Col md={10} bg="talenQ">
          <TopNav />
          {searchJobs ? (
            <>
              <div className="calenderViewPoints__calender">
                <strong className="d-block text-center">Status :</strong>
                <div className="badge_wrapper mt-3">
                  <Badge pill bg="Draft" empty>
                    Proposed
                  </Badge>
                  <Badge pill bg="Pending">
                    Scheduled
                  </Badge>
                  <Badge pill bg="success" empty>
                    Selected
                  </Badge>
                  <Badge pill bg="info" empty>
                    Noshow
                  </Badge>
                  <Badge pill bg="danger" empty>
                    Rejected
                  </Badge>
                </div>
              </div>

              {userType === "1100" || userType === "1000" ? (
                <fieldset style={{ marginBottom: "12px" }}>
                  <label>Designation/ReqId</label>
                  <Select
                    styles={selectStyles}
                    className="dropdown1"
                    menuPosition={"fixed"}
                    options={desigReqId || []}
                    value={queryInput}
                    onChange={setQueryInput}
                    isMulti
                  />
                </fieldset>
              ) : (
                ""
              )}
              {userType === "1100" || userType === "1000" ? (
                <fieldset>
                  <Button
                    variant="primary"
                    justIcon
                    id="search"
                    title="Search by Filter"
                    onClick={() => scheduledCandidates(queryInput)}
                  >
                    <Search />
                  </Button>

                  <Button
                    justIcon
                    variant="primary"
                    title="Reset Filter"
                    onClick={resetFilter}
                  >
                    <Refresh />
                  </Button>
                </fieldset>
              ) : (
                ""
              )}
              {userType === "1110" ? (
                <div className="SearchButtonSchadulePage">
                  <Button
                    aria-describedby={id}
                    style={{
                      position: "relative",
                      alignItems: "center",
                    }}
                    color="primary"
                    justIcon
                    round
                    onClick={handleSearch}
                  >
                    <Search />
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div>
                      <InputBase
                        onChange={handleInputChange}
                        placeholder="ReqId,designation,company"
                        inputProps={{ "aria-label": "search" }}
                      />
                      <Button
                        color="info"
                        justIcon
                        onClick={() => scheduledCandidates(queryInput)}
                        title="search"
                      >
                        <Search />
                      </Button>
                    </div>

                    {adminMatches && adminMatches.length > 0 ? (
                      <span style={{ marginLeft: "25px", font: "bold" }}>
                        Try similar matches
                      </span>
                    ) : (
                      ""
                    )}
                    {adminMatches &&
                      adminMatches.map((job, i) => (
                        <ListItem
                          button
                          divider
                          key={i}
                          onClick={() => adminInterviews(job.id)}
                        >
                          <ListItemText
                            primary={<label>{job.cName}</label>}
                            secondary={
                              <span
                                style={{ marginLeft: "5px" }}
                              >{`${job.reqId} - ${job.cDesig}`}</span>
                            }
                          />
                        </ListItem>
                      ))}

                    {searchResult === false ? (
                      <span style={{ marginLeft: "25px", color: "red" }}>
                        No search results, enter the exact search
                      </span>
                    ) : (
                      ""
                    )}
                  </Popover>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          <div className="tq-bucketTwo">
            <Calendar
              events={interviews}
              localizer={localizer}
              defaultView={Views.WEEK}
              views={["day", "week", "month", "agenda"]}
              step={18}
              defaultDate={new Date()}
              resources={resourceMap}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
              eventPropGetter={eventStyleGetter}
              style={{ height: 500 }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
//export default Schedules;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Schedules);
// ${slot.status}  (${can.name})  ${can.jobData[0].desig} - ${can.jobData[0].reqId}
