import React, { useEffect } from "react";
function Chart(props) {
  useEffect(() => {
    if (typeof window === "undefined") {
      return null;
    }
    var chart = window.am4core.create(
      `chartdiv${props.jobId}`,
      window.am4charts.PieChart3D
    );
    //  a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = window.am4core.percent(40);
    window.am4core.options.autoDispose = true;

    // Add data
    chart.data = [
      {
        jobStatus: "RoundClear",
        dataValue: props.resumeCount.RoundClear,
      },
      {
        jobStatus: "Scheduled",
        dataValue: props.resumeCount.Scheduled,
      },
      {
        jobStatus: "RoundCompleted",
        dataValue: props.resumeCount.RoundCompleted,
      },
      {
        jobStatus: "OfferRevoked",
        dataValue: props.resumeCount.OfferRevoked,
      },
      {
        jobStatus: "Offered",
        dataValue: props.resumeCount.Offered,
      },
      {
        jobStatus: "Proposed",
        dataValue: props.resumeCount.Proposed,
      },
      {
        jobStatus: "Dropped",
        dataValue: props.resumeCount.Dropped,
      },
      {
        jobStatus: "Declined",
        dataValue: props.resumeCount.Declined,
      },
      {
        jobStatus: "OfferRejected",
        dataValue: props.resumeCount.OfferRejected,
      },
      {
        jobStatus: "Rejected",
        dataValue: props.resumeCount.Rejected,
      },
      {
        jobStatus: "Onhold",
        dataValue: props.resumeCount.Onhold,
      },
      {
        jobStatus: "Pending",
        dataValue: props.resumeCount.Pending,
      },
      {
        jobStatus: "Screened",
        dataValue: props.resumeCount.Screened,
      },
      {
        jobStatus: "InProcess",
        dataValue: props.resumeCount.InProcess,
      },
      {
        jobStatus: "OfferAccepted",
        dataValue: props.resumeCount.OfferAccepted,
      },
      {
        jobStatus: "Joined",
        dataValue: props.resumeCount.Joined,
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new window.am4charts.PieSeries());
    pieSeries.dataFields.value = "dataValue";
    pieSeries.dataFields.category = "jobStatus";

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.events.on("datavalidated", function (ev) {
      ev.target.slices.each(function (slice) {
        if (
          slice.dataItem.values.value &&
          slice.dataItem.values.value.percent &&
          slice.dataItem.values.value.percent >= 1
        ) {
          //console.log(slice.dataItem);
        } else {
          slice.hide();
        }
      });
    });

    pieSeries.ticks.template.events.on("ready", hideSmall);
    pieSeries.ticks.template.events.on("visibilitychanged", hideSmall);
    pieSeries.labels.template.events.on("ready", hideSmall1);
    pieSeries.labels.template.events.on("visibilitychanged", hideSmall1);

    function hideSmall(ev) {
      if (ev.target.dataItem.values.value.percent >= 1) {
        // ev.target.show();
        ev.target.hide();
      } else {
        ev.target.hide();
      }
    }
    function hideSmall1(ev) {
      if (ev.target.dataItem.values.value.percent > 20) {
        // ev.target.show();
        ev.target.hide();
      } else {
        ev.target.hide();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jobId]);
  return (
    <div
      id={`chartdiv${props.jobId}`}
      style={{ width: "180px", height: "135px" }}
    ></div>
  );
}
export default Chart;
