import React, { useState } from "react";
import endPoints from "../../config/end-point";
import axios from "axios";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
const PersonalDetails = (props) => {
  const { handleNext, handleInputChange, signupUser, userType } = props;

  const [disablesSubmit, setDisableSubmit] = useState(false);
  const [phone, setPhone] = useState(null);

  const requestForOtp = () => {
    if (phone === null) {
      props.showSnackBar({
        message: "Enter contact No. with your country code",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    }
    var dupUser = { ...signupUser };

    dupUser.userAccess = "Admin";
    dupUser.userType = userType;
    dupUser.confirmPassword = signupUser.password;

    if (!dupUser.userType) {
      props.showSnackBar({
        message: "Please select your Type",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    }
    if (phone.length < 12) {
      props.showSnackBar({
        message: "Contact No. must be 12 digit",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    }
    if (phone === null) {
      props.showSnackBar({
        message: "Enter contact No. with your country code",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    }
    axios
      .post(endPoints.register, dupUser)
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100025) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });

          setTimeout(function () {}, 5000);
          handleNext();
        }
        if (data && data.code === 100044) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          handleNext();
        }
        if (data && data.code === 100026) {
          props.showSnackBar({
            message: "Contact number already exist",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          setDisableSubmit(false);
          return;
        }
        if (data && data.code === 100027) {
          props.showSnackBar({
            message: "Email already exist",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          setDisableSubmit(false);
          return;
        }
        if (data && data.code === 100004) {
          props.showSnackBar({
            message: "User Exist",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        if (data && data.code === 100038) {
          props.showSnackBar({
            message: "Email or mobile available",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          setDisableSubmit(false);
          return;
        }
      })

      .catch((error) => {
        setDisableSubmit(false);
        console.log(error, "error");
        if (error.response.data.code === 100022) {
          props.showSnackBar({
            message: error.response.data.error[0].description,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
        }
        setDisableSubmit(false);
        return;
      });
  };
  const knowpasspattern = (e) => {
    e.preventDefault();
  };
  const checkEmailDomain = (e) => {
    e.preventDefault();

    signupUser.contact = phone;

    const passcheck =
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
    if (!passcheck.test(signupUser.password)) {
      props.showSnackBar({
        message:
          "Password should have atleast 8 characters, one uppercase, lowercase & special character",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    } else if (phone === null) {
      props.showSnackBar({
        message: "Enter contact No. with your country code",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      setDisableSubmit(false);
      return;
    } else if (passcheck.test(signupUser.password)) {
      axios
        .get(endPoints.checkUserRegistration, {
          params: {
            userName: signupUser.emailId,
            userType: userType,
          },
        })
        .then((res) => {
          if (res.data.code === 100030) {
            props.showSnackBar({
              message: res.data.message,
              open: true,
              type: "error",
              vertical: "top",
              horizontal: "center",
            });
            return;
          } else if (res.data.code !== 100030) {
            requestForOtp();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="tq-form-container personal-details">
      <h3 className="tq-form-heading">Personal Details</h3>
      <form className="tq-form" onSubmit={checkEmailDomain}>
        <Row>
          <Form.Group as={Col} className="mb-4" xs={12} sm={6}>
            <Form.Label>
              First Name
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              inputProps={{
                autoComplete: "off", // disable autocomplete and autofill
              }}
              type="text"
              placeholder="First Name"
              id="firstName"
              value={signupUser.firstName}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="firstName"
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4" xs={12} sm={6}>
            <Form.Label>
              Last Name
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              id="lastName"
              value={signupUser.lastName}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="lastName"
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4" xs={12} sm={12}>
            <Form.Label>
              Email Address
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              autoComplete="new-email"
              type="text"
              placeholder="Email Address"
              id="emailId"
              value={signupUser.emailId}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="emailId"
              disabled={true}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4" xs={12} sm={12}>
            <Form.Label>
              Password
              <small className="required__form">*</small>
            </Form.Label>
            <Form.Control
              autoComplete="new-password"
              type="password"
              placeholder="Password"
              id="password"
              value={signupUser.password}
              onChange={handleInputChange}
              required="required"
              aria-labelledby="password"
              onFocus={knowpasspattern}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              title="password must be at least one uppercase,one lowercase and one special character and Length should be 8 characters"
            />
          </Form.Group>
          <Form.Group
            as={Col}
            className="mb-4 phoneWithCountry"
            xs={12}
            sm={12}
          >
            <Form.Label>
              Phone
              <small className="required__form">*</small>
            </Form.Label>
            <PhoneInput
              country={"in"}
              value={signupUser.contact}
              onChange={(phone) => setPhone(phone)}
              required="required"
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-4" xs={12} sm={12}>
            <div className="text-center">
              <Button
                className="tq-button"
                type="submit"
                disabled={disablesSubmit}
              >
                Proceed To Verify OTP
              </Button>
            </div>
          </Form.Group>
        </Row>
      </form>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(PersonalDetails);
