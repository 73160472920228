import React, { useState, useContext, useEffect } from "react";
import { dEndPoints } from "../config/end-point";
import axios from "axios";
const AppContext = React.createContext();
const AppProvider = ({ children }) => {
  const [jobContext, setJobContext] = useState({});
  const [activeCIdContext, setActiveCIdContext] = useState('')
  const [activeCandiAnalysisContext, setActiveCandiAnalysisContext] = useState({})
  const [candidateAnalytics, setCandidateAnalytics] = useState([]);
  const userType = localStorage.getItem("ut");
  
  const getAnalyticsData = (jobId) => {
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");
      //const corporateId = cacheData.ci;
      axios
        .get(dEndPoints.candidateAnalytics(corporateId, jobId))
        .then((res) => {
          let data = res.data;
          if (data.code === 100129) {
            setCandidateAnalytics(data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      //const corporateId = cacheData.ci;
      
      axios
        .get(dEndPoints.vendorCandidateAnalytics(vendorCorporateId, jobId))
        .then((res) => {
          let data = res.data;
          if (data.code === 100129) {
            setCandidateAnalytics(data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <AppContext.Provider value={{ jobContext, activeCIdContext, activeCandiAnalysisContext, setJobContext, setActiveCIdContext, setActiveCandiAnalysisContext, getAnalyticsData, candidateAnalytics, setCandidateAnalytics}}>
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
