import React from "react";
import Header from "../common/Header";
import Container from "react-bootstrap/Container";
import Footer from "../common/Footer";
import CtaOne from "../common/CtaOne";
import AchivementsCount from "./AchivementsCount";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TqBenifitCard from "./TqBenifitCard";

const OurServices = () => {
  return (
    <div className="employers-page">
      <Header />
      <section className="employer-banner tq-section-padding">
        <Container>
          <p className="hero-small">Employers</p>
          <h1 className="hero-title">
            Boost Your Hiring <br />
            With Our Vendors
          </h1>
        </Container>
      </section>
      <AchivementsCount
        setClassName="carporate-Values"
        Counts1="70+"
        CountTitle1="Active Vendors"
        Counts2="15+"
        CountTitle2="Average Profiles/Job"
        Counts3="2k+"
        CountTitle3="Screened Candidates"
        Counts4="70%+"
        CountTitle4="Closure Ratio"
      />
      <section className="services-section tq-section-padding">
        <Container>
          <div className="tq-heading-wrapper mb-5">
            <h2 className="section-heading">Benefits For Employers</h2>
          </div>
          <Row>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/expirtHire.png"
                ImgAlt="Expert-hiring-partners"
                CardTitle="Expert hiring partners"
                CardPara="Access to 200+ diversified and specialized Hiring Partners with which you build quality candidates pipeline quickly."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/singlePoint.png"
                ImgAlt="single-point"
                CardTitle="Single point of contact"
                CardPara="Communicate your requirements to the Hiring Partners via Talenq. Less Interaction, Hassle free process."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/fastTurn.png"
                ImgAlt="faster-turnaround-time"
                CardTitle="Faster turnaround time"
                CardPara="With TalenQ supervising the whole process, we will ensure that you close your requirements quickly."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-md-0 mt-sm-0 mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/wideReach.png"
                ImgAlt="widw-reach"
                CardTitle="Wider reach"
                CardPara="With our network of diversified experts, cast a wider net to reach the active and passive Candidates Faster."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/payPer.png"
                ImgAlt="pay-per-hours"
                CardTitle="Pay per hire"
                CardPara="No upfront subscription fees, outcome based pricing."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/realtime.png"
                ImgAlt="real-time-insights"
                CardTitle="Real time insights"
                CardPara="Access anywhere, anytime our user friendly dashboard with real time updates on your Requirements."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/betterCand.png"
                ImgAlt="better-candidate-experience"
                CardTitle="Better candidate experience"
                CardPara="Candidates will be informed about the outcome of each interview and the overall process."
              />
            </Col>
            <Col lg={3} md={6} sm={6} className="mt-5">
              <TqBenifitCard
                ThumbImg="https://talenq.r.worldssl.net/talenq/img/employers/unlimitedJob.png"
                ImgAlt="unlimited-job-postings"
                CardTitle="Unlimited job postings and users"
                CardPara="Post unlimited Jobs on TalenQ portal, Fill as many positions as required with No restrictions on number of users."
              />
            </Col>
          </Row>
        </Container>
      </section>
      <CtaOne
        Text="Struggling to close your hiring requirements? We add value to your recruitment process and deliver best talents in time."
        ButtonText="Request a Demo"
        BtnLink="/contactus"
      />
      <section className="talenq-flow bg-f9 tq-section-padding">
        <Container>
          <div className="row y-middle">
            <div className="col-lg-12">
              <div className="tq-heading-wrapper mb-5">
                <h2 className="section-heading text-left">
                  Our Working Process - How We Work For Employers.
                </h2>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="process-effects-layer">
            <div className="row">
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/register.png"
                        alt="register"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 1 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title"> Register with TalenQ</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 md-mb-30">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/connectReput.png"
                        alt="connect-expert"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 2 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">
                          Connect with Expert Hiring Partners
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sm-mb-30">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/relevantProfile.png"
                        alt="relevent-profile"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 3 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">
                          Get Quality and Relevant Profiles
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="tq-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="https://talenq.r.worldssl.net/talenq/img/hiringPartner/welcome.png"
                        alt="welcome-new-talent"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 4 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">
                          Welcome New Talent to the Organisation
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
export default OurServices;
