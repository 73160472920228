import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/core/styles";
import "./loginsignup.css";
import endPoints from "../../config/end-point";
import axios from "axios";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
const verifyOtpStyle = {
  width: "45px",
  height: "45px",
  margin: "5px 10px",
  border: "1px solid grey",
  padding: "0",
  borderRadius: "8px",
};
const style = makeStyles({
  color: {
    backgroundColor: "#FFD700",
    color: "black",
    marginTop: "15px",
    "&:hover": {
      backgroundColor: "#FFD700",
      color: "black",
    },
  },
  verify: {
    fontFamily: "Tajawal, sans-serif",
    textAlign: "center",
  },
  mobbileOtp: {
    fontSize: "13px",
    color: "black",
    marginBottom: "0px",
    marginLeft: "5px",
    fontFamily: "Tajawal, sans-serif",
  },
});

const OtpVerification = (props) => {
  const { handleNext, signupUser } = props;

  const [verify, setVerify] = useState({
    contactOtp: "",
    emailOtp: "",
  });
  const [disableValidate, setDisableValidate] = useState(false);

  const getMobOtp = (otp) => {
    var dupVerify = { ...verify };
    dupVerify.contactOtp = otp;
    setVerify(dupVerify);
    console.log("otp mobile", dupVerify.contactOtp);
  };
  const getEmailOtp = (otp) => {
    var dupVerify = { ...verify };
    dupVerify.emailOtp = otp;
    setVerify(dupVerify);
    console.log("otp email", dupVerify.emailOtp);
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    var data = { ...verify };
    data.emailId = signupUser.emailId;
    data.contact = signupUser.contact;
    data.verifyProcess = 2;

    if (!data.contactOtp) {
      props.showSnackBar({
        message: "Enter mobile OTP",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (data.contactOtp.length < 4) {
      props.showSnackBar({
        message: "Mob OTP length must be 4 digit",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (!data.emailOtp) {
      props.showSnackBar({
        message: "Enter Email Otp",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (data.emailOtp.length < 4) {
      props.showSnackBar({
        message: "email OTP length must be 4 digit",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    setDisableValidate(true);

    axios
      .post(endPoints.verifyUser, data)
      .then((res) => {
        setDisableValidate(false);
        var data = res.data;
        handleNext();

        if (data && data.code === 100042) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          var subscription =
            data && data.data ? data.data.availSubscriptionList : [];
          props.getsubscriptionList(subscription);
          props.nextStep();
        }
        if (data && data.code === 100042) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        setDisableValidate(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100039
        ) {
          props.showSnackBar({
            message: "Invalid OTP, Enter Correct OTP",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100001
        ) {
          props.showSnackBar({
            message: "Enter Correct OTP, Else Resend OTP",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };
  const clickResend = () => {
    axios
      .get(endPoints.resendOtp, {
        params: {
          verifyProcess: 2,
          emailId: signupUser.emailId,
          contact: signupUser.contact,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.code === 100025) {
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
        } else if (data.code === 100042) {
          handleNext();
          props.showSnackBar({
            message: data.message,
            open: true,
            type: "success",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === 100032
        ) {
          props.showSnackBar({
            message: "User attempt max number of time for otp",
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
      });
  };

  const usestyle = style();
  return (
    <div className="tq-form-container otp-verification text-center">
      <h3 className="tq-form-heading">Verify Your Identity</h3>
      <form className="tq-form">
        <Row>
          <Form.Group
            className="Otp-verify text-center"
            as={Col}
            xs={12}
            sm={12}
          >
            <Form.Label>
              <i className="bx bxs-phone otp__icon"></i>
              OTP sent to Mobile :<small className="required__form">*</small>
            </Form.Label>
            <OtpInput
              onChange={getMobOtp}
              numInputs={4}
              value={verify.contactOtp}
              shouldAutoFocus
              hasErrored
              inputStyle={verifyOtpStyle}
            />
          </Form.Group>
          <Form.Group
            className="Otp-verify text-center mt-3"
            as={Col}
            xs={12}
            sm={12}
          >
            <Form.Label>
              <i className="bx bxs-envelope otp__icon"></i>
              OTP sent to Email :<small className="required__form">*</small>
            </Form.Label>
            <OtpInput
              onChange={getEmailOtp}
              numInputs={4}
              value={verify.emailOtp}
              shouldAutoFocus
              hasErrored
              inputStyle={verifyOtpStyle}
            />
          </Form.Group>
          <Col xs={10} className="m-auto">
            <Row className="mt-4">
              <Form.Group as={Col} xs={12} sm={6}>
                <Button
                  variant="secondary"
                  onClick={clickResend}
                  title="Resend otp"
                >
                  Resend OTP
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6}>
                <Button
                  variant="primary"
                  disabled={disableValidate}
                  onClick={verifyOtp}
                >
                  Continue
                </Button>
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(OtpVerification);
