import "./indicator.css";
const ClickIndicator = () => {
  return (
    <div className="click__indicator">
      <div className="box">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
export default ClickIndicator;
