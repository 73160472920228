import React from "react";
import loader1 from "./talenqloader.svg";
import loader2 from "./talenqloader2.svg";
import "./loader.css";
import { connect } from "react-redux";

const Loader = (props) => {
  return (
    <div>
      {props.loader.show ? (
        <div className="loaderContainer">
          <div id="loader" className="loader">
            <div className="loader-inner ball-scale-multiple">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="image_divloader">
              <img src={loader1} alt="loading" className="loader_logo " />
              <img src={loader2} alt="loding" className="loader_logo2" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loader: state.loader,
  };
}

export default connect(mapStateToProps, null)(Loader);
