import React from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "./Sidenav.css";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";

  // --------------- UI Component for navigation item --------------
  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

// --------------- Default menu for all users --------------
const DefaultNavigations = (props) => {
  let { jobId } = useParams();
  const userType = localStorage.getItem("ut");
  return (
    <div className="nav-itemsTq">
      <small className="seprator-title">Menu</small>

      <Link
        to="/dashboard"
        id={window.location.pathname === "/dashboard" ? "active" : ""}
      >
        <SidebarItem icon="bx bx-category-alt" title="Dashboard" />
      </Link>
      {userType === "1110" || userType === "1100" ? (
        <Link
          to="/alljobs"
          id={
            window.location.pathname === "/alljobs" ||
            window.location.pathname === `/alljobs/${jobId}`
              ? "active"
              : ""
          }
        >
          <SidebarItem icon="bx bx-file" title="My Jobs" />
        </Link>
      ) : (
        <Link
          to="/alljobs"
          id={
            window.location.pathname === "/alljobs" ||
            window.location.pathname === `/alljobs/${jobId}`
              ? "active"
              : ""
          }
        >
          <SidebarItem icon="bx bx-file" title="Enrolled Jobs" />
        </Link>
      )}
      {userType === "1100" || userType === "1110" ? (
        <Link
          to="/createjob"
          id={
            window.location.pathname === "/createjob" ||
            window.location.pathname === `/createjob/${jobId}`
              ? "active"
              : ""
          }
        >
          <SidebarItem icon="bx bx-customize" title="Create Job" />
        </Link>
      ) : (
        ""
      )}
      {userType === "1000" ? (
        <Link
          to="/find-jobs"
          id={window.location.pathname === "/find-jobs" ? "active" : ""}
        >
          <SidebarItem icon="bx bx-file-find" title="Find Job" />
        </Link>
      ) : (
        ""
      )}
      <Link
        to="/schedules"
        id={window.location.pathname === "/schedules" ? "active" : ""}
      >
        <SidebarItem icon="bx bx-calendar" title="Schedules" />
      </Link>
      <Link
        to="/notifications"
        id={window.location.pathname === "/notifications" ? "active" : ""}
      >
        <SidebarItem icon="bx bx-bell" title="Notifications" />
      </Link>
      <Link
        to="/settings"
        id={
          window.location.pathname === "/settings" ||
          window.location.pathname === "/settings/notification-setting" ||
          window.location.pathname === "/settings/companyprofile" ||
          window.location.pathname === "/settings/bucketSettings" ||
          window.location.pathname === "/bucketSettings/create-bucket" ||
          window.location.pathname === "/settings/uploadResumes" ||
          window.location.pathname === "/settings/userprofile"
            ? "active"
            : ""
        }
      >
        <SidebarItem icon="bx bx-cog" title="settings" />
      </Link>
    </div>
  );
};

// --------------- Customized according to user menu --------------

const UserBasedNavigations = (props) => {
  let { userId } = useParams();
  const userType = localStorage.getItem("ut");
  return (
    <div className="nav-itemsTq">
      {userType === "1110" ? (
        <small className="seprator-title">Admin Menu</small>
      ) : (
        ""
      )}
      {userType === "1110" ? (
        <>
          <Link
            to="/activateuser"
            id={window.location.pathname === "/activateuser" ? "active" : ""}
          >
            <SidebarItem title="Activate User" icon="bx bx-user-check" />
          </Link>
          <Link
            to="/approveJob"
            id={window.location.pathname === "/approveJob" ? "active" : ""}
          >
            <SidebarItem icon="bx bx-receipt" title="All Jobs Action" />
          </Link>
          <Link
            to="/alluser"
            id={
              window.location.pathname === "/alluser" ||
              window.location.pathname === `/alluser/${userId}`
                ? "active"
                : ""
            }
          >
            <SidebarItem icon="bx bx-user" title="All Users" />
          </Link>
          <Link
            to="/communication"
            id={window.location.pathname === "/communication" ? "active" : ""}
          >
            <SidebarItem
              icon="bx bx-message-square-dots"
              title="Communication"
            />
          </Link>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
const SideNav = (props) => {
  return (
    <div className={`tq-SideNav ${props.className}`}>
      <div className="logoTq">
        <img src={logo} alt="TalenQ Logo" />
      </div>
      <>
        <DefaultNavigations />
        <UserBasedNavigations />
      </>
    </div>
  );
};
export default SideNav;
