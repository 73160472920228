import React from "react";
// import "../main.css";
// import "../tqui.css";
// import "./tqResponsive.css";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";

const AchivementsCount = (props) => {
  return (
    <section className="counter-section tq-section-padding bg-f9">
      <Container maxWidth="lg">
        <Row>
          <Col xs={12} sm={6} md={3} className=" mt-0 mt-md-0 mt-sm-0">
            <div className="count-card">
              <span className="numbers">{props.Counts1}</span>
              <p className="count-title">{props.CountTitle1}</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={3} className=" mt-0 mt-md-0 mt-sm-5">
            <div className="count-card">
              <span className="numbers">{props.Counts2}</span>
              <p className="count-title">{props.CountTitle2}</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={3} className=" mt-0 mt-md-0 mt-sm-5">
            <div className="count-card">
              <span className="numbers">{props.Counts3}</span>
              <p className="count-title">{props.CountTitle3}</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={3} className=" mt-0 mt-md-0 mt-sm-5">
            <div className="count-card">
              <span className="numbers">{props.Counts4}</span>
              <p className="count-title">{props.CountTitle4}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AchivementsCount;
