import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { dEndPoints } from "../../config/end-point";
import { useHistory } from "react-router";
const JodTable = (props) => {
  let history = new useHistory();
  const [pageNum, setPageNum] = useState(0);
  const [jobData, setJobData] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [linkedJobs, setLinkedJobs] = useState([]);
  // const clickDashboardRequirementVendor = (jobId) => {
  //   history.push(`/alljobs/${jobId}?enroll=${false}`);
  // };
  useEffect(() => {
    handleSearchJob("", pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearchJob = (serchInput, pageNum) => {
    const vendorCorporateId = localStorage.getItem("vci");
    const data = {
      keywords: serchInput,
    };
    //  props.showLoader({ show: true });
    axios
      .post(dEndPoints.findRequirement(vendorCorporateId), data, {
        params: {
          limit: 30,
          pageNumber: pageNum,
        },
      })
      .then((res) => {
        //  props.showLoader({ show: false });
        if (res.data.data.jobs) {
          setJobData(res.data.data.jobs);
          setLinkedJobs(res.data.data.linkedJob);
          setJobCount(Math.floor(data.jobCount / 12) + 1);
        }
      })
      .catch((error) => {
        //props.showLoader({ show: false });
        console.log("error", error);
      });
  };

  var linkjobIds = linkedJobs.map((job) => {
    return job.jobId;
  });

  var finaJobs = [];
  jobData &&
    jobData.map((item, index) => {
      var newJob = linkjobIds.indexOf(item._id) > -1 ? true : false;

      if (newJob === false) {
        finaJobs.push(item);
      }
    });

  const clickDashboardRequirement = (jobId) => {
    history.push(`/alljobs/${jobId}?enroll=${true}`);
  };
  const clickVendorRequirement = (jobId) => {
    //const enrollStatus = linkjobIds.indexOf(jobId) > -1 ? true : false;
    history.push(`/alljobs/${jobId}?enroll=${false}`);
  };
  return (
    <div className="jod__tabel-component">
      <Table striped size="sm" className="table-borderless jod-table">
        <thead className="jod_table-header">
          <tr>
            <th>Job ID</th>
            <th>Job Title</th>
            <th>No. of Position</th>
            <th>Experience</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className="jod_table-body">
          {props.jobOfTheDay &&
            props.jobOfTheDay.map((item) => (
              <tr>
                <td>{item.reqId}</td>
                <td>{item.desig}</td>
                <td>{item.totpos}</td>
                <td>
                  {item.expFrom} {"-"} {item.expTo}
                </td>
                <td>
                  {/* <Button
                    onClick={() => clickDashboardRequirementVendor(item._id)}
                    variant="secondary"
                  >
                    View JD
                  </Button> */}
                  {linkjobIds.indexOf(item._id) > -1 ? (
                    <Button
                      variant="primary"
                      className={`${props.enrolled}`}
                      onClick={() => clickDashboardRequirement(item._id)}
                    >
                      view JD
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className={`${props.enrolled}`}
                      onClick={() => clickVendorRequirement(item._id)}
                    >
                      View JD
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan={5} className="text-center ">
              <Link to="/find-jobs">View All</Link>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default JodTable;
