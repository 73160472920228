let INITIAL_STATE_CID = {
    cid: ""
};
export default (state = INITIAL_STATE_CID, action) => {
    switch (action.type) {
        case "SET_CID":
            // console.log("payload:", action.payload, "state:", state, "cid in the Reducer");
            return Object.assign(action.payload);
        default:
            return state;
    }
};

