import React from "react";
import { useHistory } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./jobscard.css";
import Button from "react-bootstrap/Button";
import Tooltip from "@material-ui/core/Tooltip";

const FindJobCard = (props) => {
  const history = useHistory();
  const clickDashboardRequirement = (jobId) => {
    history.push(`/alljobs/${jobId}?enroll=${true}`);
  };
  const clickVendorRequirement = (jobId) => {
    //const enrollStatus = linkjobIds.indexOf(jobId) > -1 ? true : false;
    history.push(`/alljobs/${jobId}?enroll=${false}`);
  };

  return (
    <div className="Find__Job-card JobCard">
      <span>
        <div
          className="cardHeader"
          onClick={
            props.enrolled === true
              ? () => clickDashboardRequirement(props._id)
              : () => clickVendorRequirement(props._id)
          }
        >
          <Row>
            <Col>
              <div className="labelArea">
                <Tooltip title="Job Status">
                  <div className="status">{props.badge}</div>
                </Tooltip>
                <div className="vCardId reqId">
                  <strong>Req. ID : </strong> <span>{props.reqId}</span>
                </div>
                <div className="vCardType">
                  {props.jobOfTheDay === true ? (
                    <Tooltip title="Job Of The Day">
                      <span
                        className="joday job_of_day"
                        jodView={props.jodstatus}
                      >
                        Job of the day
                      </span>
                    </Tooltip>
                  ) : (
                    <span></span>
                  )}

                  <span className="job__type">
                    <Tooltip title="Job Type">
                      <p className={`${props.jobType}`}>{props.jobType}</p>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </Col>
            <Col className="resume__meter-wrapper">
              {/* <Tooltip title="Resume Meter"> */}
              <div className="chartArea">{props.ChartDatas}</div>
              {/* </Tooltip> */}
            </Col>
          </Row>
        </div>
        <div className="cardBody">
          <div className="cardPriority">
            <h2 className="cardTitle">{props.Heading}</h2>
            <div className="cardRow">
              <strong>Company :</strong>
              <p>{props.company}</p>
            </div>
            <div className="cardRow">
              <strong>Skills :</strong>
              <p>{props.skills}</p>
            </div>
          </div>
          <div className="cardPriority otherDetails">
            {/* <div className="cardRow">
              <strong>Posted On :</strong>
              <p className="m-0">{props.posted}</p>
            </div> */}
            {props.location ? (
              <div className="cardRow">
                <strong>Locations :</strong>
                <p>{props.location}</p>
              </div>
            ) : (
              <></>
            )}
            {props.notice ? (
              <div className="cardRow">
                <strong>Notice Period : </strong>
                <p>{props.notice} days</p>
              </div>
            ) : (
              <></>
            )}
            {props.rate ? (
              <div className="cardRow">
                <strong>Rate : </strong>
                <p>{props.rate} %</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </span>
      <div className="cardFooter">
        <Row>
          <Col xs={5} className="align-self-center">
            <div className="posted__on">
              <p className="m-0">{props.posted}</p>
            </div>
          </Col>
          <Col xs={7}>
            <div className="buttonsGroup text-right">
              {props.enrolled === true ? (
                <Button
                  variant="success"
                  className={`${props.enrolled}`}
                  onClick={() => clickDashboardRequirement(props._id)}
                >
                  Enrolled
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={`${props.enrolled}`}
                  onClick={() => clickVendorRequirement(props._id)}
                >
                  View/Enroll
                </Button>
              )}
              {/* <button className={`jobAction ${props.enrolled}`}>
                Enrolled/Enroll
              </button> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
// function mapStateToProps(state) {
//   return {
//     xyz: state.main,
//     jobId: state.mainjobid,
//   };
// }
// export default connect(mapStateToProps)(FindJobCard);
export default FindJobCard;
