import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Ctatwo from "../common/Ctatwo";
import "../../assets/css/responsive.css";
import CtaOne from "../common/CtaOne";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Employers = () => {
  return (
    <div className="landingHome-page">
      <Header />
      <section className="hero-section text-left tq-section-padding">
        <div className="container">
          <p className="hero-small"> AI Driven Vendor Aggregation Platform </p>
          <h1 className="hero-title">
            Hire Faster, <br />
            Hire Best
          </h1>
          <div className="button-group">
            <a href="/login-signup" className="btn-tq btn-theme">
              Request a Demo
            </a>
          </div>
        </div>
      </section>
      {/* ---------- work withus -------------------- */}
      <section className="workwithus-section tq-section-padding">
        <Container>
          <div className="tq-heading-wrapper mb-5 text-center">
            <p className="top-heading">Why TalenQ ?</p>
            <h2 className="section-heading">
              Let’s begin our Hiring Journey together!
            </h2>
          </div>
          <Row>
            <Col md={4} sm={6} xs={12} className="mb-4">
              <div className="serviceCard">
                <img
                  className="img-fluid"
                  src="https://talenq.r.worldssl.net/talenq/img/empl.png"
                  alt="employeers"
                />
                <h3>Employer</h3>
                <p>
                  Get access to the network of 100+ hiring experts and build
                  quality candidate pipeline for your requirements.
                </p>
                <a
                  href="/employers"
                  title="Know about"
                  target="_blank"
                  className="serviceKnowMore"
                >
                  Know More
                </a>
              </div>
            </Col>
            <Col md={4} sm={6} xs={12} className="mb-4">
              <div className="serviceCard">
                <img
                  className="img-fluid"
                  src="https://talenq.r.worldssl.net/talenq/img/partn.png"
                  alt="Hiring Partner"
                />
                <h3>Hiring Partner</h3>
                <p>
                  Connect with Reputed Employers and engage with the wide range
                  of active Jobs to work on and generate more revenue.
                </p>
                <a
                  href="/hiring-partner"
                  title="Know about"
                  target="_blank"
                  className="serviceKnowMore"
                >
                  Know More
                </a>
              </div>
            </Col>
            <Col md={4} sm={6} xs={12} className="mb-4">
              <div className="serviceCard">
                <img
                  className="img-fluid"
                  src="https://talenq.r.worldssl.net/talenq/img/taln.png"
                  alt="TalenQ"
                />
                <h3>TalenQ</h3>
                <p>
                  TalenQ brings Employers and Hiring Partners together in order
                  to identify and hire the right candidate in a timely manner at
                  an affordable cost.
                </p>
                <a
                  href="/about"
                  title="Know about"
                  target="_blank"
                  className="serviceKnowMore"
                >
                  Know More
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ---------- home benifits -------------------- */}
      <section className="homeBenifit-section">
        <div className="hiringPartnerBnt EmpPartnerBnt tq-section-padding">
          <div className="container">
            <Row>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                <div className="sec-title mb-3">
                  <span className="sub-text small">Employers</span>
                  <h2 className="title pb-25">
                    Build Candidate Pipeline Quickly.
                  </h2>
                  <p className="m-0 homeHireP">
                    Get quality profiles from our Network of hiring experts and
                    hire the right talent in a timely manner for your
                    organisation.
                  </p>
                </div>
                <Accordion>
                  <Accordion.Item className="mb-2" eventKey="0">
                    <Accordion.Header>
                      Access to expert hiring partners
                    </Accordion.Header>
                    <Accordion.Body>
                      Access to 200+ diversified and specialized Hiring Partners
                      with which you build quality candidates pipeline quickly.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className="mb-2" eventKey="1">
                    <Accordion.Header>
                      Unlimited job postings and users
                    </Accordion.Header>
                    <Accordion.Body>
                      Post unlimited Jobs on TalenQ portal, Fill as many
                      positions as required with No restrictions on number of
                      users.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className="mb-2" eventKey="2">
                    <Accordion.Header>Pay per hire</Accordion.Header>
                    <Accordion.Body>
                      No upfront subscription fees, outcome based pricing.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className="mb-2" eventKey="3">
                    <a className="knowMoreUser" href="/employers">
                      Know More →
                    </a>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-6 col-sm-6 mb-4 d-block d-xs-none">
                <div className="about-content">
                  <div className="images-part">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/homeEmp.png"
                      alt="hiring-partner-key-points"
                      className="img-fluid"
                    />
                  </div>
                  <div className="rs-animations dotRight">
                    <div className="spinner dot ">
                      <img
                        className="scale"
                        src="https://talenq.r.worldssl.net/talenq/img/textureDots.png"
                        alt="Images"
                      />
                    </div>
                    <div className="spinner ball">
                      <img
                        className="dance2"
                        src="https://talenq.r.worldssl.net/talenq/img/textureLine.png"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
        <CtaOne
          Text="TalenQ delivers the solution to your hiring problems by providing the best suited to your requirements."
          ButtonText="Let’s get Started "
          BtnLink="/contactus"
        />
        <div className="hiringPartnerBnt bg-f9 tq-section-padding">
          <div className="container">
            <Row>
              <div className="col-md-6 col-sm-6 mb-4 d-block d-xs-none">
                <div className="about-content">
                  <div className="images-part">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/homeHire.png"
                      alt="hiring-partner-key-points"
                      className="img-fluid"
                    />
                  </div>
                  <div className="rs-animations">
                    <div className="spinner dot">
                      <img
                        className="scale"
                        src="https://talenq.r.worldssl.net/talenq/img/textureDots.png"
                        alt="Images"
                      />
                    </div>
                    <div className="spinner ball">
                      <img
                        className="dance2"
                        src="https://talenq.r.worldssl.net/talenq/img/textureLine.png"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                <div className="sec-title mb-4">
                  <span className="sub-text small">Hiring Partners</span>
                  <h2 className="title pb-25">
                    Engage with the wide range of active jobs and Generate
                    Revenue.
                  </h2>
                  <p className="m-0 homeHireP">
                    Connect with Reputed Employers with access to diverse
                    industries across India and expand your business horizons
                    via TalenQ.
                  </p>
                </div>
                <Accordion>
                  <Accordion.Item className="mb-2" eventKey="0">
                    <Accordion.Header>
                      Expand your business with no sales efforts.
                    </Accordion.Header>
                    <Accordion.Body>
                      Connect with reputed employers via TalenQ with access to
                      diverse Industries across India.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className="mb-2" eventKey="1">
                    <Accordion.Header>
                      Continuous flow of requirements
                    </Accordion.Header>
                    <Accordion.Body>
                      More Requirements, just work dedicatedly with quality
                      candidates.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className="mb-2" eventKey="2">
                    <Accordion.Header>
                      Real time insights on candidate status
                    </Accordion.Header>
                    <Accordion.Body>
                      Access anywhere, anytime with real time updates on your
                      candidates.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className="mb-2" eventKey="3">
                    <a className="knowMoreUser" href="/hiring-partner">
                      Know More →
                    </a>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Row>
          </div>
        </div>
      </section>
      <Ctatwo
        Line1="Are you a Hiring Agency ?"
        Line2="Join us as a Hiring Partner and Generate More Revenue"
        BtnLink="/login-signup"
        ButtonText="Register Free"
      />
      <Footer />{" "}
    </div>
  );
};
export default Employers;
