import React, { useEffect } from "react";

const StackedGraph = (props) => {
  // const pendignResumeCount =
  //   props.graphPlotting && props.graphPlotting.map((item) => item.pendingCount);
  // const screenedResumeCount =
  //   props.graphPlotting &&
  //   props.graphPlotting.map((item) => item.screenedCount);
  // const offeredResumeCount =
  //   props.graphPlotting && props.graphPlotting.map((item) => item.offeredCount);

  // const rejectedResumeCount =
  //   props.graphPlotting &&
  //   props.graphPlotting.map((item) => item.rejectedCount); // const totalPositionCount = props.graphPlotting.map(

  // const reqIds =
  //   props.graphPlotting && props.graphPlotting.map((item) => item.reqId);
  useEffect(() => {
    if (typeof window === "undefined") {
      return null;
    }

    var chart = window.am4core.create(
      `chartdiv${props}`,
      window.am4charts.XYChart
    );
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.numberFormatter.numberFormat = "#";
    var data = props.graphPlotting.map((item) => {
      return {
        category: item.reqId,
        value1: item.pendingCount,
        value2: item.screenedCount,
        value3: item.offeredCount,
        value4: item.rejectedCount,
        Position: item.desig,
      };
    });
    chart.data = data;
    chart.colors.step = 2;
    chart.padding(30, 30, 10, 30);
    chart.legend = new window.am4charts.Legend();

    var categoryAxis = chart.xAxes.push(new window.am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.title.text = "Req Ids";
    categoryAxis.title.fontWeight = "bold";
    var valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.numberFormatter = new window.am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#";
    valueAxis.title.text = "Resume counts";
    valueAxis.title.fontWeight = "bold";
    var series1 = chart.series.push(new window.am4charts.ColumnSeries());
    series1.columns.template.maxWidth = 50;
    series1.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#')}%  {name1}: {valueZ}";
    series1.name = "Pending Resume";
    series1.name1 = "Position";
    series1.dataFields.categoryX = "category";
    series1.dataFields.valueY = "value1";
    series1.dataFields.valueZ = "Position";
    series1.dataFields.valueYShow = "totalPercent";
    series1.dataItems.template.locations.categoryX = 0.5;
    series1.stacked = true;
    series1.tooltip.pointerOrientation = "vertical";

    var bullet1 = series1.bullets.push(new window.am4charts.LabelBullet());
    bullet1.label.text = "{valueY.totalPercent.formatNumber('#')}%";
    bullet1.label.fill = window.am4core.color("#ffffff");
    bullet1.locationY = 0.5;

    var series2 = chart.series.push(new window.am4charts.ColumnSeries());
    // series2.columns.template.width = window.am4core.percent(100);
    series2.columns.template.maxWidth = 50;
    series2.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#')}%  {name1}: {valueZ}";
    series2.name = "Screened Resume";
    series2.name1 = "Position";
    series2.dataFields.categoryX = "category";
    series2.dataFields.valueY = "value2";
    series2.dataFields.valueZ = "Position";
    series2.dataFields.valueYShow = "totalPercent";
    series2.dataItems.template.locations.categoryX = 0.5;
    series2.stacked = true;
    series2.tooltip.pointerOrientation = "vertical";

    var bullet2 = series2.bullets.push(new window.am4charts.LabelBullet());
    bullet2.label.text = "{valueY.totalPercent.formatNumber('#')}%";
    bullet2.locationY = 0.5;
    bullet2.label.fill = window.am4core.color("#ffffff");

    var series3 = chart.series.push(new window.am4charts.ColumnSeries());
    series3.columns.template.maxWidth = 50;
    series3.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#')}%  {name1}: {valueZ}";
    series3.name = "Offered Resume";
    series3.name1 = "Position";
    series3.dataFields.categoryX = "category";
    series3.dataFields.valueY = "value3";
    series3.dataFields.valueZ = "Position";
    series3.dataFields.valueYShow = "totalPercent";
    series3.dataItems.template.locations.categoryX = 0.5;
    series3.stacked = true;
    series3.tooltip.pointerOrientation = "vertical";

    var bullet3 = series3.bullets.push(new window.am4charts.LabelBullet());
    bullet3.label.text = "{valueY.totalPercent.formatNumber('#')}%";
    bullet3.locationY = 0.5;
    bullet3.label.fill = window.am4core.color("#ffffff");

    var series4 = chart.series.push(new window.am4charts.ColumnSeries());
    series4.columns.template.maxWidth = 50;
    series4.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#')}%  {name1}: {valueZ}";
    series4.name = "Rejected Resume";
    series4.name1 = "Position";
    series4.dataFields.categoryX = "category";
    series4.dataFields.valueY = "value4";
    series4.dataFields.valueZ = "Position";
    series4.dataFields.valueYShow = "totalPercent";
    series4.dataItems.template.locations.categoryX = 0.5;
    series4.stacked = true;
    series4.tooltip.pointerOrientation = "vertical";

    var bullet4 = series4.bullets.push(new window.am4charts.LabelBullet());
    bullet4.label.text = "{valueY.totalPercent.formatNumber('#')}%";
    bullet4.locationY = 0.5;
    bullet4.label.fill = window.am4core.color("#ffffff");

    bullet4.label.hideOversized = false;
    chart.legend = new window.am4charts.Legend();

    // Cursor
    chart.cursor = new window.am4charts.XYCursor();
  }, [props]);

  return (
    <div
      id={`chartdiv${props}`}
      style={{ width: "100%", height: "720px" }}
    ></div>
  );
};
export default StackedGraph;
