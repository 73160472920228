import React, { useState } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import endPoints from "../../config/end-point";
import axios from "axios";
import { showSnackBar } from "../../actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import GridContainer from "../../common/mataerialKit/components/Grid/GridContainer";
import { useHistory } from "react-router";
import Warning from "@material-ui/icons/Warning";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UserType = (props) => {
  const { handleNext, handleChange, userType, signupUser } = props;
  const [disablesSubmit, setDisableSubmit] = useState(false);
  const [openInvalidEmail, setOpenInvalidEmail] = useState(false);

  let history = new useHistory();

  const submitUserDetails = (e) => {
    if (userType === "") {
      props.showSnackBar({
        message: "Please select the user type",
        open: true,
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else {
      axios
        .get(endPoints.checkUserRegistration, {
          params: {
            userName: signupUser.emailId,
            userType: userType,
          },
        })
        .then((res) => {
          handleNext();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code === 100023) {
            setOpenInvalidEmail(true);

            setDisableSubmit(false);
            return;
          }
        });
    }
  };

  const homepage = () => {
    history.push("/");
  };
  const contactus = () => {
    history.push("/login-signup");
  };

  return (
    <div className="tq-form-container UserType-class">
      <h3 className="tq-form-heading">New registration</h3>
      <form className="tq-form">
        <Row className="text-center">
          <RadioGroup
            aria-label="userType"
            name="userType"
            value={userType}
            onChange={handleChange}
          >
            <Row>
              <Form.Group as={Col} xs={12} sm={6}>
                <FormControlLabel
                  value="Corporate"
                  control={<Radio />}
                  label="Corporate"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6}>
                <FormControlLabel
                  value="Vendor"
                  control={<Radio />}
                  label="Hiring Partner"
                />
              </Form.Group>
            </Row>
          </RadioGroup>
          <Form.Group as={Col} xs={12} sm={12}>
            <div className="text-center">
              <Button className="tq-button" onClick={submitUserDetails}>
                Continue
              </Button>
            </div>
          </Form.Group>
        </Row>
      </form>
      {/* ****** Dialog for Invalid Email ******* */}

      <GridContainer>
        <Dialog className="tqDialogue" open={openInvalidEmail}>
          <div className="tq-form-container UserType-class">
            <h4 className="dialogueHliteMsg">
              <Warning
                style={{
                  color: "#f90b0b",
                  fontSize: "40px",
                  marginBottom: "16px",
                }}
              />
              <br></br>
              Invalid Email
            </h4>
            <p className="dialogueMessageText">
              Please use your official email address to register. We don't
              accept public email address for corporate's at this point.
            </p>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className="text-center">
                  <Button
                    className="tq-button"
                    type="submit"
                    onClick={homepage}
                    style={{ backgroundColor: "#4d4d4d", color: "#fff" }}
                  >
                    Go to Home
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="text-center">
                  <Button
                    className="tq-button"
                    type="submit"
                    onClick={contactus}
                  >
                    Signup again
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </GridContainer>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}
export default connect(null, mapDispatchToProps)(UserType);
