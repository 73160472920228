import React from "react";
// import "../main.css";
// import "../tqui.css";
// import Container from "react-bootstrap/Container";

const TrustedPartner = () => {
  return (
    <section className="trustedby-section tq-section-padding bg-f9">
      <div className="container">
        <div className="tq-heading-wrapper text-center">
          <h2 className="section-heading">Corporates relying on TalenQ</h2>
        </div>
        <div className="partners-wrapper">
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/aspiresys.png"
              alt="aspiresys"
              title="aspiresys"
              className="img-fluid w-100"
            />
          </div>
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/cloudThing.png"
              alt="cloudThing"
              title="cloudThing"
              className="img-fluid w-100"
            />
          </div>
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/anthology.png"
              alt="Anthology"
              title="Anthology"
              className="img-fluid w-100"
            />
          </div>
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/talisma.png"
              alt="Talisma"
              title="Talisma"
              className="img-fluid w-100"
            />
          </div>
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/phablecare.png"
              alt="phablecare"
              title="Phablecare"
              className="img-fluid w-100"
            />
          </div>
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/moius.png"
              alt="Moius"
              title="Moius"
              className="img-fluid w-100"
            />
          </div>
          <div className="partner-thumb">
            <img
              src="https://talenq.r.worldssl.net/talenq/img/trustedBy/degpeg.png"
              alt="degpeg"
              title="Degpeg"
              className="img-fluid w-100"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default TrustedPartner;
