import React, { useEffect, useState } from "react";
import "./topnav.css";
import { Link } from "react-router-dom";
import Dropdowns from "../dropdown/Dropdowns";
import { useHistory } from "react-router";
import moment from "moment";
import thumb__profile from "../../assets/images/thumb__profile.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import loginPageStyle from "../../common/mataerialKit/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import endPoints, { dEndPoints } from "../../config/end-point";
import axios from "axios";
import Search from "@material-ui/icons/Search";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { bindActionCreators } from "redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Popover from "@material-ui/core/Popover";
import { showSnackBar, showLoader } from "../../actions/index";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import WorkIcon from "@material-ui/icons/Work";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import {
  makeStyles,
  createStyles,
  withStyles,
  fade,
} from "@material-ui/core/styles";

const renderNotificationItem = (item, index) => {
  var userId = localStorage.getItem("i");
  return (
    <div
      className={
        item.viewed.includes(userId)
          ? "notification-item"
          : "notification-item unseen"
      }
      key={index}
    >
      <Link
        to={`/alljobs/${item.parentId}?candId=${item.childId}`}
        style={{ color: "#4183c4" }}
      >
        <div className="notification__structure-wrap">
          <div className="notification__item-list txt-color">
            <strong>Name : </strong>
            <span> {item.childName} </span>
          </div>
          <div className="notification__item-list txt-color">
            <strong>ReqId : </strong>
            <span> {item.notificationData.reqId} </span>
          </div>
          <div className="notification__item-list txt-color">
            <strong>Title : </strong>
            <span> {item.notificationData.title} </span>
          </div>
          <div className="notification__item-list txt-color">
            <span className="floating__time">
              {moment(new Date(item.createdOn)).fromNow()}
            </span>
          </div>
          <div className="notification__item-list">
            <strong>Status : </strong>
            <span>{item.action}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const renderNotificationItemVendor = (item, index) => {
  var userId = localStorage.getItem("i");
  return (
    <div
      className={
        item.viewed.includes(userId)
          ? "notification-item"
          : "notification-item unseen"
      }
      key={index}
    >
      <Link
        to={`/alljobs/${item.parentId}?candId=${item.childId}&enroll=${true}`}
        style={{ color: "#4183c4" }}
      >
        <div className="notification__structure-wrap">
          <div className="notification__item-list txt-color">
            <strong>Name : </strong>
            <span> {item.childName} </span>
          </div>
          <div className="notification__item-list txt-color">
            <strong>ReqId : </strong>
            <span> {item.notificationData.reqId} </span>
          </div>
          <div className="notification__item-list txt-color">
            <strong>Title : </strong>
            <span> {item.notificationData.title} </span>
          </div>
          <div className="notification__item-list txt-color">
            <span className="floating__time">
              {moment(new Date(item.createdOn)).fromNow()}
            </span>
          </div>
          <div className="notification__item-list">
            <strong>Status : </strong>
            <span>{item.action}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      // border: "1px solid #008145",
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.1),
      },
      width: "100%",
      margin: "10px",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
      display: "flex",
      justifyContent: "space-between",
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      display: "block",
      width: "100%",
    },
    inputInput: {
      padding: "8px",
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    noOutline: {
      outline: "none !important",
    },
    searchButton: {
      margin: 0,
      height: "34px !important",
      outline: "none !important",
    },
    searchType: {
      padding: "1px 5px !important",
    },
    cNameBold: {
      fontWeight: "bold",
    },
    usericon: {
      marginRight: "6px",
      color: "#008145",
    },
  })
);
const Topnav = (props) => {
  const { classes } = props;
  const styles = useStyles();
  let history = new useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState("");
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(false);
  const [candidateSearch, setCandidateSearch] = useState([]);
  const [jobSearch, setJobSearch] = useState([]);
  const [textShow, setTextShow] = useState(false);
  const [showSimilar, setShowSimilar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    notificationCount();
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const onSearchTypeChange = (e) => {
    setSearchType(e.target.checked);
  };
  const userType = localStorage.getItem("ut");

  // const loadCompanyInfo = () => {
  //   const vendorCorporateId = localStorage.getItem("vci");
  //   const corporateId = localStorage.getItem("ci");
  //   //const corporateId = cacheData ? cacheData.ci : "";
  //   // props.showLoader({ show: true });
  //   if (userType === "1100" || userType === "1110") {
  //     axios
  //       .get(dEndPoints.getCompanyInfo(corporateId))
  //       .then((res) => {
  //         var data = res.data;
  //         console.log("data", data.data.responseData.icon);
  //         if (data.data.responseData.icon) {
  //           setCompanyInfo(data.data.responseData.icon);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //       });
  //   } else {
  //     axios
  //       .get(dEndPoints.getVendorCompanyInfo(vendorCorporateId))
  //       .then((res) => {
  //         var data = res.data;
  //         if (data.data.responseData.icon) {
  //           setCompanyInfo(data.data.responseData.icon);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //       });
  //   }
  // };
  const onSearch = (e) => {
    var reqData = { query: search };
    if (userType === "1110" || userType === "1100") {
      const corporateId = localStorage.getItem("ci");

      if (reqData.query < 3) {
        props.showSnackBar({
          message: "Please search at least 3 characters",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (searchType) {
        reqData.searchType = "Letter";
      }
      props.showLoader({ show: true });

      axios({
        url: dEndPoints.corpGlobalSearch(corporateId),
        method: "POST",
        data: reqData,
      })
        .then((res) => {
          var data = res.data;
          //console.log("global", data);
          props.showLoader({ show: false });
          if (data && data.code === 100000) {
            setTextShow(true);
            setCandidateSearch(data.data.candidateData);
            if (data.data.candidateData.length === 0) {
              setShowSimilar(true);
            }
            setJobSearch(data.data.jobData);
            if (data.data.jobData.length === 0) {
              setShowSimilar(true);
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    } else {
      const vendorCorporateId = localStorage.getItem("vci");
      if (reqData.query < 3) {
        props.showSnackBar({
          message: "Please search at least 3 characters",
          open: true,
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (searchType) {
        reqData.searchType = "Letter";
      }
      props.showLoader({ show: true });

      axios({
        url: dEndPoints.vendorGlobalSearch(vendorCorporateId),
        method: "POST",
        data: reqData,
      })
        .then((res) => {
          var data = res.data;
          props.showLoader({ show: false });
          if (data && data.code === 100000) {
            setTextShow(true);
            setCandidateSearch(data.data.candidateData);
            setJobSearch(data.data.jobData);
            if (data.data.candidateData.length === 0) {
              setShowSimilar(true);
            }
            if (data.data.jobData.length === 0) {
              setShowSimilar(true);
            }
          }
        })
        .catch((error) => {
          props.showLoader({ show: false });
          props.showSnackBar({
            message: `Something went wrong. Please contact support team`,
            open: true,
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  };
  //props.icon = companyInfo;
  // const renderUserToggle = (user, props) => (
  //   <div className="topnav__right-user">
  //     <div className="topnav__right-user__image">
  //       <img
  //         src={props.icon ? props.icon : thumb__profile}
  //         alt={props.icon ? props.icon : thumb__profile}
  //       />
  //     </div>
  //     <div className="topnav__right-user__name">{user}</div>
  //   </div>
  // );
  const notificationCount = () => {
    axios
      .get(endPoints.countNotification)
      .then((res) => {
        var data = res.data;
        if (data.code === 100000) {
          setNotification(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotifications = (event) => {
    setNotification("");
    axios({
      url: endPoints.notifications + "?limit=5",
    })
      .then((res) => {
        var data = res.data;
        if (data && data.code === 100000) {
          setNotifications(data.data);
        }
      })
      .catch((error) => {
        console.log("error while fetching data", error);
      });
  };

  const closeModalSearch = () => {
    setAnchorEl(null);
    setSearchType("");
  };
  const handleClose = () => {
    setAnchorEl(null);
    setJobSearch([]);
    setCandidateSearch([]);
    setTextShow(false);
    setSearchType("");
    setShowSimilar(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const logOut = () => {
    axios({
      url: endPoints.logOut,
      method: "GET",
    }).then((res) => {
      if (res.data && res.data.code === 100052) {
        document.cookie =
          "info=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie =
          "infoOne=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        history.push("/");
        props.showSnackBar({
          message: "logged out successfully",
          open: true,
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
      }
    });
  };

  // var userName = localStorage.getItem("email");
  // const renewToken = () => {
  //   axios({
  //     url: endPoints.updateToken,
  //     method: "GET",
  //     params: {
  //       userName: userName,
  //     },
  //   })
  //     .then((res) => {
  //       setShowModal(false);
  //       props.showSnackBar({
  //         message: "Session Continue",
  //         open: true,
  //         type: "success",
  //         vertical: "top",
  //         horizontal: "center",
  //       });
  //     })
  //     .catch((err) => {
  //       console.error("err", err);
  //     });
  // };
  const name = localStorage.getItem("name");
  history.listen(() => {
    let cookies;
    var value = "; " + document.cookie;
    var parts = value.split("; " + "info" + "=");
    if (parts.length === 2) {
      cookies = parts.pop().split(";").shift();
    }

    if (cookies) {
      setTimeout(() => {
        if (showModal === false) setShowModal(true);
      }, 5000);
    }
  });
  const userId = localStorage.getItem("i");
  return (
    <Container fluid className="topnav">
      <div
        className={
          window.location.pathname === "/find-jobs"
            ? "d-none"
            : "topnav__search"
        }
      >
        <Button justIcon round onClick={handleSearch}>
          <Search />
        </Button>
        <Popover
          className="tqSearchFixed"
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={styles.search}>
            <InputBase
              onChange={handleInputChange}
              placeholder="Search Jobs or Candidates"
              classes={{
                root: styles.inputRoot,
                input: styles.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
            <Button
              color="info"
              size="sm"
              justIcon
              onClick={onSearch}
              className={styles.searchButton}
              title="search"
            >
              <Search />
            </Button>
          </div>

          {showSimilar ? (
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  id="similar"
                  className={styles.searchType}
                  checked={searchType}
                  onChange={onSearchTypeChange}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                />
              }
              label="Try Similar Match"
            />
          ) : (
            ""
          )}
          {userType === "1110" || userType === "1100" ? (
            <>
              <Row>
                <Col sm={12}>
                  {jobSearch.length > 0 ? (
                    <h3 className="dashboard__sub-heading mb-3 mt-3">
                      Job Results
                    </h3>
                  ) : (
                    ""
                  )}
                  {jobSearch &&
                    jobSearch.map((job, i) => (
                      <ListItem
                        button
                        divider
                        key={i}
                        className={`d-block p-0`}
                      >
                        <Link
                          to={`/alljobs/${job._id}`}
                          onClick={closeModalSearch}
                          className="d-block p-2"
                        >
                          <ListItemText
                            primary={
                              <>
                                <WorkIcon className={styles.usericon} />
                                {job.desig}
                              </>
                            }
                            secondary={
                              <>
                                {job.reqId} - {job.jobType}
                              </>
                            }
                          />
                        </Link>
                      </ListItem>
                    ))}
                </Col>
                <Col sm={12}>
                  {candidateSearch.length > 0 ? (
                    <h3 className="dashboard__sub-heading mb-3 mt-3">
                      Candidate Results
                    </h3>
                  ) : (
                    ""
                  )}
                  {candidateSearch &&
                    candidateSearch.map((can, i) => (
                      <ListItem
                        button
                        divider
                        key={i}
                        className={styles.margin}
                      >
                        <Link
                          to={`/alljobs/${can.jobId}?candId=${can._id}`}
                          onClick={closeModalSearch}
                        >
                          <ListItemText
                            primary={
                              <>
                                <PersonPinIcon className={styles.usericon} />
                                {can.name}
                              </>
                            }
                            secondary={
                              <>
                                {can.cDesig} - {can.cName} - {can.skills}
                              </>
                            }
                          />
                        </Link>
                      </ListItem>
                    ))}
                </Col>
              </Row>
            </>
          ) : (
            <>
              {" "}
              <Row>
                <Col sm={6}>
                  {jobSearch.length > 0 ? (
                    <h3 className="dashboard__sub-heading mb-3">Job Results</h3>
                  ) : (
                    ""
                  )}
                  {jobSearch &&
                    jobSearch.map((job, i) => (
                      <ListItem
                        button
                        divider
                        key={i}
                        className={styles.margin}
                      >
                        <Link
                          to={`/alljobs/${job._id}?enroll=${true}`}
                          onClick={closeModalSearch}
                          className="d-block p-2"
                        >
                          <ListItemText
                            primary={
                              <>
                                <WorkIcon className={styles.usericon} />
                                {job.desig}
                              </>
                            }
                            secondary={
                              <>
                                {job.reqId} - {job.jobType}
                              </>
                            }
                          />
                        </Link>
                      </ListItem>
                    ))}
                </Col>
                <Col sm={6}>
                  {candidateSearch.length > 0 ? (
                    <h3 className="dashboard__sub-heading mb-3">
                      Candidate Results
                    </h3>
                  ) : (
                    ""
                  )}
                  {candidateSearch &&
                    candidateSearch.map((can, i) => (
                      <ListItem
                        button
                        divider
                        key={i}
                        className={styles.margin}
                      >
                        <Link
                          to={`/alljobs/${can.jobId}?candId=${
                            can._id
                          }&enroll=${true}`}
                          onClick={closeModalSearch}
                        >
                          <ListItemText
                            primary={
                              <>
                                <PersonPinIcon className={styles.usericon} />
                                {can.name}
                              </>
                            }
                            secondary={
                              <>
                                {can.cDesig} - {can.cName} - {can.skills}
                              </>
                            }
                          />
                        </Link>
                      </ListItem>
                    ))}
                </Col>
              </Row>
            </>
          )}
          {textShow && (jobSearch.length || candidateSearch.length) === 0 ? (
            <ListItem className={styles.margin}>
              <ListItemText primary="No Result Found" />
            </ListItem>
          ) : (
            ""
          )}
          {/* </List> */}
        </Popover>
      </div>
      <div
        // className="topnav__right"
        className={
          window.location.pathname === "/find-jobs"
            ? "topnav__right ml-auto"
            : "topnav__right"
        }
      >
        <div className="topnav__right-item">
          <Dropdowns
            icon="bx bx-bell "
            blink={
              notification.count ||
              (notifications.length > 0 &&
                notifications[0].viewed.includes(userId) === false)
                ? "blinking__notice"
                : ""
            }
            contentData={notifications ? notifications : ""}
            renderItems={(item, index) =>
              userType === "1100" || userType === "1110"
                ? renderNotificationItem(item, index)
                : renderNotificationItemVendor(item, index)
            }
            renderFooter={() => (
              <Link to="/notifications" className="view__all-btn">
                View All
              </Link>
            )}
          />
        </div>

        <div className="topnav__right-item user__view ml-0">
          {/* dropdown here */}
          <div className="user__icons">
            <img
              src={
                // companyInfo.icon ? companyInfo.icon :
                thumb__profile
              }
              alt={
                // companyInfo.icon ? companyInfo.icon :
                thumb__profile
              }
              className="img-fluid"
            />
          </div>
          <DropdownButton
            variant="outline-secondary text-center border-0"
            title={name}
            id="input-group-dropdown-1"
          >
            {" "}
            <Dropdown.Item
              href="/settings/userprofile"
              className="notification-item"
            >
              <i className="bx bx-user"></i>Profile
            </Dropdown.Item>
            <Dropdown.Item href="/settings" className="notification-item">
              <i className="bx bx-cog"></i>Settings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Button variant="primary w-75" onClick={logOut}>
              Logout
            </Button>
          </DropdownButton>
        </div>
      </div>
    </Container>
  );
};

//export default Topnav;
function mapDispatchToProps(dispatch) {
  return {
    showLoader: bindActionCreators(showLoader, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(loginPageStyle)(Topnav));
