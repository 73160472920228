import React from "react";
import { Container } from "react-bootstrap";
// import "../main.css";
// import "../tqui.css";

const CtaOne = (ctaOne) => {
  return (
    <div className="cta-strip-section">
      <Container>
        <div className="cta-wrapper">
          <p className="cta-text">
            {ctaOne.Text}
            {/* TalenQ delivers the solution to your hiring problems by providing
            the best suited to your requirements. */}
          </p>
          <a href={ctaOne.BtnLink} className="btn-tq btn-wt">
            {ctaOne.ButtonText}
          </a>
        </div>
      </Container>
    </div>
  );
};

export default CtaOne;
