import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import TrustedPartner from "../home/TrustedPartner";
import CtaOne from "../common/CtaOne";
import Ctatwo from "../common/Ctatwo";
import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";

const About = () => {
  return (
    <div className="aboutus-page">
      <Header />
      <section className="about-banner tq-section-padding">
        <Container>
          <p className="hero-small">About Us</p>
          <h1 className="hero-title">
            TalenQ, Your Hiring <br />
            Companion
          </h1>
        </Container>
      </section>
      <CtaOne
        Text="TalenQ delivers the solution to your hiring problems by providing the best suited to your requirements."
        ButtonText="Request a Demo"
        BtnLink="/contactus"
      />
      <section className="visionMission-section tq-section-padding">
        <Container>
          <div className="row">
            <Col xs={12} sm={7} md={7} className="d-flex align-items-center">
              <div className="content-wrapper">
                <div className="tq-heading-wrapper">
                  <p className="top-heading">What We Do</p>
                  <h2 className="section-heading">Our Vision and Mission</h2>
                </div>
                <p className="quote-text">
                  We strongly believe that the right talent helps the
                  organization to grow in all aspects.
                </p>
                <p className="desc-text mb-4 mt-4">
                  TalenQ brings Employers and Hiring Partners together in order
                  to identify and hire the right candidate in a timely manner at
                  an affordable cost. This platform will enable employers to
                  showcase their requirements and get quality profiles from our
                  Network of hiring experts.
                </p>
                <p className="desc-text">
                  Our mission is to make hiring faster and productive for the
                  company by automating the hiring process and helping it in
                  sourcing the right talents with minimal cost.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={5} md={5} className="d-xs-none">
              <img
                src="https://talenq.r.worldssl.net/talenq/img/aboutus/aboutTq.png"
                alt="Vision and Mission"
                className="img-fluid mission-thub"
              />
            </Col>
          </div>
        </Container>
      </section>
      <TrustedPartner />
      {/* <AboutBenifit /> */}

      <section className="benifits-section tq-section-padding">
        <Container>
          <Row>
            <Col
              xs={12}
              sm={6}
              md={5}
              className="mt-sm-0 mt-md-0 mt-5 d-flex align-items-center"
            >
              <div className="tq-heading-wrapper">
                {/* <p className="top-heading">Benefits with us</p> */}
                <h2 className="section-heading">Our Core Values</h2>
                <p className="heading-desc">
                  Working together as a team for a clearly defined vision,
                  keeping our core values intact helps the organisation to grow
                  in all aspects.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={7} className="mt-sm-0 mt-md-0 mt-5">
              <Row>
                <Col xs={6} sm={6}>
                  <div className="tq-bft-card">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/integrity.png"
                      alt="integrity"
                      className="img-fluid values"
                    />
                    <h3 className="card-title">Integrity</h3>
                  </div>
                </Col>
                <Col xs={6} sm={6}>
                  <div className="tq-bft-card">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/passion.png"
                      alt="passion"
                      className="img-fluid values"
                    />
                    <h3 className="card-title">Passion</h3>
                  </div>
                </Col>
                <Col xs={6} sm={6} className="mt-2">
                  <div className="tq-bft-card">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/teamwork.png"
                      alt="teamwork"
                      className="img-fluid values"
                    />
                    <h3 className="card-title">Teamwork</h3>
                  </div>
                </Col>
                <Col xs={6} sm={6} className="mt-2">
                  <div className="tq-bft-card">
                    <img
                      src="https://talenq.r.worldssl.net/talenq/img/accountability.png"
                      alt="accountability"
                      className="img-fluid values"
                    />
                    <h3 className="card-title">Accountability</h3>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Ctatwo
        Line1="Are you looking for a challenging yet growing work environment?"
        Line2="Start where you are. Use what you have. Do what you can "
        ButtonText="Join Our Team "
        BtnLink="/contactus"
      />
      <Footer />
    </div>
  );
};
export default About;
